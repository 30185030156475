import { makeStyles } from '@material-ui/core'
import GridItem from 'components/Grid/GridItem'
import { matchPersonType } from 'shared/matchData/matchData'
import styles from '../styles/investorsRegisterTitlesStyles'

const useStyles = makeStyles(styles)

const InvestorRegisterTitles = ({ personType }) => {
  const classes = useStyles()
  return (
    <GridItem xs={12}>
      {personType === 'none' ? (
        <h4 className={classes.fontTitles}>Con este formulario usted puede crear un usuario para la plataforma.</h4>
      ) : (
        <h4 className={classes.fontTitles}>
          Con este formulario usted puede crear además de un usuario para la plataforma, un perfil inversor de tipo{' '}
          <b>{`${matchPersonType(personType)}`}</b> con los mismos datos.
        </h4>
      )}
    </GridItem>
  )
}

export default InvestorRegisterTitles
