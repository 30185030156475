import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	makeStyles,
	IconButton,
} from '@material-ui/core'
import { Close, Send } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import Loader from 'components/Loader/Loader'
import CustomInput from 'components/CustomInput/CustomInput'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CommentItem from './CommentItem'
import {
	getInvestmentCommentsByInvestmentId,
	registerInvestmentComment,
} from 'local_redux/actions/investmentCommentActions'
import {
	INVESTMENT_COMMENT_BY_ID_LIST_RESET,
	INVESTMENT_COMMENT_REGISTER_RESET,
} from 'local_redux/constants/investmentCommentConstants'
import styles from '../styles/reinvestmentModalStyles'
import { ReportGmailerrorred } from '@mui/icons-material'

const useStyles = makeStyles(styles)

const InvestmentCommentsModal = ({ open, close, investmentId }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [comments, setComments] = useState([]) //{comment, userName, date, status}[]
	const [comment, setComment] = useState('')

	const {
		loadingInvestmentCommentsByInvestmentId,
		successinvestmentCommentsByInvestmentId,
		investmentCommentsByInvestmentIdList,
		errorInvestmentCommentsByInvestmentId,
	} = useSelector((state) => state.investmentCommentsByInvestmentId)
	const { loadingInvestmentCommentRegister, successInvestmentCommentRegister, errorInvestmentCommentRegister } =
		useSelector((state) => state.investmentCommentRegister)
	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		if (investmentId) {
			dispatch(getInvestmentCommentsByInvestmentId(investmentId))
		}
		return () => {
			dispatch({ type: INVESTMENT_COMMENT_BY_ID_LIST_RESET })
		}
	}, []) //GET COMMENTS && CLEAN STATE
	useEffect(() => {
		if (successinvestmentCommentsByInvestmentId) {
			const commentsMapped = investmentCommentsByInvestmentIdList.map((comment) => ({
				comment: comment.comment,
				userName: comment.user.name,
				dateComment: comment.createdAt,
				status: 'success',
			}))
			setComments(commentsMapped)
		}
	}, [successinvestmentCommentsByInvestmentId])
	useEffect(() => {
		if (successInvestmentCommentRegister) {
			const commentsUpdated = comments.map((e) => {
				if (e.status === 'pending') {
					e.status = 'success'
				}
				return e
			})
			setComments(commentsUpdated)
		}
	}, [successInvestmentCommentRegister]) //IF SUCCESS
	useEffect(() => {
		if (errorInvestmentCommentRegister) {
			const commentsUpdated = comments.map((e) => {
				if (e.status === 'pending') {
					e.status = 'failed'
				}
				return e
			})
			setComments(commentsUpdated)
		}
	}, [errorInvestmentCommentRegister]) //IF FAIL

	const handleSendComment = () => {
		dispatch({ type: INVESTMENT_COMMENT_REGISTER_RESET })
		const commentData = {
			comment,
			userName: userInfo?.name || '',
			dateComment: new Date(),
			status: 'pending',
		}
		setComments((prev) => [...prev, commentData])
		dispatch(registerInvestmentComment({ investment: investmentId, comment }))
		setComment('')
	}
	const disabledFunc = () => {
		return loadingInvestmentCommentRegister
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={open}
			keepMounted
			onClose={close}
			aria-labelledby='notice-modal-slide-title'
			aria-describedby='notice-modal-slide-description'
		>
			<DialogTitle id='notice-modal-slide-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={close}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4 className={classes.DialogTitle}>Comentarios</h4>
			</DialogTitle>
			<DialogContent className={classes.modalBody}>
				<GridContainer>
					{loadingInvestmentCommentsByInvestmentId && (
						<GridItem xs={12} className={classes.loader}>
							<Loader message='Obteniendo comentarios de la inversión...' />
						</GridItem>
					)}
					<GridItem xs={12} className={classes.commentContainer}>
						{errorInvestmentCommentsByInvestmentId && (
							<GridItem xs={12} className={classes.commentContainer}>
								<GridContainer className={classes.gridSearch}>
									<GridItem xs={12} md={12} className={classes.iconSearch}>
										<ReportGmailerrorred />
									</GridItem>
									<GridItem xs={12} md={12}>
										<p>{errorInvestmentCommentsByInvestmentId || 'Sin Comentarios'}</p>
									</GridItem>
								</GridContainer>
							</GridItem>
						)}
						{comments.length > 0 && (
							<GridContainer>
								{comments.map((item, i) => (
									<CommentItem
										classes={classes}
										userName={item.userName}
										comment={item.comment}
										dateComment={item.dateComment}
										key={'commentItem' + i}
										status={item.status}
									/>
								))}
							</GridContainer>
						)}
					</GridItem>
					<GridItem xs={12} className={classes.inputContainer}>
						<GridItem xs={11} className={classes.inputGrid}>
							<CustomInput
								helperText='Presiona "Enter" para guardar comentario (opcional)'
								formControlProps={{
									fullWidth: true,
								}}
								noPaddingTop
								inputProps={{
									placeholder: 'Escribe un comentario',
									multiline: true,
									rows: 1,
									onKeyPress: (e) => {
										if (e.key === 'Enter' && !e.shiftKey && !loadingInvestmentCommentRegister) {
											e.preventDefault()
											handleSendComment()
										}
									},
									value: comment,
									onChange: (e) => {
										setComment(e.target.value)
									},
									type: 'text',
								}}
							/>
						</GridItem>
						<GridItem xs={1} className={classes.buttonContainer}>
							<IconButton size='large' onClick={() => [handleSendComment()]} disabled={disabledFunc()}>
								{loadingInvestmentCommentRegister ? (
									<CircularProgress size={'20px'} style={{ color: '#333333' }} />
								) : (
									<Send />
								)}
							</IconButton>
						</GridItem>
					</GridItem>
				</GridContainer>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridItem xs={12}>
					<Button disabled={disabledFunc()} onClick={close}>
						Cerrar
					</Button>
				</GridItem>
			</DialogActions>
		</Dialog>
	)
}

export default InvestmentCommentsModal
