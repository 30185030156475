import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { declineInvestment } from 'local_redux/actions/investmentActions'
import { registerLog } from 'local_redux/actions/logActions'
import { LOG_REGISTER_RESET } from 'local_redux/constants/logConstants'
import { INVESTMENT_ADMIN_DECLINE_RESET } from 'local_redux/constants/investmentConstants'
import styles from '../styles/investmentAdminDeleteModalStyles'

const useStyles = makeStyles(styles)

const InvestmentAdminDeleteModal = ({
	handleCloseInvestmentAdminModal,
	deleteInvestmentAdminModal,
	showDeleteInvestmentInfo,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const { userInfo } = useSelector((state) => state.userLogin)
	const {
		successInvestmentDeclineBySuperAdmin,
		loadingInvestmentDeclineBySuperAdmin,
		errorInvestmentDeclineBySuperAdmin,
	} = useSelector((state) => state.investmentDeclineBySuperAdmin)

	useEffect(() => {
		let timeOut = ''
		if (successInvestmentDeclineBySuperAdmin) {
			timeOut = setTimeout(() => {
				handleCloseInvestmentAdminModal()
			}, 1500)
		}
		return () => clearTimeout(timeOut)
	}, [successInvestmentDeclineBySuperAdmin])
	useEffect(() => {
		if (successInvestmentDeclineBySuperAdmin) {
			// eslint-disable-next-line no-use-before-define
			handleInvestmentLog()
		} else if (errorInvestmentDeclineBySuperAdmin) {
			// eslint-disable-next-line no-use-before-define
			handleInvestmentLog(true)
		}
	}, [successInvestmentDeclineBySuperAdmin, errorInvestmentDeclineBySuperAdmin])
	useEffect(() => {
		return () => {
			dispatch({ type: INVESTMENT_ADMIN_DECLINE_RESET })
			dispatch({ type: LOG_REGISTER_RESET })
		}
	}, [dispatch])

	const handleDeclineInvestment = (e) => {
		e.preventDefault()
		const data = {
			_id: showDeleteInvestmentInfo._id,
		}
		dispatch(declineInvestment(data))
	}
	const handleInvestmentLog = (error) => {
		const actionType = !error ? 'INVESTMENT_ADMIN_DECLINE_REQUEST' : 'INVESTMENT_ADMIN_DECLINE_FAIL'
		const type = error ? 'error' : 'action'

		const logInfo = {
			type: {
				type,
			},
			investors: {
				id: showDeleteInvestmentInfo?.investorProfile._id,
				...showDeleteInvestmentInfo?.investorProfile,
			},
			user: {
				id: userInfo._id,
				name: userInfo.name,
				role: userInfo.role,
			},
			investment: {
				id: showDeleteInvestmentInfo._id,
				amount: showDeleteInvestmentInfo.amount,
			},
			project: {
				id: showDeleteInvestmentInfo.project._id,
				...showDeleteInvestmentInfo.project,
			},
		}
		if (error) {
			logInfo.type.error = {
				errorType: actionType,
				errorMessage: errorInvestmentDeclineBySuperAdmin,
			}
		} else {
			logInfo.type.actionType = actionType
		}

		dispatch(registerLog(logInfo))
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={deleteInvestmentAdminModal}
			keepMounted
			onClose={handleCloseInvestmentAdminModal}
			aria-labelledby='user-modal-delete-title'
			aria-describedby='user-modal-delete-description'
		>
			<DialogTitle id='user-modal-delete-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleCloseInvestmentAdminModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>Eliminar Inversión</h4>
			</DialogTitle>
			<DialogContent id='user-modal-delete-description' className={classes.modalBody}>
				<form onSubmit={handleDeclineInvestment} id='investment-admin-delete-form'>
					<GridContainer>
						<GridItem xs={12}>
							<Typography>
								¿Estás seguro que queres eliminar la inversión por un Importe de {showDeleteInvestmentInfo?.amount}?
							</Typography>
						</GridItem>
					</GridContainer>
					{errorInvestmentDeclineBySuperAdmin && (
						<GridContainer>
							<GridItem xs={12}>
								<SnackbarContent message={errorInvestmentDeclineBySuperAdmin} color='danger' />
							</GridItem>
						</GridContainer>
					)}
				</form>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={6}>
						<Button onClick={handleCloseInvestmentAdminModal} block>
							Cancelar
						</Button>
					</GridItem>
					<GridItem xs={6}>
						<Button
							type='submit'
							color={successInvestmentDeclineBySuperAdmin ? 'success' : 'primary'}
							block
							form='investment-admin-delete-form'
						>
							{loadingInvestmentDeclineBySuperAdmin
								? 'Eliminando'
								: successInvestmentDeclineBySuperAdmin
								? 'Eliminado'
								: 'Eliminar'}
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default InvestmentAdminDeleteModal
