import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import CustomInput from 'components/CustomInput/CustomInput'
import { FIXED_RATE_LOANS_UPDATE_BY_ID_RESET } from 'local_redux/constants/fixedRateLoansConstants'
import styles from '../styles/investmentAdminDeleteModalStyles'

const useStyles = makeStyles(styles)

const DeleteIncomeDateFixedRateLoansModal = ({
	handleCloseFixedRateLoansDateAdminModal,
	deleteFixedRateLoansDateAdminModal,
	showDeleteFixedRateLoansDateInfo,
	deleteIncomeRecivedDateFixedRateLoans,
	loadingUpdateFixedRateLoansById,
	successUpdateFixedRateLoansById,
	errorUpdateFixedRateLoansById,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [reason, setReason] = useState('')

	useEffect(() => {
		let timeOut = ''
		if (successUpdateFixedRateLoansById) {
			timeOut = setTimeout(() => {
				handleCloseFixedRateLoansDateAdminModal()
			}, 1500)
		}
		return () => clearTimeout(timeOut)
	}, [successUpdateFixedRateLoansById])
	useEffect(() => {
		return () => dispatch({ type: FIXED_RATE_LOANS_UPDATE_BY_ID_RESET })
	}, [dispatch])

	const handleDeclineFixedRateLoans = (e) => {
		e.preventDefault()
		deleteIncomeRecivedDateFixedRateLoans(showDeleteFixedRateLoansDateInfo._id, reason)
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={deleteFixedRateLoansDateAdminModal}
			keepMounted
			onClose={handleCloseFixedRateLoansDateAdminModal}
			aria-labelledby='user-modal-delete-title'
			aria-describedby='user-modal-delete-description'
		>
			<DialogTitle id='user-modal-delete-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleCloseFixedRateLoansDateAdminModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>Eliminar fecha de Ingreso</h4>
			</DialogTitle>
			<DialogContent id='user-modal-delete-description' className={classes.modalBody}>
				<form onSubmit={handleDeclineFixedRateLoans} id='delete-income-date-form'>
					<GridContainer>
						<GridItem xs={12}>
							<Typography>
								¿Estás seguro que queres eliminar la fecha de ingreso del proyecto{' '}
								<b>{showDeleteFixedRateLoansDateInfo.investorProfile}</b>?
							</Typography>
						</GridItem>
						<GridItem xs={12}>
							<CustomInput
								labelText='Motivo *'
								id='reason'
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									value: reason,
									onChange: (e) => {
										setReason(e.target.value)
									},
									type: 'text',
									required: true,
								}}
							/>
						</GridItem>
					</GridContainer>
					{errorUpdateFixedRateLoansById && (
						<GridContainer>
							<GridItem xs={12}>
								<SnackbarContent message={errorUpdateFixedRateLoansById} color='danger' />
							</GridItem>
						</GridContainer>
					)}
				</form>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={6}>
						<Button onClick={handleCloseFixedRateLoansDateAdminModal} block>
							Cancelar
						</Button>
					</GridItem>
					<GridItem xs={6}>
						<Button
							type='submit'
							color={successUpdateFixedRateLoansById ? 'success' : 'primary'}
							block
							form='delete-income-date-form'
							disabled={
								loadingUpdateFixedRateLoansById || successUpdateFixedRateLoansById || errorUpdateFixedRateLoansById
							}
						>
							{loadingUpdateFixedRateLoansById
								? 'Eliminando'
								: successUpdateFixedRateLoansById
								? 'Eliminado'
								: 'Eliminar'}
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default DeleteIncomeDateFixedRateLoansModal
