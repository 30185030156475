import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Close } from '@material-ui/icons'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Loader from 'components/Loader/Loader'
import FIXED_RATE_LOANS_INITIAL_STATE from '../helpers/fixed-rate-laons-initial-state'
import FixedRateLoansInputs from './FixedRateLoansInputs'
import { getInvestorsProfileList } from 'local_redux/actions/investorProfileActions'
import { registerFixedRateLoans, updateFixedRateLoansById } from 'local_redux/actions/fixedRateLoansActions'
import {
	FIXED_RATE_LOANS_REGISTER_RESET,
	FIXED_RATE_LOANS_UPDATE_BY_ID_RESET,
} from 'local_redux/constants/fixedRateLoansConstants'
import { INVESTORS_PROFILE_LIST_RESET } from 'local_redux/constants/investorProfileConstants'
import styles from '../styles/registerFixedRateLoansModalStyles'

const useStyles = makeStyles(styles)

const RegisterFixedRateLoansModal = ({
	handleCloseFixedRateLoansModal,
	registerFixedRateLoansModal,
	showFixedRateLoansModalInfo,
	data,
	setData,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const initialState = showFixedRateLoansModalInfo._id ? showFixedRateLoansModalInfo : FIXED_RATE_LOANS_INITIAL_STATE

	const [fixedRateLoansState, setFixedRateLoansState] = useState(initialState)
	const [investorProfiles, setInvestorProfiles] = useState([])

	const {
		loadingRegisterFixedRateLoans,
		successRegisterFixedRateLoans,
		errorRegisterFixedRateLoans,
		registeredFixedRateLoansData,
	} = useSelector(({ fixedRateLoansRegister }) => fixedRateLoansRegister)
	const { loadingUpdateFixedRateLoansById, successUpdateFixedRateLoansById, errorUpdateFixedRateLoansById } =
		useSelector(({ fixedRateLoansUpdateById }) => fixedRateLoansUpdateById)
	const { loadingInvestorsProfileList, successInvestorsProfileList, investorsProfileListData } = useSelector(
		({ investorsProfileList }) => investorsProfileList,
	)
	useEffect(() => {
		dispatch(getInvestorsProfileList())
		return () => {
			dispatch({ type: INVESTORS_PROFILE_LIST_RESET })
		}
	}, [])
	useEffect(() => {
		if (successInvestorsProfileList) {
			setInvestorProfiles(investorsProfileListData)
		}
	}, [successInvestorsProfileList])
	useEffect(() => {
		let timeOut
		if (successRegisterFixedRateLoans) {
			const profileFilter = investorProfiles.filter(
				(item) => item._id === registeredFixedRateLoansData.investorProfile,
			)[0]
			const newRateLoansRegister = {
				...registeredFixedRateLoansData,
				investorProfile: profileFilter?.businessName,
				typesOfInterestRates: `${registeredFixedRateLoansData.typesOfInterestRates} %`,
				user: registeredFixedRateLoansData.user.name,
			}
			setData([...data, newRateLoansRegister])
			timeOut = setTimeout(() => {
				handleCloseFixedRateLoansModal()
			}, 1500)
		}
		return () => clearTimeout(timeOut)
	}, [successRegisterFixedRateLoans])
	useEffect(() => {
		return () => {
			if (!showFixedRateLoansModalInfo._id) {
				dispatch({ type: FIXED_RATE_LOANS_REGISTER_RESET })
			} else {
				dispatch({ type: FIXED_RATE_LOANS_UPDATE_BY_ID_RESET })
			}
		}
	}, [dispatch])

	const handleRegisterFixedRateLoans = (e) => {
		e.preventDefault()

		const newFixedRateLoan = {
			...fixedRateLoansState,
		}
		if (!showFixedRateLoansModalInfo._id) {
			return dispatch(registerFixedRateLoans(newFixedRateLoan))
		} else {
			return dispatch(updateFixedRateLoansById(newFixedRateLoan))
		}
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={registerFixedRateLoansModal}
			keepMounted
			onClose={handleCloseFixedRateLoansModal}
			aria-labelledby='fixedRateLoan-modal-title'
			aria-describedby='fixedRateLoan-modal-description'
		>
			<form onSubmit={handleRegisterFixedRateLoans}>
				<DialogTitle id='fixedRateLoan-modal-title' disableTypography className={classes.modalHeader}>
					<Button
						justIcon
						className={classes.modalCloseButton}
						key='close'
						aria-label='Close'
						color='transparent'
						onClick={handleCloseFixedRateLoansModal}
					>
						<Close className={classes.modalClose} />
					</Button>
					<h4>{showFixedRateLoansModalInfo._id ? 'Editar Préstamo a T Fijo' : 'Registrar Préstamo a T. Fijo'}</h4>
				</DialogTitle>

				<DialogContent id='fixedRateLoan-modal-description' className={classes.modalBody}>
					<GridContainer className={classes.loadContainer}>
						{loadingInvestorsProfileList ? (
							<Loader />
						) : (
							<GridItem xs={12}>
								<FixedRateLoansInputs
									fixedRateLoan={fixedRateLoansState}
									setFixedRateLoan={setFixedRateLoansState}
									investorProfiles={investorProfiles}
								/>
							</GridItem>
						)}
					</GridContainer>
					{errorRegisterFixedRateLoans && (
						<GridContainer>
							<GridItem xs={12}>
								<SnackbarContent message={errorRegisterFixedRateLoans} color='danger' />
							</GridItem>
						</GridContainer>
					)}
					{errorUpdateFixedRateLoansById && (
						<GridContainer>
							<GridItem xs={12}>
								<SnackbarContent message={errorUpdateFixedRateLoansById} color='danger' />
							</GridItem>
						</GridContainer>
					)}
				</DialogContent>
				<DialogActions className={classes.modalFooter}>
					<GridContainer>
						<GridItem xs={6}>
							<Button onClick={handleCloseFixedRateLoansModal} block>
								Cancelar
							</Button>
						</GridItem>
						<GridItem xs={6}>
							<Button
								type='submit'
								color={successRegisterFixedRateLoans || successUpdateFixedRateLoansById ? 'success' : 'primary'}
								disabled={
									!fixedRateLoansState.investorProfile ||
									!fixedRateLoansState.startDate ||
									!fixedRateLoansState.finishDate ||
									!fixedRateLoansState.amount ||
									!fixedRateLoansState.typesOfInterestRates
								}
								block
							>
								{!showFixedRateLoansModalInfo._id
									? loadingRegisterFixedRateLoans
										? 'Registrando'
										: successRegisterFixedRateLoans
										? 'Registrado'
										: 'Registrar'
									: loadingUpdateFixedRateLoansById
									? 'Editando'
									: successUpdateFixedRateLoansById
									? 'Editado'
									: 'Editar'}
							</Button>
						</GridItem>
					</GridContainer>
				</DialogActions>
			</form>
		</Dialog>
	)
}

export default RegisterFixedRateLoansModal
