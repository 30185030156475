import { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { Assignment } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import UpdateProfileByAdmin from 'components/UpdateProfileByAdmin/UpdateProfileByAdmin'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import ExcelDefaultExport from 'components/ReactTable/components/ExcelDefaultExport'
import UploadProfileDocumentsModal from 'components/UploadProfileDocumentsModal/UploadProfileDocumentsModal'
import NotesModal from 'components/CustomModals/NotesModal/NotesModal'
import DeleteProfileInvestorAdminModal from './components/DeleteProfileInvestorAdminModal'
import { openModal, closeModal } from 'shared/helpers/modal-helper'
import { investorProfileAdminListColumn } from './helpers/investorProfileAdminListColumn'
import { investorProfileAdminListMapper } from './mappers/investorProfileAdminListMapper'
import { FilterReactTableContext } from 'contexts/filterReactTableContext'
import { FilterReactVirtualizedTableContext } from 'contexts/filterReactVirtualizedTableContext'
import { INVESTORS_PROFILE_PAGINATION_LIST_RESET } from 'local_redux/constants/investorProfileConstants'
import { getInvestorsProfilePaginationList } from 'local_redux/actions/investorProfileActions'
import styles from './styles/investorProfileAdminListStyles'

const useStyles = makeStyles(styles)

const InvestorProfileAdminListScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const navigate = useNavigate()
	const search = useLocation().search
	const profileId = new URLSearchParams(search).get('profileId')
	const tableId = 10

	const [data, setData] = useState([])
	const [investorProfileDeleteModal, setInvestorProfileDeleteModal] = useState(false)
	const [showInvestorDeleteInfo, setShowInvestorDeleteInfo] = useState({})
	const [investorProfileUploadDocumentModal, setInvestorProfileUploadDocumentModal] = useState(false)
	const [showInvestorProfileUploadDocumentInfo, setShowInvestorProfileUploadDocumentInfo] = useState({})
	const [profileDeletedId, serProfileDeletedId] = useState('')
	const [investorProfiles, setInvestorProfiles] = useState([])
	const [showModal, setShowModal] = useState(false)

	const { filteredRows, setfilteredRows } = useContext(FilterReactTableContext)
	const { getFilteredDataRows, getFilteredDataColumns } = useContext(FilterReactVirtualizedTableContext)

	const { userInfo } = useSelector((state) => state.userLogin)
	const { successInvestorProfileUpdate, investorProfileUpdateData } = useSelector(
		(state) => state.investorProfileUpdate,
	)
	const {
		successInvestorsProfilePaginationList,
		investorsProfilePaginationListData,
		loadingInvestorsProfilePaginationList,
		errorInvestorsProfilePaginationList,
	} = useSelector((state) => state.investorsProfilePaginationList)
	const { successInvestorProfileDelete } = useSelector((state) => state.investorProfileDelete)

	const handleDeployNotesModal = (user) => {
		setShowModal(user)
	}

	useEffect(() => {
		dispatch(getInvestorsProfilePaginationList())
	}, [])
	useEffect(() => {
		if (successInvestorsProfilePaginationList) {
			setInvestorProfiles(investorsProfilePaginationListData)
		}
	}, [successInvestorsProfilePaginationList])
	useEffect(() => {
		if (successInvestorProfileUpdate) {
			dispatch(getInvestorsProfilePaginationList())
		}
	}, [successInvestorProfileUpdate])
	useEffect(() => {
		if (investorProfiles) {
			setData(investorProfileAdminListMapper(investorProfiles))
		}
	}, [setData, investorProfiles])
	useEffect(() => {
		if (successInvestorProfileUpdate) {
			const mapperResult = data.map((mapData) => {
				if (mapData._id === investorProfileUpdateData._id) {
					mapData = investorProfileUpdateData
					if (investorProfileUpdateData.personType === 'physical') {
						mapData.gender = investorProfileUpdateData.gender === 'Masculino' ? 'male' : 'female'
					}
				}
				return mapData
			})
			setData(mapperResult)
		}
		if (successInvestorProfileDelete) {
			if (profileDeletedId) {
				const profiles = investorProfiles.filter((data) => data._id !== profileDeletedId)
				setInvestorProfiles(profiles)
			}
		}
	}, [successInvestorProfileUpdate, setInvestorProfiles, successInvestorProfileDelete])
	useEffect(() => {
		return () => dispatch({ type: INVESTORS_PROFILE_PAGINATION_LIST_RESET })
	}, [dispatch])
	useEffect(() => {
		if (investorProfiles.length > 0) {
			const mappedData = investorProfileAdminListMapper(investorProfiles)
			setfilteredRows(mappedData) // Set filtered rows for export
		}
	}, [investorProfiles, setfilteredRows])

	const handleCloseInvestorDeleteModal = () => {
		closeModal(setShowInvestorDeleteInfo, setInvestorProfileDeleteModal)
	}
	const handleCloseInvestorProfileUploadDocumentModal = () => {
		closeModal(setShowInvestorProfileUploadDocumentInfo, setInvestorProfileUploadDocumentModal)
	}

	return (
		<>
			{showModal && (
				<NotesModal
					type='profile'
					open={showModal}
					handleCloseModal={() => setShowModal(false)}
					modalTitle={'Notas del perfil'}
				/>
			)}
			{profileId ? (
				<UpdateProfileByAdmin
					showInvestorUpdateInfo={investorProfiles.find((investorProfile) => investorProfile._id === profileId)}
					investorsProfileListData={investorsProfilePaginationListData}
					investorProfiles={investorProfiles}
					setInvestorProfiles={setInvestorProfiles}
				/>
			) : (
				<GridContainer>
					<GridItem xs={12} className={classes.rootItem}>
						<Card>
							<CardHeader color='primary' icon>
								<CardIcon color='primary'>
									<Assignment />
								</CardIcon>
							</CardHeader>
							<CardBody>
								<GridContainer>
									<GridItem xs={12}>
										{(userInfo?.isSuper || userInfo?.isInformation) &&
											data.length > 0 &&
											filteredRows.length > 0 &&
											Boolean(getFilteredDataRows({ tableId })?.length) && (
												<ExcelDefaultExport
													excelName='Lista de perfiles inversores'
													data={filteredRows.map((row) => {
														return {
															...row,
															businessName: row?.businessName,
															dni: row?.dni,
															country: row?.country || 'España',
															postalCode: row?.postalCode || '28001',
															bankAccountNumber: row?.bankAccountNumber || '',
															address: row?.address,
														}
													})}
													tableColumns={getFilteredDataColumns({ tableId })}
												/>
											)}
									</GridItem>
									<GridItem xs={12}>
										<ReactVirtualizedTable
											data={data}
											tableId={tableId}
											columns={investorProfileAdminListColumn({
												handleDeployNotesModal,
												navigate,
												openModal,
												serProfileDeletedId,
												setShowInvestorDeleteInfo,
												setInvestorProfileDeleteModal,
											})}
											loading={Boolean(loadingInvestorsProfilePaginationList)}
											success={Boolean(successInvestorsProfilePaginationList)}
											error={errorInvestorsProfilePaginationList}
										/>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
						{investorProfileDeleteModal && (
							<DeleteProfileInvestorAdminModal
								handleCloseInvestorDeleteModal={handleCloseInvestorDeleteModal}
								investorProfileDeleteModal={investorProfileDeleteModal}
								showInvestorDeleteInfo={showInvestorDeleteInfo}
							/>
						)}
						{investorProfileUploadDocumentModal && (
							<UploadProfileDocumentsModal
								investorProfileUploadDocumentModal={investorProfileUploadDocumentModal}
								handleCloseInvestorProfileUploadDocumentModal={handleCloseInvestorProfileUploadDocumentModal}
								showInvestorProfileUploadDocumentInfo={showInvestorProfileUploadDocumentInfo}
								investorProfiles={investorProfiles}
								setInvestorProfiles={setInvestorProfiles}
							/>
						)}
					</GridItem>
				</GridContainer>
			)}
		</>
	)
}

export default InvestorProfileAdminListScreen
