import { cardTitle } from 'assets/jss/material-dashboard-pro-react'
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
	},
	rootItem: {
		padding: '0 !important',
	},
	loader: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '10px',
	},
	btnwrapper: {
		padding: 10,
		'& svg': {
			margin: 0,
		},
	},
	navLink: {
		color: '#757575 !important',
		borderRadius: '33px',
		lineHeight: '20px',
		textDecoration: 'none',
		margin: 0,
		display: 'inline-flex',
		'& svg': {
			width: 22,
			height: 22,
		},
		'&:hover,&:focus': {
			background: '#f9f6f6',
		},
	},
	imageDropdownButton: {
		padding: '0px',
		borderRadius: '50%',
	},
	dropDownItem: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		'& > p': {
			marginLeft: '10px',
			marginBottom: '0',
		},
	},
	downloadButton: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: '10px',
	},
	...modalStyle(theme),
})

export default styles
