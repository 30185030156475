import { Tooltip } from '@material-ui/core'
import { Delete } from '@mui/icons-material'
import { AccountBox, AccountCircle, NoteAdd } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'

export const investorProfileAdminListColumn = ({
	handleDeployNotesModal,
	navigate,
	openModal,
	serProfileDeletedId,
	setShowInvestorDeleteInfo,
	setInvestorProfileDeleteModal,
}) => {
	return [
		{
			header: 'Razón Social',
			accessor: 'businessName',
		},
		{
			header: 'Entidad',
			accessor: 'personType',
		},
		{
			header: 'Representante',
			accessor: 'owner',
		},
		{
			header: 'Prescriptor',
			accessor: 'prescriber',
		},
		{
			header: 'Numero de cuenta',
			accessor: 'bankAccountNumber',
			hidden: true,
		},
		{
			header: 'DNI',
			accessor: 'dni',
			hidden: true,
		},
		{
			header: 'País donde Reside',
			accessor: 'fiscalNationality',
			hidden: true,
		},
		{
			header: 'Código Postal',
			accessor: 'postalCode',
			hidden: true,
		},
		{
			header: 'Dirección',
			accessor: 'address',
			hidden: true,
		},
		{
			header: 'Acciones',
			accessor: 'actions',
			cellAlign: 'right',
			headerAlign: 'right',
			cell: ({ row }) => {
				const { item } = row
				return (
					<div className='actions-right'>
						<Tooltip id='tooltip-top' title='Ver/Agregar Nota' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => handleDeployNotesModal(item)}
								color='success'
								className='edit'
							>
								<NoteAdd />
							</Button>
						</Tooltip>
						<Tooltip id='tooltip-top' title='Ver/Editar Perfil' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => navigate(`/admin/investor-list-profiles?profileId=${item._id}`)}
								color='success'
								className='edit'
							>
								<AccountBox />
							</Button>
						</Tooltip>
						<Tooltip id='tooltip-top' title='Ver Inversor' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => navigate(`/admin/investor-list-users?investorId=${item.owner._id}`)}
								color='success'
								className='edit'
							>
								<AccountCircle />
							</Button>
						</Tooltip>

						<Button
							justIcon
							round
							simple
							onClick={() => [
								openModal(item, setShowInvestorDeleteInfo, setInvestorProfileDeleteModal),
								serProfileDeletedId(item._id),
							]}
							color='danger'
							className='delete'
						>
							<Delete />
						</Button>
					</div>
				)
			},
		},
	]
}
