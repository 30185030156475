import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { FormControl, InputLabel, makeStyles, MenuItem, Select } from '@material-ui/core'
import { Autocomplete, TextField } from '@mui/material'
import GridItem from 'components/Grid/GridItem'
import styles from '../styles/filterInvestmentStyles'

const useStyles = makeStyles(styles)

const ProfileControlInputs = ({ selectedProfileId, setSelectedProfileId, setInvestmentStatus, investmentStatus }) => {
	const classes = useStyles()

	const [profiles, setProfiles] = useState([])

	const { successInvestorsProfileList, investorsProfileListData } = useSelector((state) => state.investorsProfileList)

	useEffect(() => {
		if (successInvestorsProfileList) {
			setProfiles(investorsProfileListData)
		}
	}, [successInvestorsProfileList])

	return (
		<>
			<GridItem xs={12} sm={6}>
				<FormControl style={{ width: '100%', margin: '16.2px 0 0 0' }}>
					<Autocomplete
						ListboxProps={{
							className: classes.scrollbar,
						}}
						className={classes.autoComplete}
						options={profiles}
						value={profiles.find((item) => item._id === selectedProfileId)}
						onChange={(e, value) => {
							setSelectedProfileId(value._id)
						}}
						disableClearable
						noOptionsText={<span>{'Sin resultados'}</span>}
						getOptionLabel={(e) => e.businessName}
						renderInput={(params) => <TextField {...params} placeholder='Seleccione un perfil' variant='standard' />}
					/>
				</FormControl>
			</GridItem>
			<GridItem xs={12} sm={6}>
				<FormControl fullWidth>
					<InputLabel id='status'>SELECCIONAR FILTRO</InputLabel>
					<Select
						MenuProps={{
							className: classes.selectMenu,
						}}
						disabled={selectedProfileId === ''}
						labelId='status'
						id='status'
						value={investmentStatus}
						onChange={(e) => {
							setInvestmentStatus(e.target.value)
						}}
					>
						{[
							{ label: 'Inversiones vivas'.toLocaleUpperCase(), status: 'active' },
							{ label: 'Inversiones pasadas'.toLocaleUpperCase(), status: 'finished' },
						].map((filter, index) => (
							<MenuItem
								value={filter.status}
								key={index}
								classes={{
									root: classes.selectMenuItem,
									selected: classes.selectMenuItemSelected,
								}}
							>
								{filter.label}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</GridItem>
		</>
	)
}

export default ProfileControlInputs
