import { matchPersonType } from 'shared/matchData/matchData'
export const InvestorProfileListMapper = (investorProfiles) => {
	return investorProfiles.map((item) => ({
		id: item._id,
		personType: matchPersonType(item.personType),
		businessName: item.businessName.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
		documents: item.documents,
		item: item,
	}))
}
