const InvestorRegisterTitlesStyles = {
  fontTitles: {
    '@media (max-width: 600px)': {
      fontSize: '15px',
    },
    '@media (min-width: 600px) and (max-width: 768px)': {
      fontSize: '16.5px',
    },
  },
}

export default InvestorRegisterTitlesStyles
