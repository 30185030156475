const FIXED_RATE_LOANS_INITIAL_STATE = [
	{
		investorProfile: '',
		startDate: '',
		finishDate: '',
		amount: '',
		typesOfInterestRates: '',
		status: '',
		managedBy: '',
	},
]
export default FIXED_RATE_LOANS_INITIAL_STATE
