import { format } from 'date-fns'
import { parseFromStringToCurrencyNumber } from 'shared/helpers/currency-helpers'

const excelMapper = ({ filteredRows }) => {
	return filteredRows
		?.map((item) => {
			return {
				businessName: item?.businessName || '',
				email: item?.email || '',
				projectAddress: item?.projectAddress || '',
				investmentDate: item?.investmentDate ? format(new Date(item?.investmentDate), 'yyyy/MM/dd') : 'Sin Fecha',
				amount: parseFromStringToCurrencyNumber(item.amount),
				incomeRecived: item?.incomeRecived ? format(new Date(item?.incomeRecived), 'dd/MM/yyyy') : 'Confirmar Ingreso',
				singedContract: item?.singedContract || '',
				liquidation: item?.liquidation || '',
				status: item?.status || '',
				managedBy: item.managedBy || '',
			}
		})
		.sort((a, b) => {
			if (a.businessName < b.businessName) {
				return -1
			}
			if (a.businessName > b.businessName) {
				return 1
			}
			return 0
		})
}

export default excelMapper
