const styles = () => ({
  pointer: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  marginAuto: {
    margin: '0% auto',
  },
  Loader: {
    position: 'absolute',
    top: '50%',
    right: '50%',
  },
})

export default styles
