import { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { Attachment } from '@material-ui/icons'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import { replaceStringToNum } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { parseNumberToEURCurrencyString } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import styles from '../styles/liquidatedInvestmentsListResumeStyles'

const useStyles = makeStyles(styles)

const LiquidatedInvestmentsListResume = ({ data }) => {
	const classes = useStyles()

	const [summaryData, setSummaryData] = useState({
		liquidatedAmount: 0,
		liquidatedQuantity: data?.length,
		avgTir: 0,
		profits: 0,
	})

	useEffect(() => {
		const summary = data?.reduce(
			(summary, investment) => {
				summary.liquidatedAmount += replaceStringToNum(investment.amount)
				if (investment.tir && typeof investment.tir === 'number') {
					summary.tirSum += investment.tir
				}
				if (investment.toPay) {
					summary.profits += Math.abs(
						replaceStringToNum(investment.benefit)
					)
				}
				return summary
			},
			{
				liquidatedAmount: 0,
				liquidatedQuantity: data?.length,
				tirSum: 0,
				profits: 0,
			},
		)
		setSummaryData({ ...summary, avgTir: parseFloat(summary?.tirSum / data?.length).toFixed(2) })
	}, [data])

	return (
		<GridContainer>
			<GridItem xs={12} sm={8} md={4}>
				<Card className={classes.cardStyle}>
					<CardHeader color='primary' icon>
						<CardIcon color='primary'>
							<Attachment />
						</CardIcon>
					</CardHeader>
					<CardBody>
						<h4 className={classes.cardTitle}>Inversiones Liquidadas</h4>
						<p>
							Inversiones Liquidadas: <strong>{data.length > 0 ? summaryData.liquidatedQuantity : '-'}</strong>
						</p>
						<p>
							Importe Liquidado:{' '}
							<strong>
								{data.length > 0
									? parseNumberToEURCurrencyString(summaryData.liquidatedAmount + summaryData.profits)
									: '-'}
							</strong>
						</p>
						<p>
							TIR media: <strong>{data.length > 0 ? summaryData.avgTir : '-'} %</strong>
						</p>
						<p>
							Beneficio: <strong>{data.length > 0 ? parseNumberToEURCurrencyString(summaryData.profits) : '-'}</strong>
						</p>
					</CardBody>
				</Card>
			</GridItem>
		</GridContainer>
	)
}

export default LiquidatedInvestmentsListResume
