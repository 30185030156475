import { format } from 'date-fns'
import { parseFromStringToCurrencyNumber } from 'shared/helpers/currency-helpers'
import { matchProjectStatus } from 'shared/matchData/matchData'

export const investmentByInvestorMapper = (investments) => {
	return investments.map((item) => {
		let liquidationField = item.liquidation ? item.liquidation : item.investorProfile?.liquidation || ''

		return {
			id: item._id,
			item: item,
			investmentId: item._id,
			investorProfileId: item.investorProfile._id,
			investmentDate: item.investmentDate && new Date(item.investmentDate),
			projectAddress: item.project?.projectAddress.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
			businessName: item.investorProfile?.businessName.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
			email: item.investorProfile?.email.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
			amount: item.amount,
			liquidation:
				liquidationField === 'pay' ? 'Pagar' : liquidationField === 'reinvest' ? 'Reinvertir' : 'Pagar solo beneficios',
			status: matchProjectStatus(item.project.status),
			incomeRecived:
				item.incomeRecivedDate === undefined
					? 'Confirmar ingreso'
					: item.incomeRecivedDate.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1'),
			managedBy: item.managedBy ? item.managedBy : '',
			singedContract: item.itWasSigned,
			actions: '',
		}
	})
}

export const excelMapper = ({ filteredRows }) => {
	return filteredRows?.map((row) => ({
		...row,
		amount: parseFromStringToCurrencyNumber(row.amount),
		investmentDate: format(row.investmentDate, 'dd/MM/yyyy'),
		incomeRecived: row.incomeRecived ? row.incomeRecived : 'No Confirmado',
		singedContract: row.item.itWasSigned
			? row.item.signed && row.item.contractUrls
				? 'Firmado'
				: 'Pendiente de Firma'
			: 'Firma y Contrato pendientes',
		investorProfile: row?.investorProfile?.props.children,
	}))
}
