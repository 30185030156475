import { useState, useEffect, useContext, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Assignment } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import ExcelDefaultExport from 'components/ReactTable/components/ExcelDefaultExport'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import { FilterReactVirtualizedTableContext } from 'contexts/filterReactVirtualizedTableContext'
import { FilterReactTableContext } from 'contexts/filterReactTableContext'
import RegisterFixedRateLoansModal from './components/RegisterFixedRateLoansModal'
import DeleteFixedRateLoansModal from './components/DeleteFixedRateLoansModal'
import DeleteIncomeDateFixedRateLoansModal from './components/DeleteIncomeDateFixedRateLoansModal'
import IncomeConfirmationFixedRateLoansModal from './components/IncomeConfirmationFixedRateLoansModal'
import fixedRateLoansTableHeaderColumn from './helpers/fixed-rate-loans-table-header-column'
import fixedRateLoansMapper from './mappers/fixed-rate-loans-mapper'
import { FIXED_RATE_LOANS_LIST_RESET } from 'local_redux/constants/fixedRateLoansConstants'
import { getFixedRateLoans, updateIncomeReciveFixedRateLoansById } from 'local_redux/actions/fixedRateLoansActions'
import styles from './styles/fixedRateLoansScreenStyles'

const useStyles = makeStyles(styles)

const FixedRateLoansScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const tableId = 10
	const { filteredRows, setfilteredRows } = useContext(FilterReactTableContext)

	const [data, setData] = useState([])
	const [openRegisterFixedRateLoansModal, setOpenRegisterFixedRateLoansModal] = useState(false)
	const [registerFixedRateLoansModalInfo, setRegisterFixedRateLoansModalInfo] = useState({})
	const [openDeleteFixedRateLoansModal, setOpenDeleteFixedRateLoansModal] = useState(false)
	const [deleteFixedRateLoansModalInfo, setDeleteFixedRateLoansModalInfo] = useState({})
	const [showIncomeRecivedDateFixedRateLoansModal, setShowIncomeRecivedDateFixedRateLoansModal] = useState(false)
	const [showDeleteIncomeRecivedDateFixedRateLoansModal, setShowDeleteIncomeRecivedDateFixedRateLoansModal] =
		useState(false)
	const [selectedDataFixedRateLoans, setSelectedDataFixedRateLoans] = useState(null)

	const { loadingListFixedRateLoans, successFixedRateLoansList, FixedRateLoansListData, errorFixedRateLoansList } =
		useSelector(({ fixedRateLoansList }) => fixedRateLoansList)
	const { successRegisterFixedRateLoans } = useSelector(({ fixedRateLoansRegister }) => fixedRateLoansRegister)
	const { successDeleteFixedRateLoansById } = useSelector(({ fixedRateLoansDeleteById }) => fixedRateLoansDeleteById)
	const { successUpdateFixedRateLoansById } = useSelector(({ fixedRateLoansUpdateById }) => fixedRateLoansUpdateById)
	const { userInfo } = useSelector(({ userLogin }) => userLogin)

	const handleIncomeRecivedFixedRateLoansDate = useCallback((data) => {
		setShowIncomeRecivedDateFixedRateLoansModal(true)
		setSelectedDataFixedRateLoans(data)
	}, [])
	const handleDeleteIncomeRecivedFixedRateLoansDate = useCallback((data) => {
		setShowDeleteIncomeRecivedDateFixedRateLoansModal(true)
		setSelectedDataFixedRateLoans(data)
	}, [])

	useEffect(() => {
		dispatch(getFixedRateLoans())
		return () => {
			dispatch({ type: FIXED_RATE_LOANS_LIST_RESET })
		}
	}, [])
	useEffect(() => {
		if (successFixedRateLoansList) {
			setData(fixedRateLoansMapper(FixedRateLoansListData))
		}
	}, [successFixedRateLoansList])
	useEffect(() => {
		if (successUpdateFixedRateLoansById || successRegisterFixedRateLoans) {
			dispatch({ type: FIXED_RATE_LOANS_LIST_RESET })
		}
	}, [successUpdateFixedRateLoansById, successRegisterFixedRateLoans])
	useEffect(() => {
		if (successDeleteFixedRateLoansById) {
			let fixedRateLoans = data.filter((item) => item.id !== deleteFixedRateLoansModalInfo._id)
			setData(fixedRateLoans)
		}
	}, [successDeleteFixedRateLoansById])
	useEffect(() => {
		const loans = fixedRateLoansTableHeaderColumn(
			handleIncomeRecivedFixedRateLoansDate,
			handleDeleteIncomeRecivedFixedRateLoansDate,
			userInfo,
		)

		if (loans.length > 0) {
			try {
				const mappedData = data.map((item) => {
					const row = {}

					// Map each loan header and assign the data accordingly
					loans.forEach((column) => {
						const { accessor, type } = column

						// Assign data based on accessor and apply formatting if needed
						switch (type) {
							case 'date':
								row[accessor] = item[accessor] ? new Date(item[accessor]).toLocaleDateString() : 'N/A'
								break
							case 'currency':
								row[accessor] = item[accessor] ? `${item[accessor]} €` : '0 €'
								break
							default:
								row[accessor] = item[accessor] || 'N/A'
								break
						}
					})

					return row
				})

				setfilteredRows(mappedData)
			} catch (error) {
				console.error(error)
			}
		}
	}, [
		data,
		handleIncomeRecivedFixedRateLoansDate,
		handleDeleteIncomeRecivedFixedRateLoansDate,
		userInfo,
		setfilteredRows,
	])

	const { getFilteredDataRows, getFilteredDataColumns } = useContext(FilterReactVirtualizedTableContext) //context of table
	const handleCloseFixedRateLoansModal = () => {
		setOpenRegisterFixedRateLoansModal(false)
		setRegisterFixedRateLoansModalInfo({})
	}
	const handleOpenRegisterFixedRateLoansModal = (fixedRateLoan) => {
		const data = fixedRateLoan ? fixedRateLoan : {}
		setOpenRegisterFixedRateLoansModal(true)
		setRegisterFixedRateLoansModalInfo(data)
	}
	const handleCloseDeleteFixedRateLoansModal = () => {
		setOpenDeleteFixedRateLoansModal(false)
		setDeleteFixedRateLoansModalInfo({})
	}
	const updateIncomeRecivedFixedRateLoansDate = (id, date, userEmail) => {
		//IncomeRecived
		const data = {
			field: 'incomeRecivedDate',
			value: date,
			userEmail,
		}
		dispatch(updateIncomeReciveFixedRateLoansById(id, data))
	}
	const handleCloseIncomeFixedRateLoansModal = () => {
		//IncomeRecived
		setShowIncomeRecivedDateFixedRateLoansModal(false)
		setSelectedDataFixedRateLoans(null)
	}
	const handleCloseIncomeDeleteFixedRateLoansModal = () => {
		//IncomeRecived
		setShowDeleteIncomeRecivedDateFixedRateLoansModal(false)
		setSelectedDataFixedRateLoans(null)
	}
	const deleteIncomeRecivedDateFixedRateLoans = (id, reason) => {
		//IncomeRecived
		dispatch(updateIncomeReciveFixedRateLoansById(id, { field: 'incomeRecivedDate', reason }))
	}

	return (
		<>
			<GridContainer>
				<GridItem xs={12} className={classes.rootItem}>
					<Button color='primary' onClick={handleOpenRegisterFixedRateLoansModal}>
						Nuevo Préstamo
					</Button>
				</GridItem>
				<GridItem xs={12} className={classes.rootItem}>
					<Card>
						<CardHeader color='primary' icon>
							<CardIcon color='primary'>
								<Assignment />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>Prestamos a T. Fijo</h4>
							<div style={{ display: 'flex', justifyContent: 'end' }}>
								{Boolean(getFilteredDataRows({ tableId })?.length) && (
									<ExcelDefaultExport
										excelName='Lista de Prestamos a T. Fijo'
										data={filteredRows}
										tableColumns={getFilteredDataColumns({ tableId })}
									/>
								)}
							</div>
						</CardHeader>
						<CardBody>
							<ReactVirtualizedTable
								tableId={tableId}
								columns={fixedRateLoansTableHeaderColumn(
									handleIncomeRecivedFixedRateLoansDate,
									handleDeleteIncomeRecivedFixedRateLoansDate,
									userInfo,
								)}
								data={data}
								loading={Boolean(loadingListFixedRateLoans)}
								success={Boolean(successFixedRateLoansList)}
								error={errorFixedRateLoansList}
							/>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
			{openRegisterFixedRateLoansModal && (
				<RegisterFixedRateLoansModal
					handleCloseFixedRateLoansModal={handleCloseFixedRateLoansModal}
					registerFixedRateLoansModal={openRegisterFixedRateLoansModal}
					showFixedRateLoansModalInfo={registerFixedRateLoansModalInfo}
					userInfo={userInfo}
					data={data}
					setData={setData}
				/>
			)}
			{openDeleteFixedRateLoansModal && (
				<DeleteFixedRateLoansModal
					handleCloseDeleteFixedRateLoansModal={handleCloseDeleteFixedRateLoansModal}
					deleteFixedRateLoansModal={openDeleteFixedRateLoansModal}
					showDeleteFixedRateLoansInfo={deleteFixedRateLoansModalInfo}
				/>
			)}
			{showIncomeRecivedDateFixedRateLoansModal && (
				<IncomeConfirmationFixedRateLoansModal //IncomeRecived
					handleCloseIncomeUpdateFixedRateLoansModal={handleCloseIncomeFixedRateLoansModal}
					incomeUpdateFixedRateLoansModal={showIncomeRecivedDateFixedRateLoansModal}
					showIncomeUpdateInfoFixedRateLoans={selectedDataFixedRateLoans}
					updateIncomeRecivedFixedRateLoansDate={updateIncomeRecivedFixedRateLoansDate}
				/>
			)}
			{showDeleteIncomeRecivedDateFixedRateLoansModal && (
				<DeleteIncomeDateFixedRateLoansModal
					deleteFixedRateLoansDateAdminModal={showDeleteIncomeRecivedDateFixedRateLoansModal}
					handleCloseFixedRateLoansDateAdminModal={handleCloseIncomeDeleteFixedRateLoansModal}
					showDeleteFixedRateLoansDateInfo={selectedDataFixedRateLoans}
					deleteIncomeRecivedDateFixedRateLoans={deleteIncomeRecivedDateFixedRateLoans}
				/>
			)}
		</>
	)
}

export default FixedRateLoansScreen
