export const investorUsersListColumns=[
    {
        header: 'Nombre',
        accessor: 'businessName',
    },
    {
        header: 'Email',
        accessor: 'email',
    },
    {
        header: 'Acciones',
        accessor: 'actions',
        size: 'sm',
        cellAlign: 'right',
        headerAlign: 'right',
    },
]