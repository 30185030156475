import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import CustomInput from 'components/CustomInput/CustomInput'
import { deleteInvestorProfile } from 'local_redux/actions/investorProfileActions'
import { INVESTOR_PROFILE_DELETE_RESET } from 'local_redux/constants/investorProfileConstants'
import styles from '../styles/deleteProfileAdminModalStyles'

const useStyles = makeStyles(styles)

const DeleteProfileInvestorAdminModal = ({
  handleCloseInvestorDeleteModal,
  investorProfileDeleteModal,
  showInvestorDeleteInfo,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [phrase, setPhrase] = useState('')

  const { successInvestorProfileDelete, errorInvestorProfileDelete, loadingInvestorProfileDelete } = useSelector(
    (state) => state.investorProfileDelete
  )

  useEffect(() => {
    let timer = ''
    if (successInvestorProfileDelete) {
      timer = setTimeout(() => {
        dispatch({ type: INVESTOR_PROFILE_DELETE_RESET })
        handleCloseInvestorDeleteModal()
      }, 2000)
    }

    return () => clearTimeout(timer)
  }, [successInvestorProfileDelete])

  const handleDeleteUser = (e) => {
    e.preventDefault()
    dispatch(deleteInvestorProfile(showInvestorDeleteInfo))
  }

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={investorProfileDeleteModal}
      keepMounted
      onClose={handleCloseInvestorDeleteModal}
      aria-labelledby='user-modal-delete-title'
      aria-describedby='user-modal-delete-description'
    >
      <DialogTitle id='user-modal-delete-title' disableTypography className={classes.modalHeader}>
        <Button
          justIcon
          className={classes.modalCloseButton}
          key='close'
          aria-label='Close'
          color='transparent'
          onClick={handleCloseInvestorDeleteModal}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4>Eliminar Perfil Inversor</h4>
      </DialogTitle>
      <DialogContent id='user-modal-delete-description' className={classes.modalBody}>
        <form onSubmit={handleDeleteUser} id='delete-profile-investor-form'>
          <GridContainer>
            <GridItem xs={12}>
              <Typography>
                ¿Está seguro de que desea eliminar el perfil inversor{' '}
                <strong>{showInvestorDeleteInfo.businessName}</strong>?
              </Typography>
              <br />
              <Typography>
                Una vez que un perfil inversor se elimina de forma permanente, no se puede recuperar.
              </Typography>
              <br />
              <Typography>Escriba la siguiente frase para habilitar el botón Eliminar.</Typography>
              <p className={classes.deletePhrase}>absolutamente seguro</p>
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                inputProps={{
                  autoFocus: true,
                  placeholder: 'Escriba la frase aquí',
                  type: 'text',
                  autoComplete: 'off',
                  onChange: (e) => setPhrase(e.target.value.toLowerCase()),
                }}
                formControlProps={{
                  fullWidth: true,
                }}
              />
            </GridItem>
          </GridContainer>

          {errorInvestorProfileDelete && (
            <GridContainer>
              <GridItem xs={12}>
                <SnackbarContent message={errorInvestorProfileDelete} color='danger' />
              </GridItem>
            </GridContainer>
          )}
        </form>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <GridContainer>
          <GridItem xs={6}>
            <Button onClick={handleCloseInvestorDeleteModal} block>
              Cancelar
            </Button>
          </GridItem>
          <GridItem xs={6}>
            <Button
              disabled={phrase !== 'absolutamente seguro'}
              type='submit'
              color={successInvestorProfileDelete ? 'success' : 'primary'}
              block
              form='delete-profile-investor-form'
            >
              {loadingInvestorProfileDelete ? 'Eliminando' : successInvestorProfileDelete ? 'Eliminado' : 'Eliminar'}
            </Button>
          </GridItem>
        </GridContainer>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteProfileInvestorAdminModal
