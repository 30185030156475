import { cardTitle } from 'assets/jss/material-dashboard-pro-react'
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
  btnwrapper: {
		padding: 10,
		'& svg': {
			margin: 0,
		},
	},
	navLink: {
		color: '#757575 !important',
		borderRadius: '33px',
		lineHeight: '20px',
		textDecoration: 'none',
		margin: 0,
		display: 'inline-flex',
		'& svg': {
			width: 22,
			height: 22,
		},
		'&:hover,&:focus': {
			background: '#f9f6f6',
		},
	},
	imageDropdownButton: {
		padding: '0px',
		borderRadius: '50%',
	},
  tablet: {
    display: 'flex',
    '& thead': {
      '& tr': {
        '& th': {
          '&:first-child': {
            zIndex: '10',
            position: 'sticky !important',
            width: '5em',
            left: '0',
            top: 'auto',
            borderTopWidth: '1px',
            backgroundColor: '#fff',
          },
        },
      },
    },
    '& tbody': {
      overflow: 'inherit !important',
      '& tr': {
        '&:hover': {
          '& td:first-child': { backgroundColor: '#eeece2' },
          '& td:nth-child(n+2)': { backgroundColor: 'transparent' },
        },
        '&:nth-child(odd)': {
          '& td': { backgroundColor: '#f2f1eb' },
        },
        '&:nth-child(even)': {
          '& td': { backgroundColor: '#fff' },
        },
        '& td:first-child': {
          zIndex: '10',
          position: 'sticky !important',
          width: '5em',
          left: '0',
          top: 'auto',
          borderTopWidth: '1px',
        },
      },
    },
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  rootItem: {
    padding: '0 !important',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  confirmBtnCssClass: {
    backgroundColor: '#ebe6e2',
    color: '#333333',
    padding: '10px',
    width: '5rem',
    borderRadius: '3px',
    '&:hover': {
      color: '#333333',
    },
  },
  downloadButton: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: '10px',
	},
  ...modalStyle(theme),
})

export default styles
