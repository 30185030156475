import { makeStyles } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import styles from './styles/materialForPrescribersScreenStyles'

const useStyles = makeStyles(styles)

const MaterialForPrescribersScreen = () => {
  const classes = useStyles()

  return (
    <GridContainer>
      <Card>
        <CardBody>
          <iframe src='https://prescriptores.c-g.es/' className={classes.iframe} name='demo'>
            <p>Su navegador no es compatible con iframes</p>
          </iframe>
        </CardBody>
      </Card>
    </GridContainer>
  )
}

export default MaterialForPrescribersScreen
