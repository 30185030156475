import OptionsMenuTable from 'components/OptionsMenuTable/OptionsMenuTable'
import { getLiquidationStatus, getProjectStatus } from '../helpers/match-data'
import { openModal } from 'shared/helpers/modal-helper'

const investmentsListMapper = ({
	investments,
	setShowDetailInvestmentAdminInfo,
	setDetailInvestmentAdminModal,
	setShowDeleteInvestmentInfo,
	setDeleteInvestmentAdminModal,
	setIdInvestment,
	handleDeployNotesModal,
	classes,
}) => {
	return investments?.map((item) => {
		let liquidationField = item.liquidation ? item.liquidation : item.investorProfile?.liquidation || ''
		const contractStatus = item.itWasSigned
			? item.signed && item.contractUrl
				? 'Firmado'
				: 'Pendiente de Firma'
			: 'Firma y Contrato pendientes'
		return {
			item: item,
			id: item._id,
			investmentId: item?._id,
			investorProfileId: item?.investorProfile._id,
			contractUrl: item?.contractUrl,
			hasComments: item?.hasComments ? 'Tiene' : 'No tiene',
			profileInvestor: item?.investorProfile,
			//useFields
			businessName: item?.investorProfile?.businessName.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
			email: item.investorProfile?.email.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
			projectAddress: item?.project?.projectAddress.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
			investmentDate: item?.investmentDate,
			amount: item?.amount,
			incomeRecived: Boolean(item?.incomeRecivedDate) ? new Date(item?.incomeRecivedDate) : null,
			singedContract: contractStatus,
			liquidation: getLiquidationStatus(liquidationField),
			status: getProjectStatus(item.project.status),
			managedBy: item?.managedBy,
			actions: (
				<OptionsMenuTable
					options={[
						<div className={classes.dropDownItem} onClick={() => handleDeployNotesModal(item)}>
							<p>Comentarios</p>
						</div>,
						<div
							className={classes.dropDownItem}
							onClick={() => openModal(item, setShowDetailInvestmentAdminInfo, setDetailInvestmentAdminModal)}
						>
							<p>Ver Inversión</p>
						</div>,
						<div
							className={classes.dropDownItem}
							onClick={() => {
								openModal(item, setShowDeleteInvestmentInfo, setDeleteInvestmentAdminModal)
								setIdInvestment(item._id)
							}}
						>
							<p>Eliminar Inversión</p>
						</div>,
					]}
				/>
			),
		}
	})
}

export default investmentsListMapper
