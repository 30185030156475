import { useSelector } from 'react-redux'
import { MenuItem, FormControl, Select, InputLabel } from '@material-ui/core'
import { Autocomplete, TextField, InputLabel as InputLabelNew } from '@mui/material'
import CustomInput from 'components/CustomInput/CustomInput'
import GridContainer from 'components/Grid/GridContainer'
import Button from 'components/CustomButtons/Button'
import GridItem from 'components/Grid/GridItem'
import Retention from 'components/Retention/Retention'
import { countriesList } from 'shared/helpers/nationality-helpers'

const InputsLegalProfile = ({
	userInfo,
	setUserInfo,
	investorsProfileListData,
	handleChangeFile,
	fileInput,
	handleSelectFile,
	setErrorTaxResidenceFile,
	classes,
}) => {
	const { retentionListData } = useSelector((state) => state.retentionList)

	return (
		<GridContainer>
			<GridItem xs={12}>
				<CustomInput
					labelText='Razón Social *'
					id='investor-businessName'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.businessName,
						onChange: (e) => {
							setUserInfo({ ...userInfo, businessName: e.target.value })
						},
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={6}>
				<CustomInput
					labelText='CIF *'
					id='investor-cif'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.cif,
						onChange: (e) => {
							setUserInfo({ ...userInfo, cif: e.target.value, nif: e.target.value })
						},
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={6}>
				<CustomInput
					labelText={'Email  *'}
					id='investor-email'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.businessEmail,
						onChange: (e) => {
							setUserInfo({ ...userInfo, businessEmail: e.target.value })
						},
						type: 'email',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={6}>
				<CustomInput
					labelText={'Teléfono *'}
					id='investor-phone'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.businessPhone,
						onChange: (e) => {
							setUserInfo({ ...userInfo, businessPhone: e.target.value })
						},
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={6}>
				<CustomInput
					labelText='Teléfono 2 (No principal)'
					id='phone2'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.phone2,
						onChange: (e) => setUserInfo({ ...userInfo, phone2: e.target.value }),
						type: 'text',
					}}
				/>
			</GridItem>
			<GridItem xs={12}>
				<CustomInput
					labelText={'Dirección *'}
					id='investor-address'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.businessAddress,
						onChange: (e) => {
							setUserInfo({ ...userInfo, businessAddress: e.target.value })
						},
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={4}>
				<CustomInput
					labelText='Provincia *'
					id='investor-province'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.province,
						onChange: (e) => {
							setUserInfo({ ...userInfo, province: e.target.value })
						},
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={4}>
				<CustomInput
					labelText='Localidad *'
					id='investor-location'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.location,
						onChange: (e) => {
							setUserInfo({ ...userInfo, location: e.target.value })
						},
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={4}>
				<CustomInput
					labelText='Código postal *'
					id='investor-postalCode'
					error={
						(userInfo.postalCode && userInfo.postalCode.length < 4) ||
						(userInfo.postalCode && userInfo.postalCode.length > 5)
					}
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.postalCode,
						onChange: (e) => {
							setUserInfo({ ...userInfo, postalCode: e.target.value })
						},
						type: 'number',
						required: true,
					}}
				/>
			</GridItem>
			<Retention setInfo={setUserInfo} info={userInfo} setErrorTaxResidenceFile={setErrorTaxResidenceFile} />
			<GridItem xs={12} sm={2}>
				<CustomInput
					labelText={'Retención *'}
					id='investor-retention'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.country
							? `${retentionListData?.find((item) => item.country === userInfo.country)?.retention} %`
							: userInfo.retention
							? userInfo.retention
							: '19%',
						type: 'text',
						disabled: true,
						required: true,
					}}
				/>
			</GridItem>
			<GridItem
				xs={12}
				sm={5}
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'bottom',
					flexDirextion: 'column',
				}}
			>
				<div className='fileinput'>
					<input
						type='file'
						name='residenceCertificate'
						accept='.pdf,'
						id='residenceCertificate'
						onChange={handleChangeFile}
						ref={fileInput}
					/>
				</div>

				<Button
					style={{ marginTop: '1.3rem' }}
					disabled={userInfo.residenceCertificate ? true : false}
					size='sm'
					block
					onClick={() => handleSelectFile()}
				>
					Certificado de Residencia Fiscal
				</Button>
			</GridItem>
			<GridItem xs={12} sm={6}>
				<FormControl variant='standard' style={{ width: '100%', margin: '12px 0 0 0' }}>
					<InputLabel id='investor-liquidated-label' sx={{ minWidth: '100%' }}>
						Liquidación
					</InputLabel>
					<Select
						labelId='investor-liquidated-label'
						id='investor-liquidated'
						value={userInfo.liquidation ? userInfo.liquidation : 'pay'}
						onChange={(e) => {
							setUserInfo({ ...userInfo, liquidation: e.target.value })
						}}
						label='liquidated'
						sx={{ minWidth: '100%' }}
					>
						<MenuItem value={'pay'}>Pagar</MenuItem>
						<MenuItem value={'reinvest'}>Reinvertir</MenuItem>
						<MenuItem value={'benefits-only'}>Pagar solo beneficios</MenuItem>
					</Select>
				</FormControl>
			</GridItem>
			<GridItem xs={12} sm={6}>
				<CustomInput
					labelText={'IBAN '}
					id='investor-iban'
					helperText='Formato ES9999999999999999999999'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.bankAccountNumber,
						onChange: (e) => {
							setUserInfo({ ...userInfo, bankAccountNumber: e.target.value })
						},
						type: 'text',
					}}
				/>
			</GridItem>
			<GridItem xs={12}>
				<CustomInput
					labelText={'Nacionalidad fiscal de la empresa *'}
					id='fiscalNationality'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.fiscalNationality,
						onChange: (e) => {
							setUserInfo({ ...userInfo, fiscalNationality: e.target.value })
						},
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={4}>
				<CustomInput
					labelText={'Nombre Representante *'}
					id='representative-name'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.representativeName,
						onChange: (e) => {
							setUserInfo({ ...userInfo, representativeName: e.target.value })
						},
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={4}>
				<CustomInput
					labelText={'Apellidos Representante *'}
					id='investor-identification'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.representativeLastName,
						onChange: (e) => {
							setUserInfo({ ...userInfo, representativeLastName: e.target.value })
						},
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={4}>
				<CustomInput
					labelText={'NIF Representante *'}
					id='investor-identification'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.representativeNif,
						onChange: (e) => {
							setUserInfo({ ...userInfo, representativeNif: e.target.value })
						},
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={6}>
				<CustomInput
					labelText={'Dirección Representante *'}
					id='investor-address'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.representativeAddress,
						onChange: (e) => {
							setUserInfo({ ...userInfo, representativeAddress: e.target.value })
						},
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={6}>
				<FormControl style={{ width: '100%', margin: '12px 0 0 0' }}>
					<InputLabelNew htmlFor='update-representativeNacionality' style={{ fontSize: '13px', marginBottom: '3px' }}>
						Nacionalidad Representante *
					</InputLabelNew>
					<Autocomplete
						ListboxProps={{
							className: classes.scrollbar,
						}}
						className={classes.autoComplete}
						disablePortal
						options={countriesList.map((item) => item.name.toUpperCase())}
						value={userInfo?.nationalityOfTheRepresentative}
						onChange={(e, value) => {
							setUserInfo({ ...userInfo, nationalityOfTheRepresentative: value?.toLowerCase() })
						}}
						noOptionsText={<span>{'Sin resultados'}</span>}
						getOptionLabel={(e) => e}
						renderInput={(params) => (
							<TextField {...params} placeholder='Seleccione un país' variant='standard' required={true} />
						)}
					/>
				</FormControl>
			</GridItem>
			<GridItem xs={12} sm={6}>
				<FormControl variant='standard' style={{ width: '100%', margin: '12px 0 0 0' }}>
					<InputLabel id='investor-prescriber-label' sx={{ minWidth: '100%' }}>
						Perfiles Prescriptor
					</InputLabel>
					<Select
						labelId='investor-prescriber-label'
						id='investor-prescriber'
						value={userInfo.prescriber}
						onChange={(e) => {
							setUserInfo({ ...userInfo, prescriber: e.target.value })
						}}
						label='Prescriber'
						sx={{ minWidth: '100%' }}
					>
						<MenuItem disabled>Selecciona Uno</MenuItem>
						{investorsProfileListData &&
							investorsProfileListData.map((profile) => (
								<MenuItem key={profile._id} value={profile._id}>
									{profile.businessName}
								</MenuItem>
							))}
					</Select>
				</FormControl>
			</GridItem>
		</GridContainer>
	)
}

export default InputsLegalProfile
