import { replaceStringToNum } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { formatCurrency } from 'shared/helpers/currency-helpers'

const excelData = (data) => {
	return data
		?.map((item) => {
			return {
				amount: replaceStringToNum(item.amount),
				benefit: replaceStringToNum(item.benefit),
				toPay: replaceStringToNum(item.toPay),
				incomeRecivedDate: item.incomeRecivedDate ? item.incomeRecivedDate : 'No Confirmado',
				projectAddress: item?.projectAddress?.toUpperCase() || '',
				investorProfile: item?.investorProfile?.toUpperCase() || '',
				email: item?.email,
				retention: item?.retention,
				startDateUpdated: item?.startDateUpdated,
				dateLiquidation: item?.dateLiquidation,
				investorTIR: item?.investorTIR,
				liquidation: item?.liquidation,
				withHoldingAmount: formatCurrency(
					(parseFloat(item.benefit.replace('€', '').replace('.', '').replace(',', '.').replace('−', '-').trim()) *
						parseFloat(item.retention.replace('%', '').trim())) /
						100,
				),
			}
		})
		.sort((a, b) => {
			if (a.investorProfile < b.investorProfile) {
				return -1
			}
			if (a.investorProfile > b.investorProfile) {
				return 1
			}
			return 0
		})
}

export default excelData
