const liquidationStatus = {
	pay: 'Pagar',
	reinvest: 'Reinvertir',
	// 'benefits-only': 'Pagar solo beneficios',
}

const projectStatus = {
	startSoon: 'Inicia pronto',
	workInProgress: 'Obras en Curso',
	onSale: 'En Venta',
	reserved: 'Reservado',
	finished: 'Finalizado',
	liquidated: 'Liquidado',
	notPublished: 'No Publicado',
}

export const getLiquidationStatus = (status) => {
	return liquidationStatus[status] || 'Pagar solo beneficios'
}

export const getProjectStatus = (status) => {
	return projectStatus[status] || null
}
