import { cardTitle } from 'assets/jss/material-dashboard-pro-react'
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  steepsContainer: {
    marginBottom: '16px',
    padding: '0 !important',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rootItem: {
    padding: '0 !important',
  },
  ...modalStyle(theme),
})

export default styles
