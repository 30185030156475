const reasonsListColumns = () => {
	return [
		{
			header: 'Fecha',
			accessor: 'created',
			type: 'date',
		},
		{
			header: 'Notificado por',
			accessor: 'user',
		},
		{
			header: 'Nota',
			accessor: 'reason',
		},
	]
}

export default reasonsListColumns
