import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Close } from '@material-ui/icons'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from '@mui/material/Typography'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { FIXED_RATE_LOANS_DELETE_BY_ID_RESET } from 'local_redux/constants/fixedRateLoansConstants'
import { deleteFixedRateLoansById } from 'local_redux/actions/fixedRateLoansActions'
import styles from '../styles/deleteFixedRateLoansModalStyles'

const useStyles = makeStyles(styles)

const DeleteFixedRateLoansModal = ({
	handleCloseDeleteFixedRateLoansModal,
	deleteFixedRateLoansModal,
	showDeleteFixedRateLoansInfo,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const { loadingDeleteFixedRateLoansById, successDeleteFixedRateLoansById, errorDeleteFixedRateLoansById } =
		useSelector((state) => state.fixedRateLoansDeleteById)

	useEffect(() => {
		let timeOut
		if (successDeleteFixedRateLoansById) {
			timeOut = setTimeout(() => {
				handleCloseDeleteFixedRateLoansModal()
			}, 1500)
		}
		return () => clearTimeout(timeOut)
	}, [successDeleteFixedRateLoansById])

	useEffect(() => {
		return () => dispatch({ type: FIXED_RATE_LOANS_DELETE_BY_ID_RESET })
	}, [dispatch])

	const handleDeleteFixedRateLoans = (e) => {
		e.preventDefault()
		dispatch(deleteFixedRateLoansById(showDeleteFixedRateLoansInfo._id))
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={deleteFixedRateLoansModal}
			keepMounted
			onClose={handleCloseDeleteFixedRateLoansModal}
			aria-labelledby='delete-fixed-rate-loans-title'
			aria-describedby='delete-fixed-rate-loans-description'
		>
			<form onSubmit={handleDeleteFixedRateLoans}>
				<DialogTitle id='delete-fixed-rate-loans-title' disableTypography className={classes.modalHeader}>
					<Button
						justIcon
						className={classes.modalCloseButton}
						key='close'
						aria-label='Close'
						color='transparent'
						onClick={handleCloseDeleteFixedRateLoansModal}
					>
						<Close className={classes.modalClose} />
					</Button>
					<h4>Eliminar Préstamo</h4>
				</DialogTitle>
				<DialogContent id='delete-fixed-rate-loans-description' className={classes.modalBody}>
					<GridContainer>
						<GridItem xs={12}>
							<Typography>
								¿Está seguro que quiere eliminar el registro <b>{showDeleteFixedRateLoansInfo?.country}</b>?
							</Typography>
						</GridItem>
					</GridContainer>
					{errorDeleteFixedRateLoansById && (
						<GridContainer>
							<GridItem xs={12}>
								<SnackbarContent message={errorDeleteFixedRateLoansById} color='danger' />
							</GridItem>
						</GridContainer>
					)}
				</DialogContent>
				<DialogActions className={classes.modalFooter}>
					<GridContainer>
						<GridItem xs={6}>
							<Button onClick={handleCloseDeleteFixedRateLoansModal} block>
								Cancelar
							</Button>
						</GridItem>
						<GridItem xs={6}>
							<Button type='onSubmit' color={successDeleteFixedRateLoansById ? 'success' : 'primary'} block>
								{loadingDeleteFixedRateLoansById
									? 'Eliminando'
									: successDeleteFixedRateLoansById
									? 'Eliminado'
									: 'Eliminar'}
							</Button>
						</GridItem>
					</GridContainer>
				</DialogActions>
			</form>
		</Dialog>
	)
}

export default DeleteFixedRateLoansModal
