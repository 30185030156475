const fixedRateLoansTableHeaderColumn = () => {
	return [
		{
			header: 'Perfil Inversor',
			accessor: 'investorProfile',
			size: 'sm',
		},
		{
			header: 'Fecha Registro',
			accessor: 'createdAt',
			type: 'date',
			size: 'sm',
		},
		{
			header: 'Fecha Inicio',
			accessor: 'startDate',
			size: 'sm',
			type: 'date',
		},
		{
			header: 'Fecha Fin',
			accessor: 'finishDate',
			size: 'sm',
			type: 'date',
		},
		{
			header: 'Importe',
			accessor: 'amount',
			size: 'sm',
			type: 'currency',
		},
		{
			header: 'Interés',
			accessor: 'typesOfInterestRates',
			size: 'xs',
		},
		{
			header: 'Estado',
			accessor: 'status',
			size: 'xs',
		},
		{
			header: 'Gestionado por',
			accessor: 'user',
			size: 'sm',
		},
		{
			header: 'Acciones',
			accessor: 'actions',
			size: 'sm',
			hidden: true,
			cellAlign: 'right',
			headerAlign: 'right',
		},
	]
}

export default fixedRateLoansTableHeaderColumn
