import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Close } from '@material-ui/icons'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Typography from '@mui/material/Typography'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { deleteUser } from 'local_redux/actions/userActions'
import { USER_DELETE_RESET, USER_DETAILS_RESET } from 'local_redux/constants/userConstants'
import { INVESTOR_LIST_RESET } from 'local_redux/constants/investorConstant'
import { INVESTORS_PROFILE_BY_USER_RESET } from 'local_redux/constants/investorProfileConstants'
import { investorsProfileByUser } from 'local_redux/actions/investorProfileActions'
import styles from '../styles/InvestorUserDeleteModalStyles'

const useStyles = makeStyles(styles)

const InvestorUserDeleteModal = ({ handleCloseDeleteUserModal, deleteUserModal, showDeleteUserInfo }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [deleteValidationError, setDeleteValidationError] = useState()

  const { successUserDelete, errorUserDelete, loadingUserDelete } = useSelector((state) => state.userDelete)
  const { loadingInvestorsProfileByUser, investorsProfileByUserData } = useSelector(
    (state) => state.investorsProfileByUser
  )
  useEffect(() => {
    dispatch(investorsProfileByUser(showDeleteUserInfo._id))
  }, [showDeleteUserInfo])

  useEffect(() => {
    let timeOut = ''
    if (successUserDelete) {
      setDeleteValidationError('')
      timeOut = setTimeout(() => {
        dispatch({ type: USER_DETAILS_RESET })
        dispatch({ type: INVESTOR_LIST_RESET })
        dispatch({ type: USER_DELETE_RESET })
        dispatch({ type: INVESTORS_PROFILE_BY_USER_RESET })
        handleCloseDeleteUserModal()
      }, 2000)
    }
    return () => clearTimeout(timeOut)
  }, [successUserDelete])

  useEffect(() => {
    return dispatch({ type: INVESTORS_PROFILE_BY_USER_RESET })
  }, [dispatch])

  const handleDeleteUser = (e) => {
    e.preventDefault()
    if (investorsProfileByUserData && investorsProfileByUserData.length > 0) {
      return setDeleteValidationError('Antes de eliminar el usuario usted debe borrar todos los perfiles asociados.')
    }
    dispatch(deleteUser(showDeleteUserInfo._id))
  }
  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={deleteUserModal}
      keepMounted
      onClose={handleCloseDeleteUserModal}
      aria-labelledby='user-modal-delete-title'
      aria-describedby='user-modal-delete-description'
    >
      <form onSubmit={handleDeleteUser}>
        <DialogTitle id='user-modal-delete-title' disableTypography className={classes.modalHeader}>
          <Button
            justIcon
            className={classes.modalCloseButton}
            key='close'
            aria-label='Close'
            color='transparent'
            onClick={handleCloseDeleteUserModal}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4>Eliminar Inversor</h4>
        </DialogTitle>

        <DialogContent id='user-modal-delete-description' className={classes.modalBody}>
          <GridContainer>
            {loadingInvestorsProfileByUser ? (
              <GridItem xs={12}>Cargando</GridItem>
            ) : investorsProfileByUserData?.length > 0 ? (
              <GridItem xs={12}>
                <Typography variant='body1'>
                  El usuario <b>{showDeleteUserInfo?.email}</b> tiene{' '}
                  {investorsProfileByUserData.length < 2
                    ? `1 perfil inversor asociado`
                    : `${investorsProfileByUserData.length} perfiles inversores asociados`}
                  .
                  <br />
                  Para borrar definitivamente el usuario usted deberá borrar primero{' '}
                  {investorsProfileByUserData.length < 2 ? `el perfil` : `los perfiles`}.
                </Typography>
              </GridItem>
            ) : (
              <GridItem xs={12}>
                <Typography>
                  ¿Está seguro que quiere eliminar el inversor <strong>{showDeleteUserInfo?.email}</strong>?
                </Typography>
              </GridItem>
            )}
          </GridContainer>

          {errorUserDelete && (
            <GridContainer>
              <GridItem xs={12}>
                <SnackbarContent message={errorUserDelete} color='danger' />
              </GridItem>
            </GridContainer>
          )}

          {deleteValidationError && (
            <GridContainer>
              <GridItem xs={12}>
                <SnackbarContent message={deleteValidationError} color='danger' />
              </GridItem>
            </GridContainer>
          )}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <GridContainer>
            {investorsProfileByUserData?.length > 0 ? (
              <GridItem xs={12}>
                <Button onClick={handleCloseDeleteUserModal} block>
                  Cerrar
                </Button>
              </GridItem>
            ) : (
              <>
                <GridItem xs={6}>
                  <Button onClick={handleCloseDeleteUserModal} block>
                    Cancelar
                  </Button>
                </GridItem>
                <GridItem xs={6}>
                  <Button type='onSubmit' color={successUserDelete ? 'success' : 'primary'} block>
                    {loadingUserDelete ? 'Eliminando' : successUserDelete ? 'Eliminado' : 'Eliminar'}
                  </Button>
                </GridItem>
              </>
            )}
          </GridContainer>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default InvestorUserDeleteModal
