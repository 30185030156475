import { useState, useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Assignment } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import DetailInvestmentAdminModal from 'components/DetailInvestmentAdminModal/DetailInvestmentAdminModal'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import ExcelDefaultExport from 'components/ReactTable/components/ExcelDefaultExport'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import NotesModal from 'components/CustomModals/NotesModal/NotesModal'
import Button from 'components/CustomButtons/Button'
import DownloadInvestmentContractsModal from 'components/DownloadInvestmentContractsModal/DownloadInvestmentContractsModal'
import InvestmentAdminDeleteModal from './components/InvestmentAdminDeleteModal'
import SubComponentsPikers from './components/IncomeConfirmationModal'
import DeleteIncomeDateModal from './components/DeleteIncomeDateModal'
import UploadContractModal from './components/UploadContractModal'
import InvestmentsListResume from './components/InvestmentsListResume'
import SignedModal from './components/SignedModal'
import DownloadStatusModal from './components/DownloadStatusModal'
import INVESTMENTADMINLIST_COLUMNS from './helpers/investments-admin-list-columns'
import investmentsListMapper from './mappers/investments-list-mapper'
import excelMapper from './mappers/excel-mapper'
import { openModal, closeModal } from 'shared/helpers/modal-helper'
import { FilterReactVirtualizedTableContext } from 'contexts/filterReactVirtualizedTableContext'
import { getUnfinishedInvestmentsList, getContractsInfoToDownload } from 'local_redux/actions/investmentActions'
import { registerLog } from 'local_redux/actions/logActions'
import { PROFILE_GET_NOTES_RESET } from 'local_redux/constants/userConstants'
import { INVESTMENT_LIST_RESET, GET_CONTRACTS_INFO_TO_DOWNLOAD_RESET } from 'local_redux/constants/investmentConstants'
import { LOG_REGISTER_RESET } from 'local_redux/constants/logConstants'
import styles from './styles/investmentAdminListScreenStyles'

const useStyles = makeStyles(styles)

const InvestmentAdminListScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const tableId = 1

	const [data, setData] = useState([])
	const [detailInvestmentAdminModal, setDetailInvestmentAdminModal] = useState(false)
	const [showDetailInvestmentAdminInfo, setShowDetailInvestmentAdminInfo] = useState({})
	const [deleteInvestmentAdminModal, setDeleteInvestmentAdminModal] = useState(false)
	const [showDeleteInvestmentInfo, setShowDeleteInvestmentInfo] = useState({})
	const [uploadContractFileAdminModal, setUploadContractFileAdminModal] = useState(false)
	const [showUploadContractFileInfo, setShowUploadContractFileInfo] = useState({})
	const [deleteInvestmentDateAdminModal, setDeleteInvestmentDateAdminModal] = useState(false)
	const [showDeleteInvestmentDateInfo, setShowDeleteInvestmentDateInfo] = useState({})
	const [incomeUpdateAdminModal, setIncomeUpdateAdminModal] = useState(false)
	const [showIncomeUpdateInfo, setShowIncomeUpdateInfo] = useState({})
	const [signedAdminModal, setSignedAdminModal] = useState(false)
	const [showSignedInfo, setShowSignedInfo] = useState({})
	const [investmentAdmin, setInvestmentAdmin] = useState([])
	const [idInvestment, setIdInvestment] = useState('')
	const [showModal, setShowModal] = useState(false)
	const [openDownloadContractModal, setOpenDownloadContractModal] = useState(false)
	const [openDownloadStatusModal, setOpenDownloadStatusModal] = useState(false)
	const [urlsToDownload, setUrlsToDownload] = useState([])
	const [zipFileName, setZipFileName] = useState('')

	const { getFilteredDataRows, getFilteredDataColumns } = useContext(FilterReactVirtualizedTableContext) //context of table
	const {
		loadingUnfinishedInvestmentsList,
		successUnfinishedInvestmentsList,
		unfinishedInvestmentsListData,
		errorUnfinishedInvestmentsList,
	} = useSelector((state) => state.unfinishedInvestmentsList)
	const {
		loadingContractsInfoToDownload,
		successContractsInfoToDownload,
		contractsInfoToDownload,
		errorContractsInfoToDownload,
	} = useSelector(({ contractsInfoToDownload }) => contractsInfoToDownload)

	const { userInfo } = useSelector((state) => state.userLogin)
	const { successInvestmentDeclineBySuperAdmin } = useSelector((state) => state.investmentDeclineBySuperAdmin)
	const { successIncomeUpdate, incomeUpdateData, errorIncomeUpdate } = useSelector((state) => state.incomeUpdate)
	const { successInvestmenUploadContract, investmenUploadContractData } = useSelector(
		(state) => state.investmenUploadContract,
	)
	const { successInvestmenSignContract, investmenSignContractData } = useSelector(
		(state) => state.investmenSignContract,
	)
	const { successIncomeDeleteDate, incomeDeleteDateData } = useSelector((state) => state.incomeDeleteDate)
	const { successAdded } = useSelector((state) => state[`investNotes`])

	const handleDeployNotesModal = (user) => {
		setShowModal(user)
	}
	useEffect(() => {
		dispatch(getUnfinishedInvestmentsList())
		return () => dispatch({ type: INVESTMENT_LIST_RESET })
	}, [])
	useEffect(() => {
		if (successAdded) {
			setData(
				data.map((item) => {
					if (item.id === showModal?._id) {
						item.hasComments = 'Tiene'
					}
					return item
				}),
			)
			return () => dispatch({ type: PROFILE_GET_NOTES_RESET })
		}
	}, [successAdded])
	useEffect(() => {
		if (successUnfinishedInvestmentsList) {
			setInvestmentAdmin(unfinishedInvestmentsListData)
		}
	}, [successUnfinishedInvestmentsList])
	useEffect(() => {
		if (investmentAdmin) {
			const data = investmentsListMapper({
				investments: investmentAdmin,
				setShowDetailInvestmentAdminInfo,
				setDetailInvestmentAdminModal,
				setShowDeleteInvestmentInfo,
				setDeleteInvestmentAdminModal,
				setIdInvestment,
				handleDeployNotesModal,
				classes,
			})
			setData(data)
		}
	}, [investmentAdmin, setData, setIdInvestment, classes])
	useEffect(() => {
		if (successInvestmentDeclineBySuperAdmin) {
			let investment = investmentAdmin.filter((data) => data._id !== idInvestment)
			setInvestmentAdmin(investment)
		}
		if (successIncomeUpdate || successIncomeDeleteDate) {
			let incomeData = successIncomeUpdate ? incomeUpdateData : incomeDeleteDateData
			const investment = investmentAdmin.map((data) => {
				if (data._id === incomeData._id) {
					data.incomeRecivedDate = incomeData.incomeRecivedDate
				}
				return data
			})
			setInvestmentAdmin(investment)
		}
		if (successInvestmenUploadContract) {
			const investment = investmentAdmin.map((data) => {
				if (data._id === investmenUploadContractData._id) {
					data.contractUrl = investmenUploadContractData.contractUrl
				}
				return data
			})
			setInvestmentAdmin(investment)
		}
		if (successInvestmenSignContract) {
			const investment = investmentAdmin.map((data) => {
				if (data._id === investmenSignContractData._id) {
					data.signed = true
				}
				return data
			})
			setInvestmentAdmin(investment)
		}
	}, [
		setInvestmentAdmin,
		successInvestmentDeclineBySuperAdmin,
		successIncomeUpdate,
		successInvestmenUploadContract,
		successInvestmenSignContract,
		successIncomeDeleteDate,
	])
	useEffect(() => {
		if (successIncomeUpdate) {
			// eslint-disable-next-line no-use-before-define
			handleIncomeLog(null, incomeUpdateData)
		}
		if (errorIncomeUpdate) {
			const investment = investmentAdmin.filter((item) => item._id === idInvestment)[0]
			// eslint-disable-next-line no-use-before-define
			handleIncomeLog(errorIncomeUpdate, investment)
		}
	}, [successIncomeUpdate, errorIncomeUpdate])

	const handleCloseDeclineAdminModal = () => {
		closeModal(setShowDetailInvestmentAdminInfo, setDetailInvestmentAdminModal)
	}
	const handleCloseInvestmentAdminModal = () => {
		closeModal(setShowDeleteInvestmentInfo, setDeleteInvestmentAdminModal)
	}
	const showDeleteInvestmentDateHandler = (investment) => {
		openModal(investment, setShowDeleteInvestmentDateInfo, setDeleteInvestmentDateAdminModal)
	}
	const handleCloseInvestmentDateAdminModal = () => {
		closeModal(setShowDeleteInvestmentDateInfo, setDeleteInvestmentDateAdminModal)
	}
	const showIncomeUpdateHandler = (item) => {
		openModal(item, setShowIncomeUpdateInfo, setIncomeUpdateAdminModal)
	}
	const handleCloseIncomeUpdateModal = () => {
		closeModal(setShowIncomeUpdateInfo, setIncomeUpdateAdminModal)
	}
	const handleCloseSignedModal = () => {
		closeModal(setShowSignedInfo, setSignedAdminModal)
	}
	const handleCloseUploadContractModal = () => {
		closeModal(setShowUploadContractFileInfo, setUploadContractFileAdminModal)
	}
	const handleIncomeLog = (error, data) => {
		const actionType = !error ? 'INCOME_REGISTER_SUCCESS' : 'INCOME_REGISTER_FAIL'
		const type = error ? 'error' : 'action'
		const logInfo = {
			type: {
				type,
			},
			investors: {
				id: data.investorProfile._id,
				businessName: data.investorProfile.businessName,
			},
			user: {
				id: userInfo._id,
				name: userInfo.name,
				role: userInfo.role,
				email: userInfo.email,
			},
			project: {
				id: data.project._id,
				projectAddress: data.project.projectAddress,
			},
		}
		if (!error) {
			logInfo.investment = {
				id: data._id,
				amount: data.amount,
			}
			logInfo.type.actionType = actionType
		} else {
			logInfo.type.error = {
				errorType: actionType,
				errorMessage: errorIncomeUpdate,
			}
		}
		dispatch(registerLog(logInfo))
	}
	const handleDownload = ({ urls, zipFileName, ids }) => {
		const logInfo = {
			type: {
				type: 'download',
			},
			user: {
				id: userInfo._id,
				name: userInfo.name,
				role: userInfo.role,
				email: userInfo.email,
			},
			dataDownloadInfo: {
				model: 'investments',
				field: 'contractUrl',
				docIds: ids,
			},
		}
		setOpenDownloadStatusModal(true)
		setOpenDownloadContractModal(false)
		setUrlsToDownload(urls)
		setZipFileName(zipFileName)
		dispatch(registerLog(logInfo))
	}
	const handleCloseStatus = () => {
		setOpenDownloadStatusModal(false)
		dispatch({ type: LOG_REGISTER_RESET })
	}

	return (
		<>
			<GridContainer>
				<GridItem xs={12} className={classes.rootItem}>
					<Card>
						<CardHeader color='primary' icon>
							<CardIcon color='primary'>
								<Assignment />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>Inversiones</h4>
							<GridItem xs={12}>
								<GridContainer>
									<GridItem xs={12} className={classes.downloadButton}>
										{(userInfo?.isSuper || userInfo.isInvestorRelations) && (
											<Button onClick={() => setOpenDownloadContractModal(true)}>Descargar contratos</Button>
										)}
									</GridItem>
									<GridItem xs={12}>
										{Boolean(getFilteredDataRows({ tableId })?.length) && (
											<ExcelDefaultExport
												excelName='Lista de inversiones'
												tableColumns={getFilteredDataColumns({ tableId })}
												data={excelMapper({ filteredRows: getFilteredDataRows({ tableId }) })}
											/>
										)}
									</GridItem>
								</GridContainer>
							</GridItem>
						</CardHeader>
						<CardBody>
							<ReactVirtualizedTable
								tableId={tableId}
								columns={INVESTMENTADMINLIST_COLUMNS({
									showDeleteInvestmentDateHandler,
									showIncomeUpdateHandler,
									setIdInvestment,
									setShowUploadContractFileInfo,
									setUploadContractFileAdminModal,
									setShowSignedInfo,
									setSignedAdminModal,
									userInfo,
								})}
								data={data}
								loading={Boolean(loadingUnfinishedInvestmentsList)}
								success={Boolean(successUnfinishedInvestmentsList)}
								error={errorUnfinishedInvestmentsList}
								emptyText={'No se encontraron inversiones.'}
							/>
						</CardBody>
					</Card>
				</GridItem>
				{data.length > 0 && (
					<GridItem xs={12}>
						<InvestmentsListResume activeInvestments tableId={tableId} />
					</GridItem>
				)}
			</GridContainer>
			{showModal && (
				<NotesModal
					type='invest'
					open={showModal}
					handleCloseModal={() => setShowModal(false)}
					modalTitle={'Comentarios'}
				/>
			)}
			{detailInvestmentAdminModal && (
				<DetailInvestmentAdminModal
					handleCloseDeclineAdminModal={handleCloseDeclineAdminModal}
					detailInvestmentAdminModal={detailInvestmentAdminModal}
					showDetailInvestmentAdminInfo={showDetailInvestmentAdminInfo}
				/>
			)}
			{deleteInvestmentAdminModal && (
				<InvestmentAdminDeleteModal
					handleCloseInvestmentAdminModal={handleCloseInvestmentAdminModal}
					deleteInvestmentAdminModal={deleteInvestmentAdminModal}
					showDeleteInvestmentInfo={showDeleteInvestmentInfo}
				/>
			)}
			{incomeUpdateAdminModal && (
				<SubComponentsPikers
					handleCloseIncomeUpdateModal={handleCloseIncomeUpdateModal}
					incomeUpdateModal={incomeUpdateAdminModal}
					showIncomeUpdateInfo={showIncomeUpdateInfo}
				/>
			)}
			{deleteInvestmentDateAdminModal && (
				<DeleteIncomeDateModal
					deleteInvestmentDateAdminModal={deleteInvestmentDateAdminModal}
					handleCloseInvestmentDateAdminModal={handleCloseInvestmentDateAdminModal}
					showDeleteInvestmentDateInfo={showDeleteInvestmentDateInfo}
				/>
			)}
			{uploadContractFileAdminModal && (
				<UploadContractModal
					handleCloseUploadContractModal={handleCloseUploadContractModal}
					showUploadContractInfo={showUploadContractFileInfo}
					uploadContractModal={uploadContractFileAdminModal}
				/>
			)}
			{signedAdminModal && (
				<SignedModal
					handleCloseSignedModal={handleCloseSignedModal}
					signedModal={signedAdminModal}
					showSignedInfo={showSignedInfo}
				/>
			)}
			{openDownloadContractModal && (
				<DownloadInvestmentContractsModal
					openModal={openDownloadContractModal}
					closeModal={() => setOpenDownloadContractModal(false)}
					handleDownload={handleDownload}
					typeScreen={null}
					loadingContractsInfoToDownload={loadingContractsInfoToDownload}
					successContractsInfoToDownload={successContractsInfoToDownload}
					contractsInfoToDownload={contractsInfoToDownload}
					errorContractsInfoToDownload={errorContractsInfoToDownload}
					getContractsInfoToDownload={getContractsInfoToDownload}
					GET_CONTRACTS_INFO_TO_DOWNLOAD_RESET={GET_CONTRACTS_INFO_TO_DOWNLOAD_RESET}
				/>
			)}
			{openDownloadStatusModal && (
				<DownloadStatusModal
					openModal={openDownloadStatusModal}
					closeModal={handleCloseStatus}
					urls={urlsToDownload}
					setUrlsToDownload={setUrlsToDownload}
					zipFileName={zipFileName}
				/>
			)}
		</>
	)
}

export default InvestmentAdminListScreen
