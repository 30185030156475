import { useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardAvatar from 'components/Card/CardAvatar'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import Button from 'components/CustomButtons/Button'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'

const PersonTypeComponent = ({ state, setState }) => {
	const { userInfo } = useSelector((state) => state.userLogin)
	return (
		<GridContainer>
			<GridItem xs={12}>
				<Typography variant='h6' style={{ textAlign: 'center', textTransform: 'none' }} gutterBottom>
					Seleccione un tipo de perfil
				</Typography>
			</GridItem>
			<GridItem xs={12} sm={10} style={{ margin: '50px auto' }}>
				<GridContainer>
					<GridItem xs={12} sm={6}>
						<Card profile>
							<CardAvatar profile round={true}>
								{/* <img src={`${axios.defaults.baseURL}/public/assets/img/avatars/avatar.jpg`} alt='...' /> */}
								<img src={getUrlToOpenFile('public/assets/img/avatars/avatar.jpg', userInfo)} alt='...' />
							</CardAvatar>
							<CardBody profile>
								<Button
									color={state.personType === 'physical' ? 'success' : 'primary'}
									block
									onClick={() => {
										setState({ ...state, personType: 'physical' })
									}}
								>
									Persona Física
								</Button>
							</CardBody>
						</Card>
					</GridItem>
					<GridItem xs={12} sm={6}>
						<Card profile>
							<CardAvatar profile>
								{/* <img src={`${axios.defaults.baseURL}/public/assets/img/logo/legal.jpg`} alt='...' /> */}
								<img src={getUrlToOpenFile('public/assets/img/logo/legal.jpg', userInfo)} alt='...' />
							</CardAvatar>
							<CardBody profile>
								<Button
									color={state.personType === 'legal' ? 'success' : 'primary'}
									block
									onClick={() => {
										setState({ ...state, personType: 'legal' })
									}}
								>
									Persona Jurídica
								</Button>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</GridItem>
		</GridContainer>
	)
}
export default PersonTypeComponent
