import { createRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles, Badge } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { uploadContract } from 'local_redux/actions/investmentActions'
import { INVESTMENT_UPLOAD_CONTRACT_RESET } from 'local_redux/constants/investmentConstants'
import styles from '../styles/uploadContractModalStyles'

const useStyles = makeStyles(styles)

const UploadContractModal = ({ handleCloseUploadContractModal, uploadContractModal, showUploadContractInfo }) => {
	const dispatch = useDispatch()
	const classes = useStyles()
	const contractFile = createRef()

	const [modalState, setModalState] = useState(showUploadContractInfo)

	const { successInvestmenUploadContract, loadingInvestmenUploadContract, errorInvestmenUploadContract } = useSelector(
		(state) => state.investmenUploadContract,
	)

	useEffect(() => {
		let timeOut = ''
		if (successInvestmenUploadContract) {
			timeOut = setTimeout(() => {
				handleCloseUploadContractModal()
			}, 1500)
		}
		return () => clearTimeout(timeOut)
	}, [successInvestmenUploadContract])
	useEffect(() => {
		return () => dispatch({ type: INVESTMENT_UPLOAD_CONTRACT_RESET })
	}, [dispatch])

	const selectFileHandler = () => {
		contractFile.current.click()
	}
	const handleFileChange = (e) => {
		e.preventDefault()
		setModalState({ ...modalState, contractFile: e.target.files[0] })
	}
	const handleFileRemove = () => {
		setModalState({ ...modalState, contractFile: '' })
	}
	const handleUploadContract = (e) => {
		e.preventDefault()
		dispatch(uploadContract(modalState))
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={uploadContractModal}
			keepMounted
			onClose={handleCloseUploadContractModal}
			aria-labelledby='user-modal-delete-title'
			aria-describedby='user-modal-delete-description'
		>
			<DialogTitle id='user-modal-delete-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleCloseUploadContractModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>Agregar contrato</h4>
			</DialogTitle>
			<DialogContent id='user-modal-delete-description' className={classes.modalBody}>
				<form onSubmit={handleUploadContract} id='upload-contract-form'>
					<GridContainer>
						<GridItem xs={12}>
							<Typography>
								Para agregar un contrato a la inversión del perfil{' '}
								<strong>{showUploadContractInfo.investorProfile.businessName}</strong> en el proyecto{' '}
								<strong>{showUploadContractInfo?.project.projectAddress}</strong> seleccione el contrato deseado y luego
								presione subir.
							</Typography>
						</GridItem>
						{!modalState?.contractFile && (
							<GridItem xs={12} className={classes.addPhotoRoot}>
								<div className='fileinput'>
									<input
										type='file'
										name='contractFile'
										accept='.pdf,'
										id='contractFile'
										ref={contractFile}
										onChange={handleFileChange}
									/>
								</div>
								<Button color='primary' size='lg' justIcon round onClick={() => selectFileHandler()}>
									<PictureAsPdfIcon />
								</Button>
							</GridItem>
						)}
						{modalState?.contractFile && (
							<GridItem xs={12} style={{ marginTop: '2rem' }}>
								<Badge
									badgeContent={
										<Button size='sm' color='danger' round justIcon onClick={() => handleFileRemove()}>
											<Close />
										</Button>
									}
									className={classes.fileBadge}
								>
									<div className={classes.fileDiv}>
										{modalState.contractFile.name.length > 20
											? modalState.contractFile.name.slice(1, 10) +
											  '...' +
											  modalState.contractFile.name.slice(
													modalState.contractFile.name.length - 6,
													modalState.contractFile.name.length,
											  )
											: modalState.contractFile.name}
									</div>
								</Badge>
							</GridItem>
						)}
					</GridContainer>
					{errorInvestmenUploadContract && (
						<GridContainer>
							<GridItem xs={12}>
								<SnackbarContent message={errorInvestmenUploadContract} color='danger' />
							</GridItem>
						</GridContainer>
					)}
				</form>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={6}>
						<Button onClick={handleCloseUploadContractModal} block>
							Cancelar
						</Button>
					</GridItem>
					<GridItem xs={6}>
						<Button
							type='submit'
							color={successInvestmenUploadContract ? 'success' : 'primary'}
							block
							form='upload-contract-form'
						>
							{loadingInvestmenUploadContract
								? 'Subiendo'
								: successInvestmenUploadContract
								? 'Subido corectamente'
								: 'Subir'}
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default UploadContractModal
