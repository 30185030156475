import { Tooltip } from '@material-ui/core'
import { UploadFile, BorderColor, Delete, Visibility } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import { openModal } from 'shared/helpers/modal-helper'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'

const INVESTMENTADMINLIST_COLUMNS = ({
	showDeleteInvestmentDateHandler,
	showIncomeUpdateHandler,
	setIdInvestment,
	setShowUploadContractFileInfo,
	setUploadContractFileAdminModal,
	setShowSignedInfo,
	setSignedAdminModal,
	userInfo,
}) => {
	const isAuthorizedUser = userInfo.isSuper || userInfo.isContabilidad || userInfo.isAdmin
	return [
		{
			header: 'Perfil Inversor',
			accessor: 'businessName',
			size: 'sm',
			cell: ({ row, cell }) => {
				return (
					<div
						onClick={() => window.open(`/admin/investor-list-profiles?profileId=${row?.investorProfileId}`)}
						style={{ cursor: 'pointer', color: '#4646c3' }}
					>
						{cell}
					</div>
				)
			},
		},
		{
			header: 'Email',
			accessor: 'email',
			hidden: true,
		},
		{
			header: 'Dirección de Proyecto',
			accessor: 'projectAddress',
			size: 'md',
		},
		{
			header: 'Comentarios añadidos',
			accessor: 'hasComments',
			size: 'sm',
		},
		{
			header: 'Fecha',
			accessor: 'investmentDate',
			type: 'date',
			dateFormat: 'dd/MM/yyyy',
			size: 'sm',
		},
		{
			header: 'Importe',
			accessor: 'amount',
			type: 'number',
			size: 'sm',
		},
		{
			header: 'Ingreso Recibido',
			accessor: 'incomeRecived',
			nullValue: 'Confirmar ingreso',
			type: 'date',
			dateFormat: 'dd/MM/yyyy',
			size: 'md',
			disabledDateInput: true,
			cell: ({ row, cell }) => {
				const isValid = Boolean(row.incomeRecived)
				return (
					<>
						{isValid ? (
							<>
								{cell}{' '}
								{isAuthorizedUser && (
									<Tooltip title='Eliminar Ingreso Recibido' placement='bottom'>
										<Button
											justIcon
											round
											simple
											onClick={() =>
												showDeleteInvestmentDateHandler({
													...row,
													_id: row?.id,
												})
											}
											color='danger'
											className='delete-date'
										>
											<Delete />
										</Button>
									</Tooltip>
								)}
							</>
						) : isAuthorizedUser ? (
							<p
								style={{ cursor: 'pointer', color: '#4646c3' }}
								onClick={() => [showIncomeUpdateHandler({ ...row, _id: row.id }), setIdInvestment(row.id)]}
							>
								{cell}
							</p>
						) : (
							<p style={{ color: '#4646c3' }}>{'pendiente de confirmar'}</p>
						)}
					</>
				)
			},
		},
		{
			header: 'Estado de Contrato',
			accessor: 'singedContract',
			size: 'md',
			cell: ({ row, cell }) => (
				<div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', padding: '0 10px' }}>
					{cell}
					{cell === 'Firmado' ? (
						<Tooltip id='tooltip-top' title='Ver contrato' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => window.open(getUrlToOpenFile(row.contractUrl, userInfo))}
								color='success'
								className='view'
							>
								<Visibility />
							</Button>
						</Tooltip>
					) : cell === 'Pendiente de Firma' ? (
						<Tooltip id='tooltip-top' title='Subir contrato' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => openModal(row.item, setShowUploadContractFileInfo, setUploadContractFileAdminModal)}
								color='success'
								className='view'
							>
								<UploadFile />
							</Button>
						</Tooltip>
					) : (
						userInfo?.isSupport && (
							<Tooltip id='tooltip-top' title='Agregar ID del Contrato' placement='bottom'>
								<Button
									justIcon
									round
									simple
									onClick={() => openModal(row.item, setShowSignedInfo, setSignedAdminModal)}
									color='success'
									className='view'
								>
									<BorderColor />
								</Button>
							</Tooltip>
						)
					)}
				</div>
			),
		},
		,
		{
			header: 'A fin de Proyecto',
			accessor: 'liquidation',
			size: 'sm',
		},
		{
			header: 'Estado',
			accessor: 'status',
			size: 'sm',
		},
		{
			header: 'Gestionado por',
			accessor: 'managedBy',
			size: 'md',
		},
		{
			header: 'Acciones',
			accessor: 'actions',
			size: 'sm',
			width: '100px',
			cellAlign: 'right',
			headerAlign: 'right',
		},
	]
}

export default INVESTMENTADMINLIST_COLUMNS
