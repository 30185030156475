import { useSelector } from 'react-redux'
import { makeStyles, Typography } from '@material-ui/core'
import Card from 'components/Card/Card'
import CardAvatar from 'components/Card/CardAvatar'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { matchPersonType } from 'shared/matchData/matchData'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import styles from '../styles/personTypeReviewStyles'

const useStyles = makeStyles(styles)

const PersonTypeReview = ({ state }) => {
	const classes = useStyles()
	const isLegal = state.personType === 'legal'
	const defaultAvatar = isLegal ? `public/assets/img/logo/legal.jpg` : `public/assets/img/avatars/avatar.jpg`

	const { userInfo } = useSelector((state) => state.userLogin)

	return (
		<>
			<GridContainer style={{ marginBottom: '20px' }}>
				<GridItem xs={12} style={{ margin: 'auto' }}>
					<Typography variant='subtitle2' align='center' style={{ textTransform: 'none' }} gutterBottom>
						Revise que toda la información esté correcta
					</Typography>
				</GridItem>
			</GridContainer>
			<GridContainer>
				<GridItem xs={12} sm={10} md={8} style={{ margin: 'auto' }}>
					<Card className={classes.card}>
						<GridContainer className={classes.gridBody}>
							<GridItem xs={12} className={classes.header}>
								<CardAvatar round={isLegal ? false : true}>
									<img
										className={isLegal ? classes.image : classes.imageRound}
										// src={`${axios.defaults.baseURL}/${defaultAvatar}`}
										src={getUrlToOpenFile(defaultAvatar, userInfo)}
										alt={isLegal ? state.businessName : `${state.name} ${state.lastName}`}
									/>
								</CardAvatar>
								<GridItem>
									<Typography variant='h6'>
										{isLegal ? state.businessName : `${state.name} ${state.lastName}`}
									</Typography>
									<Typography variant='subtitle1' gutterBottom>
										{matchPersonType(state.personType).toUpperCase()}
									</Typography>
								</GridItem>
							</GridItem>
						</GridContainer>
						<GridContainer className={classes.gridBody}>
							{isLegal && (
								<>
									<GridItem xs={6}>
										<Typography variant='caption'>CIF:</Typography>
										<Typography variant='body2' className={classes.text2} gutterBottom>
											{state.cif}
										</Typography>
									</GridItem>
									<GridItem xs={6}>
										<Typography variant='caption'>Nombre Representante:</Typography>
										<Typography variant='body2' className={classes.text2} gutterBottom>
											{state.name}
										</Typography>
									</GridItem>
									<GridItem xs={6}>
										<Typography variant='caption'>Apellidos Representante:</Typography>
										<Typography variant='body2' className={classes.text2} gutterBottom>
											{state.lastName}
										</Typography>
									</GridItem>
								</>
							)}
							<GridItem xs={6} sm={6}>
								<Typography variant='caption'>{!isLegal ? 'DNI/NIE:' : 'DNI/NIE Representante:'}</Typography>
								<Typography variant='body2' className={classes.text2} gutterBottom>
									{state.identification}
								</Typography>
							</GridItem>
							<GridItem xs={12} sm={6}>
								<Typography variant='caption'>{!isLegal ? 'Móvil:' : 'Teléfono:'}</Typography>
								<Typography variant='body2' className={classes.text2} gutterBottom>
									{state.phone}
								</Typography>
							</GridItem>
							<GridItem xs={12} sm={6}>
								<Typography variant='caption'>Email:</Typography>
								<Typography variant='body2' className={classes.text2} gutterBottom>
									{state.email}
								</Typography>
							</GridItem>
							<GridItem xs={12} sm={6}>
								<Typography variant='caption'>{!isLegal ? 'Dirección:' : 'Dirección Representante:'}</Typography>
								<Typography variant='body2' className={classes.text2} gutterBottom>
									{state.address}
								</Typography>
							</GridItem>
							{state.province && (
								<GridItem xs={12} sm={6}>
									<Typography variant='caption'>Provincia</Typography>
									<Typography variant='body2' className={classes.text2} gutterBottom>
										{state.province}
									</Typography>
								</GridItem>
							)}
							{state.location && (
								<GridItem xs={12} sm={6}>
									<Typography variant='caption'>Localidad</Typography>
									<Typography variant='body2' className={classes.text2} gutterBottom>
										{state.location}
									</Typography>
								</GridItem>
							)}
							{state.postalcode && (
								<GridItem xs={12} sm={6}>
									<Typography variant='caption'>Código postal</Typography>
									<Typography variant='body2' className={classes.text2} gutterBottom>
										{state.postalcode}
									</Typography>
								</GridItem>
							)}
							{state.bankAccountNumber && (
								<GridItem xs={12} sm={6}>
									<Typography variant='caption'>IBAN:</Typography>
									<Typography variant='body2' className={classes.text2} gutterBottom>
										{state.bankAccountNumber}
									</Typography>
								</GridItem>
							)}
							{!isLegal && (
								<GridItem xs={12} sm={6}>
									<Typography variant='caption'>Género:</Typography>
									<Typography variant='body2' className={classes.text2} gutterBottom>
										{state.gender}
									</Typography>
								</GridItem>
							)}
						</GridContainer>
					</Card>
				</GridItem>
			</GridContainer>
		</>
	)
}

export default PersonTypeReview
