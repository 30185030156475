import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import reasonsListColumns from '../helpers/reasons-list-columns'
import reasonsListMapper from '../mappers/reasons-list-mapper'
import { getReasonsFromProjectsByProjectId } from 'local_redux/actions/projectAction'
import { REASONS_FROM_PROJECTS_BY_PROJECT_ID_LIST_RESET } from 'local_redux/constants/projectConstant'
import styles from '../styles/updateFinishedDateModalStyles'

const useStyles = makeStyles(styles)

const ReasonsFinishedDateUpdateModal = ({
	handleCloseReasonsFinishedDateUpdateModal,
	reasonFinishedDateUpdateModal,
	reasonFinishedDateUpdateInfo,
}) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [data, setData] = useState(null)

	const {
		reasonsListByProjectIdListData,
		successReasonsListByProjectIdList,
		loadingReasonsListByProjectIdList,
		errorReasonsListByProjectIdList,
	} = useSelector(({ reasonsFromProjectByProjectIdList }) => reasonsFromProjectByProjectIdList)

	useEffect(() => {
		dispatch(getReasonsFromProjectsByProjectId(reasonFinishedDateUpdateInfo.id))
		return () => {
			dispatch({ type: REASONS_FROM_PROJECTS_BY_PROJECT_ID_LIST_RESET })
		}
	}, [])
	useEffect(() => {
		if (successReasonsListByProjectIdList) {
			setData(reasonsListMapper(reasonsListByProjectIdListData))
		}
	}, [successReasonsListByProjectIdList])

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={reasonFinishedDateUpdateModal}
			keepMounted
			onClose={handleCloseReasonsFinishedDateUpdateModal}
			aria-labelledby='view-update-finished-date-title'
			aria-describedby='view-update-finished-date-description'
		>
			<DialogTitle id='view-update-finished-date-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleCloseReasonsFinishedDateUpdateModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>Lista de retrasos en {reasonFinishedDateUpdateInfo.projectAddress}</h4>
			</DialogTitle>
			<DialogContent id='view-update-finished-date-description' className={classes.modalBody}>
				<GridContainer>
					<GridItem xs={12}>
						<ReactVirtualizedTable
							data={data}
							columns={reasonsListColumns()}
							loading={Boolean(loadingReasonsListByProjectIdList)}
							success={Boolean(successReasonsListByProjectIdList)}
							error={errorReasonsListByProjectIdList}
						/>
					</GridItem>
				</GridContainer>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={12}>
						<Button onClick={handleCloseReasonsFinishedDateUpdateModal} block>
							Cerrar
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default ReasonsFinishedDateUpdateModal
