import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { CardActions } from '@mui/material'
import { DownloadForOffline, CloudDownload, FilterAlt } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Loader from 'components/Loader/Loader'
import ReactTable from 'components/ReactTable/ReactTable'
import DownloadDirModal from './components/DownloadDirModal'
import { getBackups } from 'local_redux/actions/backupActions'
import styles from './styles/backupScreenStyles'

const useStyles = makeStyles(styles)

const BackupScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [data, setData] = useState([])
	const [infoDownloadDirModal, setInfoDownloadDirModal] = useState([])
	const [downloaDirModal, setDownloadDirModal] = useState(false)

	const { loadingBackupList, backupListData } = useSelector((state) => state.backupList)

	useEffect(() => {
		dispatch(getBackups())
	}, [])

	const matchBackupData = (item, filter) => {
		let projectDirName = (filter === 'fileFolders' ? 'Archivos' : 'Imagenes').concat(' de proyectos')
		switch (item) {
			case 'contracts':
				return 'Contratos Firmados'
			case 'finished-projects-csv':
				return 'Csv proyectos finalizados'
			case 'projects':
				return projectDirName
			case 'tax-residences':
				return 'Recidencias fiscales'
			default:
				return item
		}
	}
	const handleOpenDownloadModal = (matchValue, filter, value) => {
		setDownloadDirModal(true)
		setInfoDownloadDirModal({ matchValue, filter, value })
	}
	const handleData = (filter) => {
		let filteredData = backupListData[filter].map((item) => {
			return {
				id: item,
				backupDir: matchBackupData(item, filter),
				actions: (
					<div className='actions-right'>
						<Button
							justIcon
							round
							simple
							color='success'
							className='edit'
							onClick={() => handleOpenDownloadModal(matchBackupData(item, filter), filter, item)}
						>
							<DownloadForOffline />
						</Button>
					</div>
				),
			}
		})
		setData(filteredData)
	}
	const handleCloseDownloadModal = () => {
		setDownloadDirModal(false)
		setInfoDownloadDirModal([])
	}

	return (
		<>
			<GridContainer>
				<GridItem xs={12} md={6} className={classes.rootItem}>
					<Card>
						<CardHeader color='primary' icon>
							<CardIcon color='primary'>
								<FilterAlt />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>Directorio</h4>
						</CardHeader>
						<CardActions>
							<GridContainer>
								<GridItem xs={4}>
									<Button block onClick={() => handleData('dbFolders')}>
										Base de datos
									</Button>
								</GridItem>
								<GridItem xs={4}>
									<Button block onClick={() => handleData('fileFolders')}>
										Archivos
									</Button>
								</GridItem>
								<GridItem xs={4}>
									<Button block onClick={() => handleData('imgFolders')}>
										Imagenes
									</Button>
								</GridItem>
							</GridContainer>
						</CardActions>
					</Card>
				</GridItem>
				<GridItem xs={12} className={classes.rootItem}>
					<Card>
						<CardHeader color='primary' icon>
							<CardIcon color='primary'>
								<CloudDownload />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>Backups</h4>
						</CardHeader>
						<CardBody>
							{loadingBackupList ? (
								<GridItem xs={12} className={classes.loader}>
									<Loader />
								</GridItem>
							) : (
								<ReactTable
									columns={[
										{
											Header: 'Directorio',
											accessor: 'backupDir',
											size: 'small',
										},
										{
											Header: 'Aciones',
											accessor: 'actions',
											size: 'small',
										},
									]}
									data={data}
								/>
							)}
						</CardBody>
					</Card>
				</GridItem>
				{downloaDirModal && (
					<DownloadDirModal
						downloadModal={downloaDirModal}
						handleCloseDownloadModal={handleCloseDownloadModal}
						showDirInfo={infoDownloadDirModal}
					/>
				)}
			</GridContainer>
		</>
	)
}

export default BackupScreen
