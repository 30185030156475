import { NumericFormat } from 'react-number-format'
import classnames from 'classnames'
import { makeStyles } from '@material-ui/core'
import { matchNumberToCurrency } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import styles from '../styles/reinvestmentModalStyles'
import { useState } from 'react'

const useStyles = makeStyles(styles)

const ReInvestPDLTable = ({ data, projectData, handleChange }) => {
	const classes = useStyles(styles)
	const [rowValues, setRowValues] = useState({ typeOfInterest: 12, reinvestmentAmount: 0 })

	return (
		<div className='ReactTable -striped -highlight'>
			<table className={'rt-table '} style={{ display: 'table', width: '100%' }}>
				<thead className='rt-thead -header'>
					<tr className='rt-tr'>
						{['Proyectos', 'Importe Invertible', '¿Cuánto reinvierte?', 'Porcentaje de Interés'].map((item, i) => (
							<th key={i} className={classnames('rt-th rt-resizable-header')}>
								{item}
							</th>
						))}
					</tr>
				</thead>
				<tbody className='rt-thead -header'>
					{data.map((item, i) => {
						return (
							<tr
								key={i}
								className={
									classnames('rt-tr', { ' -odd': i % 2 === 0 }, { ' -even': i % 2 === 1 }) +
									` ${classes.hoverCell} ${classes.trWidthBig}`
								}
							>
								<td className={classnames('rt-td', classes.widthBig)}>{item.projectAddress}</td>
								<td className={classnames('rt-td', classes.widthBig)}>
									{matchNumberToCurrency(item.amountInvertible)}
								</td>
								<td className={classnames('rt-td', classes.widthBig)}>
									{
										<NumericFormat
											className={classes.inputFormat}
											decimalSeparator=','
											thousandsGroupStyle='mil'
											thousandSeparator='.'
											decimalScale={2}
											allowedDecimalSeparators=','
											fixedDecimalScale={true}
											valueIsNumericString={true}
											value={projectData?.find((project) => project?._id === item._id)?.reinvestmentAmount}
											onValueChange={(value) => {
												setRowValues({ ...rowValues, reinvestmentAmount: value.value })
												handleChange(
													{ reinvestmentAmount: value.value, typeOfInterest: rowValues.typeOfInterest },
													item,
												)
											}}
											allowNegative={false}
											suffix={' €'}
											placeholder='0,00 €'
										/>
									}
								</td>
								<td className={classnames('rt-td', classes.widthBig)}>
									{
										<NumericFormat
											className={classes.inputFormat}
											decimalSeparator=','
											thousandsGroupStyle='mil'
											thousandSeparator='.'
											decimalScale={2}
											allowedDecimalSeparators=','
											fixedDecimalScale={true}
											valueIsNumericString={true}
											value={projectData?.find((project) => project?._id === item._id)?.typeOfInterest}
											onValueChange={(value) => {
												setRowValues({ ...rowValues, typeOfInterest: value.value })
												handleChange(
													{ typeOfInterest: value.value, reinvestmentAmount: rowValues.reinvestmentAmount },
													item,
												)
											}}
											allowNegative={false}
											suffix={' %'}
											placeholder='12%'
										/>
									}
								</td>
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}

export default ReInvestPDLTable
