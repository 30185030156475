import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Typography, FormControl, MenuItem, InputLabel, Select as Selectable } from '@material-ui/core'
import { PersonAdd } from '@material-ui/icons'
import Card from 'components/Card/Card'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CardBody from 'components/Card/CardBody'
import { matchPersonType } from 'shared/matchData/matchData'

const PersonTypeForm = ({ state, setState }) => {
	const [isLegal, setIsLegal] = useState(state.personType === 'legal')

	const { userInfo } = useSelector((state) => state.userLogin)

	useEffect(() => {
		if (isLegal) {
			return setState({
				...state,
				name: userInfo.name,
				lastName: userInfo.lastName,
				identification: userInfo.dni,
				email: userInfo.email,
				phone: userInfo.phone,
				address: userInfo.address,
			})
		}
		return () => setIsLegal(false)
	}, [isLegal])

	return (
		<>
			<Typography variant='subtitle2' align='center' style={{ textTransform: 'none' }} gutterBottom>
				Perfil {matchPersonType(state.personType)}
			</Typography>
			<GridItem xs={12}>
				<Card>
					<CardHeader color='primary' icon>
						<CardIcon color='primary'>
							<PersonAdd />
						</CardIcon>
						<h4>Perfil del inversor</h4>
					</CardHeader>
					<CardBody>
						{isLegal && (
							<GridContainer>
								<GridItem xs={12} sm={6}>
									<CustomInput
										labelText='Razón Social *'
										id='investor-businessName'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: state.businessName,
											onChange: (e) => {
												setState({ ...state, businessName: e.target.value })
											},
											type: 'text',
											required: true,
										}}
									/>
								</GridItem>
								<GridItem xs={12} sm={6}>
									<CustomInput
										labelText='CIF *'
										id='investor-cif'
										formControlProps={{
											fullWidth: true,
										}}
										inputProps={{
											value: state.cif,
											onChange: (e) => {
												setState({ ...state, cif: e.target.value })
											},
											type: 'text',
											required: true,
										}}
									/>
								</GridItem>
							</GridContainer>
						)}
						<GridContainer>
							<GridItem xs={12} sm={6}>
								<CustomInput
									labelText={!isLegal ? 'Nombre  *' : 'Nombre Representante  *'}
									id='investor-firstname'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										value: state.name,
										onChange: (e) => {
											setState({ ...state, name: e.target.value })
										},
										type: 'text',
										required: true,
									}}
								/>
							</GridItem>
							<GridItem xs={12} sm={6}>
								<CustomInput
									labelText={!isLegal ? 'Apellidos  *' : 'Apellidos Representante  *'}
									id='investor-lastname'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										value: state.lastName,
										onChange: (e) => {
											setState({ ...state, lastName: e.target.value })
										},
										type: 'text',
										required: true,
									}}
								/>
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={12} sm={6}>
								<CustomInput
									labelText={!isLegal ? 'DNI/NIE  *' : 'DNI/NIE Representante  *'}
									id='investor-identification'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										value: state.identification,
										onChange: (e) => {
											setState({ ...state, identification: e.target.value })
										},
										type: 'text',
										required: true,
									}}
								/>
							</GridItem>
							<GridItem xs={12} sm={6}>
								<CustomInput
									labelText={!isLegal ? 'Móvil  *' : 'Teléfono  *'}
									id='investor-phone'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										value: state.phone,
										onChange: (e) => {
											setState({ ...state, phone: e.target.value })
										},
										type: 'text',
										required: true,
									}}
								/>
							</GridItem>
						</GridContainer>
						<GridContainer>
							<GridItem xs={12} sm={6}>
								<CustomInput
									labelText='Email *'
									id='investor-email'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										value: state.email,
										onChange: (e) => {
											setState({ ...state, email: e.target.value })
										},
										type: 'email',
										required: true,
									}}
								/>
							</GridItem>
							<GridItem xs={12} sm={6}>
								<CustomInput
									labelText='Provincia *'
									id='investor-province'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										value: state.province,
										onChange: (e) => {
											setState({ ...state, province: e.target.value })
										},
										type: 'text',
										required: true,
									}}
								/>
							</GridItem>
							<GridItem xs={12} sm={6}>
								<CustomInput
									labelText='Localidad *'
									id='investor-location'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										value: state.location,
										onChange: (e) => {
											setState({ ...state, location: e.target.value })
										},
										type: 'text',
										required: true,
									}}
								/>
							</GridItem>
							<GridItem xs={12} sm={6}>
								<CustomInput
									labelText='Código postal *'
									id='investor-postalCode'
									error={
										(state.postalCode && state.postalCode.length < 4) ||
										(state.postalCode && state.postalCode.length > 5)
									}
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										value: state.postalCode,
										onChange: (e) => {
											setState({ ...state, postalCode: e.target.value })
										},
										type: 'number',
										required: true,
									}}
								/>
							</GridItem>
							<GridItem xs={12}>
								<CustomInput
									labelText={!isLegal ? 'Dirección *' : 'Dirección Representante *'}
									id='investor-address'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										value: state.address,
										onChange: (e) => {
											setState({ ...state, address: e.target.value })
										},
										type: 'text',
										required: true,
									}}
								/>
							</GridItem>
							<GridItem xs={12}>
								<CustomInput
									labelText={'IBAN '}
									id='investor-iban'
									helperText='Formato ES9999999999999999999999'
									formControlProps={{
										fullWidth: true,
									}}
									inputProps={{
										value: state.bankAccountNumber,
										onChange: (e) => {
											setState({ ...state, bankAccountNumber: e.target.value })
										},
										type: 'text',
									}}
								/>
							</GridItem>
						</GridContainer>
						<GridContainer>
							{!isLegal && (
								<GridItem xs={12} sm={3}>
									<FormControl fullWidth>
										<InputLabel htmlFor='investor-profile-update-type'>Género</InputLabel>
										<Selectable
											value={state.gender}
											onChange={(e) => setState({ ...state, gender: e.target.value })}
											inputProps={{
												name: 'investor-profile-update-gender',
												id: 'investor-profile-update-gender',
											}}
										>
											<MenuItem disabled>Selecciona una Opción</MenuItem>
											{['Mujer', 'Hombre'].map((entityType, index) => (
												<MenuItem value={entityType} key={index}>
													{entityType}
												</MenuItem>
											))}
										</Selectable>
									</FormControl>
								</GridItem>
							)}
						</GridContainer>
					</CardBody>
				</Card>
			</GridItem>
		</>
	)
}

export default PersonTypeForm
