import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import { makeStyles, Grid } from '@material-ui/core'
import { PermIdentity } from '@material-ui/icons'
import { CardActions } from '@mui/material'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Clearfix from 'components/Clearfix/Clearfix'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import Loader from 'components/Loader/Loader'
import CardIcon from 'components/Card/CardIcon'
import EmailChangeModal from 'components/CustomModals/EmailChangeModal/EmailChangeModal'
import UserProfileInputs from './UserProfileInputs'
import role from 'config/roles/roles'
import { complexPasswordValidation } from 'shared/validators/inputValidators'
import { matchGender } from 'shared/matchData/matchData'
import { INVESTOR_LIST_RESET } from 'local_redux/constants/investorConstant'
import { USER_UPDATE_RESET, USER_DETAILS_RESET } from 'local_redux/constants/userConstants'
import { INVESTORS_PROFILE_BY_USER_RESET } from 'local_redux/constants/investorProfileConstants'
import { getUserById, userUpdateInfo } from 'local_redux/actions/userActions'
import styles from '../styles/investorUserUpdateFormStyles'

const useStyles = makeStyles(styles)

const InvestorUserUpdateForm = ({ userId }) => {
	const classes = useStyles()
	const dispatch = useDispatch()

	const [userInfo, setUserInfo] = useState('')
	const [currentEmail, setCurrentEmail] = useState('')
	const [isChangeEmailInAllProfiles, setIsChangeEmailInAllProfiles] = useState(null)
	const [errorPasswordValidation, setErrorPasswordValidation] = useState('')
	const [isChangeEmailModal, setIsChangeEmailModal] = useState(false)
	const [alert, setAlert] = useState(null)
	const [errorAddress, setErrorAddress] = useState(false)

	const { loadingUserUpdate, successUserUpdate, errorUserUpdate } = useSelector((state) => state.userUpdate)
	const { loadingUserDetails, successUserDetails, userDetail } = useSelector((state) => state.userDetails)

	useEffect(() => {
		if (successUserDetails) {
			setUserInfo(userDetail)
			setCurrentEmail(userDetail.email)
		} else {
			dispatch(getUserById(userId))
		}
	}, [successUserDetails, userId])
	useEffect(() => {
		let timeOut = ''
		if (isChangeEmailInAllProfiles === null && successUserUpdate) {
			timeOut = setTimeout(() => {
				// eslint-disable-next-line no-use-before-define
				confirmSuccess()
			}, 1000)
		}
		return () => clearTimeout(timeOut)
	}, [dispatch, successUserUpdate, isChangeEmailInAllProfiles])
	useEffect(() => {
		return () => dispatch({ type: USER_DETAILS_RESET })
	}, [dispatch])
	useEffect(() => {
		if (successUserUpdate && isChangeEmailInAllProfiles !== null) {
			if (isChangeEmailInAllProfiles === true) {
				setAlert(
					<SweetAlert
						success
						style={{ display: 'block', marginTop: '-100px' }}
						title='¡Hecho!'
						// eslint-disable-next-line no-use-before-define
						onConfirm={() => confirmSuccess()}
						confirmBtnCssClass={classes.confirmBtnCssClass}
					>
						Cambió el email en el usuario y todos sus perfiles
					</SweetAlert>,
				)
			} else {
				setAlert(
					<SweetAlert
						success
						style={{ display: 'block', marginTop: '-100px' }}
						title='¡Hecho!'
						// eslint-disable-next-line no-use-before-define
						onConfirm={() => confirmSuccess()}
						confirmBtnCssClass={classes.confirmBtnCssClass}
					>
						Cambió el email sólo en el usuario
					</SweetAlert>,
				)
			}
		}
	}, [successUserUpdate, isChangeEmailInAllProfiles])

	const confirmSuccess = () => {
		setErrorPasswordValidation('')
		dispatch({ type: INVESTORS_PROFILE_BY_USER_RESET })
		dispatch({ type: USER_UPDATE_RESET })
		dispatch({ type: USER_DETAILS_RESET })
		dispatch({ type: INVESTOR_LIST_RESET })
		setIsChangeEmailInAllProfiles(null)
		setAlert(null)
	}
	const dataSender = (info) => {
		const userData = {
			...info,
			role: null,
			gender: matchGender(info.gender),
			isChangeEmail: info?.isChangeEmail || null,
		}

		if (userData.address.includes(';')) {
			return setErrorAddress('La dirección no puede tener un ";".')
		}

		dispatch(userUpdateInfo(userData))
	}
	const handleEmailModal = () => {
		setIsChangeEmailModal(!isChangeEmailModal)
	}
	const handleCancelEmailChange = () => {
		setIsChangeEmailInAllProfiles(false)
		dataSender({ ...userInfo, isChangeEmail: false })
		handleEmailModal()
	}
	const handleConfirmEmailChange = () => {
		setIsChangeEmailInAllProfiles(true)
		dataSender({ ...userInfo, isChangeEmail: true })
		handleEmailModal()
	}
	const updateProfileHandler = (e) => {
		e.preventDefault()
		if (userInfo.password && !complexPasswordValidation(userInfo.password)) {
			return setErrorPasswordValidation('La contraseña no cumple las condiciones mínimas')
		}

		if (userInfo.role === role.INVESTOR_ROLE) {
			if (userInfo.email !== currentEmail && !userInfo.isChangeEmail) {
				return setIsChangeEmailModal(true)
			}
		}
		dataSender(userInfo)
	}

	return (
		<>
			<GridContainer>
				<Grid item xs={12} sm={10} md={9} style={{ margin: 'auto' }}>
					<Card>
						<CardHeader color='primary' icon>
							<CardIcon color='primary'>
								<PermIdentity />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>
								Actualizar Perfil -<small>{` ${userInfo.name} ${userInfo.lastName}`}</small>
							</h4>
						</CardHeader>
						<CardBody>
							{loadingUserDetails ? (
								<GridItem className={classes.Loader} xs={12}>
									<Loader />
								</GridItem>
							) : (
								userInfo && (
									<>
										<form>
											<GridContainer>
												{loadingUserDetails ? (
													<>Cargando datos de usuario</>
												) : (
													userInfo && <UserProfileInputs setUserInfo={setUserInfo} userInfo={userInfo} />
												)}

												{errorUserUpdate && (
													<GridItem xs={12} sm={12}>
														<SnackbarContent message={errorUserUpdate} color='danger' />
													</GridItem>
												)}
												{errorAddress && (
													<GridItem xs={12} sm={12}>
														<SnackbarContent message={errorAddress} color='danger' />
													</GridItem>
												)}
												{errorPasswordValidation && !complexPasswordValidation(userInfo.password) && (
													<GridContainer>
														<GridItem xs={12}>
															<SnackbarContent message={errorPasswordValidation} color='danger' />
														</GridItem>
													</GridContainer>
												)}
											</GridContainer>
											<CardActions className={classes.cardAction}>
												<Button
													color={successUserUpdate ? 'success' : 'primary'}
													className={classes.updateProfileButton}
													onClick={updateProfileHandler}
													disabled={loadingUserUpdate}
												>
													{loadingUserUpdate
														? 'Actualizando...'
														: successUserUpdate
														? 'Usuario Actualizado'
														: 'Actualizar Perfil'}
												</Button>
												<Clearfix style={{ color: 'red' }} />
											</CardActions>
										</form>
									</>
								)
							)}
						</CardBody>
					</Card>
				</Grid>
			</GridContainer>
			{isChangeEmailModal && (
				<EmailChangeModal
					open={isChangeEmailModal}
					handleCloseModal={handleEmailModal}
					message={<>¿Desea tambien cambiar el email de contacto en todos sus perfiles por {userInfo.email}?</>}
					modalTitle={'Cambio de Email'}
					handleCancel={handleCancelEmailChange}
					handleConfirm={handleConfirmEmailChange}
				/>
			)}
			{alert}
		</>
	)
}

export default InvestorUserUpdateForm
