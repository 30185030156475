import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Assignment } from '@mui/icons-material'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import ReactVirtualizedTable from 'components/ReactVirtualizedTable/ReactVirtualizedTable'
import InvestorProfileUpdateModal from './components/InvestorProfileUpdateModal'
import { closeModal } from 'shared/helpers/modal-helper'
import UploadProfileDocumentsModal from 'components/UploadProfileDocumentsModal/UploadProfileDocumentsModal'
import { InvestorProfileListColumns } from './helpers/InvestorProfileListColumns'
import { InvestorProfileListMapper } from './mappers/InvestorProfileListMapper'
import { INVESTORS_PROFILE_BY_USER_RESET } from 'local_redux/constants/investorProfileConstants'
import { investorsProfileByUser } from 'local_redux/actions/investorProfileActions'
import styles from './styles/investorProfileListStyles'

const useStyles = makeStyles(styles)

const InvestorProfileListScreen = () => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [data, setData] = useState([])
	const [investorProfileModal, setInvestorProfileModal] = useState(false)
	const [showInvestorUpdateInfo, setShowInvestorUpdateInfo] = useState({})
	const [investorProfiles, setInvestorProfiles] = useState([])
	const [investorProfileUploadDocumentModal, setInvestorProfileUploadDocumentModal] = useState(false)
	const [showInvestorProfileUploadDocumentInfo, setShowInvestorProfileUploadDocumentInfo] = useState({})

	const {
		loadingInvestorsProfileByUser,
		successInvestorsProfileByUser,
		investorsProfileByUserData,
		errorInvestorsProfileByUser,
	} = useSelector((state) => state.investorsProfileByUser)
	const { userInfo } = useSelector((state) => state.userLogin)
	const { successInvestorProfileUpdate, investorProfileUpdateData } = useSelector(
		(state) => state.investorProfileUpdate,
	)

	useEffect(() => {
		dispatch(investorsProfileByUser(userInfo?._id))
	}, [])
	useEffect(() => {
		if (successInvestorsProfileByUser) {
			setInvestorProfiles(investorsProfileByUserData)
		}
	}, [successInvestorsProfileByUser])
	useEffect(() => {
		if (investorProfiles) {
			const investorList = InvestorProfileListMapper(investorProfiles)
			setData(investorList)
		}
	}, [setData, investorProfiles])
	useEffect(() => {
		if (successInvestorProfileUpdate) {
			const data = investorProfiles.map((data) => {
				if (data._id === investorProfileUpdateData._id) {
					data.name = investorProfileUpdateData.name
					data.businessName = investorProfileUpdateData.businessName
					data.lastName = investorProfileUpdateData.lastName
					data.nif = investorProfileUpdateData.nif
					data.phone = investorProfileUpdateData.phone
					data.email = investorProfileUpdateData.email
					data.address = investorProfileUpdateData.address
					data.bankAccountNumber = investorProfileUpdateData.bankAccountNumber
					data.province = investorProfileUpdateData.province
					data.location = investorProfileUpdateData.location
					data.postalCode = investorProfileUpdateData.postalCode
					data.profileType = investorProfileUpdateData.profileType
					data.gender = investorProfileUpdateData.gender === 'Masculino' ? 'male' : 'female'
				}
				return data
			})
			setInvestorProfiles(data)
		}
	}, [successInvestorProfileUpdate, setInvestorProfiles])
	useEffect(() => {
		return () => dispatch({ type: INVESTORS_PROFILE_BY_USER_RESET })
	}, [dispatch])

	const showInvestorInfoHandler = (item) => {
		setShowInvestorUpdateInfo(item)
		setInvestorProfileModal(true)
	}
	const handleCloseInvestorUpdateModal = () => {
		setInvestorProfileModal(false)
		setShowInvestorUpdateInfo({})
	}
	const handleCloseInvestorProfileUploadDocumentModal = () => {
		closeModal(setShowInvestorProfileUploadDocumentInfo, setInvestorProfileUploadDocumentModal)
	}

	return (
		<>
			<GridContainer>
				<GridItem xs={12} className={classes.rootItem}>
					<Card>
						<CardHeader color='primary' icon>
							<CardIcon color='primary'>
								<Assignment />
							</CardIcon>
							<h4 className={classes.cardIconTitle}>Listas de Perfiles</h4>
						</CardHeader>
						<CardBody>
							<ReactVirtualizedTable
								columns={InvestorProfileListColumns({ showInvestorInfoHandler })}
								data={data}
								loading={Boolean(loadingInvestorsProfileByUser)}
								success={Boolean(successInvestorsProfileByUser)}
								error={errorInvestorsProfileByUser}
								emptyText={'No se encontraron inversores'}
							/>
						</CardBody>
					</Card>
					{investorProfileModal && (
						<InvestorProfileUpdateModal
							handleCloseInvestorUpdateModal={handleCloseInvestorUpdateModal}
							investorProfileModal={investorProfileModal}
							showInvestorUpdateInfo={showInvestorUpdateInfo}
						/>
					)}
					{investorProfileUploadDocumentModal && (
						<UploadProfileDocumentsModal
							investorProfileUploadDocumentModal={investorProfileUploadDocumentModal}
							handleCloseInvestorProfileUploadDocumentModal={handleCloseInvestorProfileUploadDocumentModal}
							showInvestorProfileUploadDocumentInfo={showInvestorProfileUploadDocumentInfo}
							investorProfiles={investorProfiles}
							setInvestorProfiles={setInvestorProfiles}
						/>
					)}
				</GridItem>
			</GridContainer>
		</>
	)
}

export default InvestorProfileListScreen
