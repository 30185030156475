import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
	...modalStyle(theme),
	circularProgress: {
		display: 'flex',
		justifyContent: 'center',
		marginBottom: '20px',
	},
	textInfo: {
		fontSize: '1rem',
		textAlign: 'center',
	},
})

export default styles
