import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { MenuItem, FormControl, Select, InputLabel } from '@material-ui/core'
import { Autocomplete, TextField, InputLabel as InputLabelNew } from '@mui/material'
import CustomInput from 'components/CustomInput/CustomInput'
import GridContainer from 'components/Grid/GridContainer'
import Button from 'components/CustomButtons/Button'
import GridItem from 'components/Grid/GridItem'
import Retention from 'components/Retention/Retention'
import { countriesList } from 'shared/helpers/nationality-helpers'

const InputsLegalPerson = ({
	data,
	setData,
	investorsProfileListData,
	handleChangeFile,
	fileInput,
	handleSelectFile,
	setErrorTaxResidenceFile,
	userDetail,
	classes,
}) => {
	const { retentionListData } = useSelector((state) => state.retentionList)

	useEffect(() => {
		if (data) {
			const dateUpdate = {
				...data,
				email: userDetail?.email || '',
				phone: userDetail?.phone || '',
				representativeName: userDetail?.name || '',
				representativeLastName: userDetail?.lastName || '',
				representativeNif: userDetail?.dni || '',
			}
			setData(dateUpdate)
		}
	}, [userDetail])

	return (
		<GridItem xs={12}>
			<GridContainer style={{ marginBottom: '26px' }}>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText={'Razón Social *'}
						id='investor-businessName'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.businessName,
							onChange: (e) => {
								setData({ ...data, businessName: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText={'CIF *'}
						id='investor-cif'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.cif,
							onChange: (e) => {
								setData({ ...data, cif: e.target.value, representativeNif: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText={'Email *'}
						id='investor-email'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.email,
							onChange: (e) => {
								setData({ ...data, email: e.target.value })
							},
							type: 'email',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText='Teléfono *'
						id='investor-phone'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.phone,
							onChange: (e) => {
								setData({ ...data, phone: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12}>
					<CustomInput
						labelText='Dirección *'
						id='investor-address'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.address || '',
							onChange: (e) => {
								setData({ ...data, address: e.target.value, addressOfTheRepresentative: e.target.value })
							},
							type: 'text',
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={4}>
					<CustomInput
						labelText='Provincia *'
						id='investor-province'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.province,
							onChange: (e) => {
								setData({ ...data, province: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={4}>
					<CustomInput
						labelText='Localidad *'
						id='investor-location'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.location,
							onChange: (e) => {
								setData({ ...data, location: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={4}>
					<CustomInput
						labelText='Código postal *'
						id='investor-postalCode'
						error={(data.postalCode && data.postalCode.length < 4) || (data.postalCode && data.postalCode.length > 5)}
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.postalCode,
							onChange: (e) => {
								setData({ ...data, postalCode: e.target.value })
							},
							type: 'number',
							required: true,
						}}
					/>
				</GridItem>
				<Retention setInfo={setData} info={data} setErrorTaxResidenceFile={setErrorTaxResidenceFile} />

				<GridItem xs={12} sm={2}>
					<CustomInput
						labelText={'Retención *'}
						id='investor-retention'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.country
								? `${retentionListData?.find((item) => item.country === data.country)?.retention} %`
								: data.retention
								? data.retention
								: '19%',
							type: 'text',
							disabled: true,
							required: true,
						}}
					/>
				</GridItem>
				<GridItem
					xs={12}
					sm={5}
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'bottom',
						flexDirextion: 'column',
					}}
				>
					<div className='fileinput'>
						<input
							type='file'
							name='residenceCertificate'
							accept='.pdf,'
							id='residenceCertificate'
							onChange={handleChangeFile}
							ref={fileInput}
						/>
					</div>

					<Button
						style={{ marginTop: '1.3rem' }}
						disabled={data.residenceCertificate ? true : false}
						size='sm'
						block
						onClick={() => handleSelectFile()}
					>
						Certificado de Residencia Fiscal
					</Button>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<FormControl variant='standard' style={{ width: '100%', margin: '12px 0 0 0' }}>
						<InputLabel id='investor-liquidated-label' sx={{ minWidth: '100%' }}>
							Liquidación
						</InputLabel>
						<Select
							labelId='investor-liquidated-label'
							id='investor-liquidated'
							value={data.liquidation}
							onChange={(e) => {
								setData({ ...data, liquidation: e.target.value })
							}}
							label='liquidated'
							sx={{ minWidth: '100%' }}
						>
							<MenuItem value={'pay'}>Pagar</MenuItem>
							<MenuItem value={'reinvest'}>Reinvertir</MenuItem>
							<MenuItem value={'benefits-only'}>Pagar solo beneficios</MenuItem>
						</Select>
					</FormControl>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText='IBAN *'
						id='investor-bankAccountNumber'
						helperText='Formato ES9999999999999999999999'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.bankAccountNumber,
							onChange: (e) => {
								setData({ ...data, bankAccountNumber: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12}>
					<CustomInput
						labelText='Nacionalidad fiscal de la empresa *'
						id='fiscal-nationality'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.fiscalNationality,
							onChange: (e) => {
								setData({ ...data, fiscalNationality: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText={'Nombre Representante *'}
						id='representative-name'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.representativeName,
							onChange: (e) => {
								setData({ ...data, representativeName: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText={'Apellidos Representante *'}
						id='representative-lastname'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.representativeLastName,
							onChange: (e) => {
								setData({ ...data, representativeLastName: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText={'NIF Representante *'}
						id='investor-identification'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.representativeNif,
							onChange: (e) => {
								setData({ ...data, representativeNif: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<CustomInput
						labelText={'Dirección Representante *'}
						id='investor-address'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: data.representativeAddress,
							onChange: (e) => {
								setData({ ...data, representativeAddress: e.target.value })
							},
							type: 'text',
							required: true,
						}}
					/>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<FormControl style={{ width: '100%', margin: '12px 0 0 0' }}>
						<InputLabelNew htmlFor='update-gender' style={{ fontSize: '13px', marginBottom: '3px' }}>
							Nacionalidad Representante *
						</InputLabelNew>
						<Autocomplete
							ListboxProps={{
								className: classes.scrollbar,
							}}
							className={classes.autoComplete}
							disablePortal
							options={countriesList.map((item) => item.name.toUpperCase())}
							value={data?.nationalityOfTheRepresentative}
							onChange={(e, value) => {
								setData({ ...data, nationalityOfTheRepresentative: value?.toLowerCase() })
							}}
							noOptionsText={<span>{'Sin resultados'}</span>}
							getOptionLabel={(e) => e}
							renderInput={(params) => (
								<TextField {...params} placeholder='Seleccione un país' variant='standard' required={true} />
							)}
						/>
					</FormControl>
				</GridItem>
				<GridItem xs={12} sm={6}>
					<FormControl variant='standard' style={{ width: '100%', margin: '12px 0 0 0' }}>
						<InputLabel id='investor-prescriber-label' sx={{ minWidth: '100%' }}>
							Perfiles Prescriptor
						</InputLabel>
						<Select
							labelId='investor-prescriber-label'
							id='iinvestor-prescriber'
							value={data.prescriber ? data.prescriber : ''}
							onChange={(e) => {
								setData({ ...data, prescriber: e.target.value })
							}}
							label='Prescriber'
							sx={{ minWidth: '100%' }}
						>
							<MenuItem disabled>Selecciona Uno</MenuItem>
							{investorsProfileListData &&
								investorsProfileListData.map((profile) => (
									<MenuItem key={profile._id} value={profile._id}>
										{profile.businessName}
									</MenuItem>
								))}
						</Select>
					</FormControl>
				</GridItem>
			</GridContainer>
		</GridItem>
	)
}

export default InputsLegalPerson
