import { primaryColor } from 'assets/jss/material-dashboard-pro-react'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
	buttonGroup: {
		display: 'flex',
		justifyContent: 'space-around',
		minWidth: '700px',
		'@media (max-width: 768px)': {
			minWidth: 'auto',
		},
	},
	cardBorder: {
		boxShadow: 'none',
		border: '2px solid #d1d0d0',
		borderRadius: '5px',
		margin: '15px 0',
	},
	cardHeader: {
		padding: '0',
	},
	title: {
		color: '#999',
		margin: '0 0 10px 0',
	},
	closeIcon: {
		position: 'absolute',
		top: '5px',
		right: 0,
	},
	paddingModal: {
		paddingTop: '10px',
		paddingBottom: '10px',
	},
	selectTitle: {
		marginBottom: '10px',
	},
	selectMenuDiv: {
		border: '1px solid #ded7d7',
		borderRadius: '3px',
		boxShadow: '0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(235 230 226 / 40%)',
		position: 'absolute',
		right: 15,
		left: 15,
		backgroundColor: '#fff',
		zIndex: 10,
		'& ul': {
			padding: 0,
		},
		'& ul li': {
			listStyle: 'none',
			padding: '5px 10px',
			cursor: 'pointer',
			'&:hover': {
				color: '#fff !important',
				backgroundColor: '#333',
			},
		},
	},
	fileBadge: {
		margin: '20px 0',
		width: '100%',
	},
	fileDiv: {
		textAlign: 'center',
		backgroundColor: '#b9b6b6',
		borderRadius: '5px',
		padding: '5px',
		width: '100%',
	},
	dateInput: {
		width: '100%',
		padding: '5px',
		marginTop: '5px',
		'& .MuiInput-underline:before': {
			borderBottom: '1px solid #d2d2d2',
		},
	},
	autoComplete: {
		color: '#3C4858',
		'& .MuiFormControl-root': {
			'& .MuiInputBase-root': {
				'&:before': {
					borderBottomWidth: '1px !important',
					borderBottomColor: grayColor[4] + '!important',
				},
				'&:after': {
					borderBottomColor: primaryColor[0] + '!important',
				},
				'& .MuiInput-input': {
					color: '#3C4858',
					fontSize: '.75rem',
					fontWeight: '400',
					lineHeight: '1.42857',
					textTransform: 'uppercase',
				},
				'& input': {
					'&,&::placeholder': {
						color: 'black',
						opacity: '1',
					},
					'&::placeholder': {
						color: grayColor[1],
					},
				},
			},
		},
	},
	...modalStyle(theme),
})

export default styles
