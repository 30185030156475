import { format } from 'date-fns'
import { Tooltip } from '@mui/material'
import { Visibility } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'

const delayedProjectsColumns = ({
	setDelayedModal,
	setDelayedModalInfo,
	openModal,
	setReasonsFinishedDateUpdateModalInfo,
	setReasonsFinishedDateUpdateModal,
}) => {
	return [
		{
			header: 'Dirección del proyecto',
			accessor: 'projectAddress',
		},
		{
			header: 'Fecha de Fin',
			accessor: 'finishedDate',
			type: 'date',
			cell: ({ row }) => {
				return (
					<div
						onClick={() => openModal(row, setDelayedModalInfo, setDelayedModal)}
						style={{ cursor: 'pointer', color: '#4646c3' }}
					>
						{row?.finishedDate ? format(new Date(row?.finishedDate), 'dd-MM-yyyy') : 'Fecha no establecida'}
					</div>
				)
			},
		},
		{
			header: 'Fecha de fin según dossier',
			accessor: 'finishDateDossier',
			type: 'date',
		},
		{
			header: 'Días de retraso',
			accessor: 'delayedDays',
		},
		{
			header: 'Acciones',
			accessor: 'actions',
			cellAlign: 'right',
			headerAlign: 'right',
			cell: ({ row }) => (
				<div>
					{row?.updateFinishedDateReasonsCount && !row?.updateFinishedDateReasonsCount < 1 && (
						<Tooltip id='tooltip-top' title='Ver Notas' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => openModal(row, setReasonsFinishedDateUpdateModalInfo, setReasonsFinishedDateUpdateModal)}
								color='success'
								className='edit'
							>
								<Visibility />
							</Button>
						</Tooltip>
					)}
				</div>
			),
		},
	]
}

export default delayedProjectsColumns
