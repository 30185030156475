import { cardTitle } from 'assets/jss/material-dashboard-pro-react'
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
	},
	rootItem: {
		padding: '0 !important',
		display: 'flex',
		justifyContent: 'end',
	},
	loader: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '10px',
	},
	...modalStyle(theme),
})

export default styles
