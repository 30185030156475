import React from 'react'
import { InputLabel, Select, MenuItem, makeStyles } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import styles from '../styles/filterInvestmentStyles'

const useStyles = makeStyles(styles)

const FixedRateLoansInputs = ({ fixedRateLoan, setFixedRateLoan, investorProfiles }) => {
	const classes = useStyles()

	return (
		<GridContainer>
			<GridItem xs={12} sm={12} md={12}>
				<InputLabel id='investorProfile'>Seleccione el Perfil Inversor</InputLabel>
				<Select
					MenuProps={{
						className: classes.selectMenu,
					}}
					labelId='investorProfileLabel'
					id='investorProfile'
					value={fixedRateLoan.investorProfile}
					onChange={(e) =>
						setFixedRateLoan({
							...fixedRateLoan,
							investorProfile: e.target.value,
						})
					}
					fullWidth
					required
				>
					{investorProfiles.map((profile) => (
						<MenuItem
							key={profile}
							value={profile._id}
							classes={{
								root: classes.selectMenuItem,
								selected: classes.selectMenuItemSelected,
							}}
						>
							{`${profile.businessName}`}
						</MenuItem>
					))}
				</Select>
			</GridItem>
			<GridItem xs={12} sm={12} md={6}>
				<div style={{ position: 'relative', marginTop: '10px' }}>
					<InputLabel className={classes.label}>Fecha Inicio *</InputLabel>
					<CustomInput
						id='startDate'
						formControlProps={{
							fullWidth: true,
						}}
						inputProps={{
							value: fixedRateLoan.startDate,
							onChange: (e) =>
								setFixedRateLoan({
									...fixedRateLoan,
									startDate: e.target.value,
								}),
							type: 'date',
							required: true,
						}}
					/>
				</div>
			</GridItem>
			<GridItem xs={12} sm={12} md={6}>
				<div style={{ position: 'relative', marginTop: '10px' }}>
					<InputLabel className={classes.label}>Fecha Final *</InputLabel>
					<CustomInput
						id='finishDate'
						formControlProps={{
							fullWidth: true,
							style: { marginBottom: 10 },
						}}
						inputProps={{
							value: fixedRateLoan.finishDate,
							onChange: (e) =>
								setFixedRateLoan({
									...fixedRateLoan,
									finishDate: e.target.value,
								}),
							type: 'date',
							required: true,
						}}
					/>
				</div>
			</GridItem>
			<GridItem xs={12} sm={12} md={6}>
				<CustomInput
					labelText='Importe*'
					id='amount'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: fixedRateLoan.amount,
						onChange: (e) =>
							setFixedRateLoan({
								...fixedRateLoan,
								amount: e.target.value,
							}),
						type: 'number',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={12} md={6}>
				<CustomInput
					labelText='Tipo de Interés *'
					id='typesOfInterestRates'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: fixedRateLoan.typesOfInterestRates,
						onChange: (e) =>
							setFixedRateLoan({
								...fixedRateLoan,
								typesOfInterestRates: e.target.value,
							}),
						type: 'number',
						required: true,
					}}
				/>
			</GridItem>
		</GridContainer>
	)
}

export default FixedRateLoansInputs
