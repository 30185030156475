const reasonsListMapper = (reasonsListByProjectIdListData) =>
	reasonsListByProjectIdListData?.map((item) => {
		return {
			id: item?._id,
			created: item?.created,
			user: item?.name,
			reason: item?.reason,
		}
	})

export default reasonsListMapper
