import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	makeStyles,
	Select,
	MenuItem,
	InputLabel,
} from '@material-ui/core'
import { FormControl } from '@mui/material'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { updateInvestmentStatus } from 'local_redux/actions/investmentActions'
import { UPDATE_INVESTMENT_STATUS_RESET } from 'local_redux/constants/investmentConstants'
import styles from '../styles/updateInvestmentStatusModalStyles'

const useStyles = makeStyles(styles)

const UpdateInvestmentStatusModal = ({ setOpenModal, openModal, investment }) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [status, setStatus] = useState(investment?.liquidation)

	const { successUpdateInvestmentStatus, loadingUpdateInvestmentStatus, errorUpdateInvestmentStatus } = useSelector(
		(state) => state.updateInvestmentStatus,
	)

	useEffect(() => {
		if (successUpdateInvestmentStatus) {
			setOpenModal(false)
		}

		return () => {
			dispatch({ type: UPDATE_INVESTMENT_STATUS_RESET })
		}
	}, [successUpdateInvestmentStatus])

	const handleInvestmentData = () => {
		const data = {
			_id: investment._id,
			liquidation: status,
		}

		dispatch(updateInvestmentStatus(data))
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={openModal}
			maxWidth='xs'
			keepMounted
			onClose={() => setOpenModal(false)}
		>
			<DialogTitle id='notice-modal-slide-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={() => setOpenModal(false)}
				>
					<Close className={classes.modalClose} />
				</Button>
				Cambiar estado de la inversión
			</DialogTitle>
			<>
				<DialogContent id='notice-modal-slide-description'>
					<FormControl fullWidth className={classes.selectFormControl}>
						<InputLabel htmlFor='user-update-type'>Estado</InputLabel>
						<Select
							MenuProps={{
								className: classes.selectMenu,
							}}
							classes={{
								select: classes.select,
							}}
							value={status}
							onChange={(e) => setStatus(e.target.value)}
							inputProps={{
								name: 'user-update-type',
								id: 'user-update-type',
							}}
						>
							<MenuItem disabled>Selecciona una Opción</MenuItem>
							{[
								{ label: 'Reinvertir', value: 'reinvest' },
								{ label: 'Pagar', value: 'pay' },
								//{ label: 'Solo Beneficios', value: 'benefits-only' },
							].map((item, index) => (
								<MenuItem
									classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelected }}
									value={item.value}
									key={index}
								>
									{item.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</DialogContent>
				<DialogActions className={classes.modalFooter}>
					<GridContainer>
						{errorUpdateInvestmentStatus && (
							<GridItem xs={12}>
								<SnackbarContent message={errorUpdateInvestmentStatus} color='danger' />
							</GridItem>
						)}
						<GridItem xs={12}>
							<Button
								onClick={handleInvestmentData}
								color={successUpdateInvestmentStatus ? 'success' : 'primary'}
								disabled={false}
								block
							>
								{loadingUpdateInvestmentStatus ? 'Guardando...' : 'Guardar'}
							</Button>
						</GridItem>
					</GridContainer>
				</DialogActions>
			</>
		</Dialog>
	)
}

export default UpdateInvestmentStatusModal
