import { Delete, NoteAdd, Visibility } from '@mui/icons-material'
import { Switch, Tooltip } from '@mui/material'
import Button from 'components/CustomButtons/Button'
import { validateStatus } from '../helpers/validate-status'
import { formatStatus } from '../helpers/format-status'

export const investorUsersListMapper = (
	investorUsers,
	handleDeployNotesModal,
	showDeleteInfoHandler,
	setIdInvestorUser,
	handleNavigation,
	handleStatusUser,
	loadingUserUpdate,
	classes,
) => {
	return investorUsers.map((item) => {
		const statusUser = validateStatus(item.status) ? 'Inactivo' : 'active'
		return {
			id: item._id,
			businessName: `${item.name} ${item.lastName}`.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
			email: item.email,
			totalPages: item.totalPages,
			model: 'profile',
			actions: (
				<div className='actions-right'>
					<Tooltip id='tooltip-top' title='Ver/Agregar Nota' placement='bottom'>
						<Button justIcon round simple onClick={() => handleDeployNotesModal(item)} color='success' className='edit'>
							<NoteAdd />
						</Button>
					</Tooltip>
					<Button
						justIcon
						round
						simple
						onClick={() => handleNavigation(`/admin/investor-list-users?investorId=${item._id}`)}
						color='success'
						className='edit'
					>
						<Visibility />
					</Button>
					<Tooltip id='tooltip-top' title={formatStatus[item.status]} placement='bottom'>
						<Switch
							checked={validateStatus(item.status)}
							onChange={() => handleStatusUser({ _id: item._id, status: statusUser, email: item.email })}
							disabled={loadingUserUpdate}
							value='variousProperties'
							color='primary'
							classes={{
								switchBase: classes.switchBase,
								checked: classes.switchChecked,
								thumb: classes.switchIcon,
								track: classes.switchBar,
							}}
						/>
					</Tooltip>
					<Button
						justIcon
						round
						simple
						onClick={() => [showDeleteInfoHandler(item._id), setIdInvestorUser(item._id)]}
						color='danger'
						className='delete'
					>
						<Delete />
					</Button>
				</div>
			),
		}
	})
}
