import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { deleteIncomeDate } from 'local_redux/actions/incomeAction'
import { INCOME_DELETE_DATE_RESET } from 'local_redux/constants/incomeConstants'
import styles from '../styles/investmentAdminDeleteModalStyles'

const useStyles = makeStyles(styles)

const DeleteIncomeDateModal = ({
  handleCloseInvestmentDateAdminModal,
  deleteInvestmentDateAdminModal,
  showDeleteInvestmentDateInfo,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [reason, setReason] = useState('')

  const { successIncomeDeleteDate, loadingincomeDeleteDate, errorIncomeDeleteDate } = useSelector(
    (state) => state.incomeDeleteDate
  )

  useEffect(() => {
    let timeOut = ''
    if (successIncomeDeleteDate) {
      timeOut = setTimeout(() => {
        handleCloseInvestmentDateAdminModal()
      }, 1500)
    }
    return () => clearTimeout(timeOut)
  }, [successIncomeDeleteDate])
  useEffect(() => {
    return () => dispatch({ type: INCOME_DELETE_DATE_RESET })
  }, [dispatch])

  const handleDeclineInvestment = (e) => {
    e.preventDefault()
    dispatch(deleteIncomeDate({ ...showDeleteInvestmentDateInfo, reason }))
  }

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={deleteInvestmentDateAdminModal}
      keepMounted
      onClose={handleCloseInvestmentDateAdminModal}
      aria-labelledby='user-modal-delete-title'
      aria-describedby='user-modal-delete-description'
    >
      <DialogTitle id='user-modal-delete-title' disableTypography className={classes.modalHeader}>
        <Button
          justIcon
          className={classes.modalCloseButton}
          key='close'
          aria-label='Close'
          color='transparent'
          onClick={handleCloseInvestmentDateAdminModal}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4>Eliminar fecha de Ingreso</h4>
      </DialogTitle>
      <DialogContent id='user-modal-delete-description' className={classes.modalBody}>
        <form onSubmit={handleDeclineInvestment} id='delete-income-date-form'>
          <GridContainer>
            <GridItem xs={12}>
              <Typography>
                ¿Estás seguro que queres eliminar la fecha de ingreso de la inversión del proyecto{' '}
                <b>{showDeleteInvestmentDateInfo.projectAddress}</b>?
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText='Motivo *'
                id='reason'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: reason,
                  onChange: (e) => {
                    setReason(e.target.value)
                  },
                  type: 'text',
                  required: true,
                }}
              />
            </GridItem>
          </GridContainer>
          {errorIncomeDeleteDate && (
            <GridContainer>
              <GridItem xs={12}>
                <SnackbarContent message={errorIncomeDeleteDate} color='danger' />
              </GridItem>
            </GridContainer>
          )}
        </form>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <GridContainer>
          <GridItem xs={6}>
            <Button onClick={handleCloseInvestmentDateAdminModal} block>
              Cancelar
            </Button>
          </GridItem>
          <GridItem xs={6}>
            <Button
              type='submit'
              color={successIncomeDeleteDate ? 'success' : 'primary'}
              block
              form='delete-income-date-form'
            >
              {loadingincomeDeleteDate ? 'Eliminando' : successIncomeDeleteDate ? 'Eliminado' : 'Eliminar'}
            </Button>
          </GridItem>
        </GridContainer>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteIncomeDateModal
