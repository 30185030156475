import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import es from 'date-fns/locale/es'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker'
import Typography from '@mui/material/Typography'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import CustomInput from 'components/CustomInput/CustomInput'
import { updateFinishedDateProject } from 'local_redux/actions/projectAction'
import { PROJECT_UPDATE_FINISHED_DATE_RESET } from 'local_redux/constants/projectConstant'
import styles from '../styles/updateFinishedDateModalStyles'

const useStyles = makeStyles(styles)

const UpdateFinishedDateModal = ({
	handleCloseUpdateFinishedDateModal,
	updateFinishedDateModal,
	showUpdateFinishedDateInfo,
}) => {
	const dispatch = useDispatch()
	const classes = useStyles()

	const [reason, setReason] = useState('')
	const [date, setDate] = useState(new Date())

	const { loadingProjectsUpdateFinishedDate, successProjectsUpdateFinishedDate, errorProjectsUpdateFinishedDate } =
		useSelector(({ projectsUpdateFinishedDate }) => projectsUpdateFinishedDate)

	useEffect(() => {
		return () => dispatch({ type: PROJECT_UPDATE_FINISHED_DATE_RESET })
	}, [dispatch])

	const handleUpdateFinishedDate = (e) => {
		e.preventDefault()
		const data = {
			_id: showUpdateFinishedDateInfo.id,
			reason,
			date,
		}

		dispatch(updateFinishedDateProject(data))
	}

	return (
		<Dialog
			classes={{
				root: classes.modalRoot,
				paper: classes.modal,
			}}
			open={updateFinishedDateModal}
			keepMounted
			onClose={handleCloseUpdateFinishedDateModal}
			aria-labelledby='update-finished-date-title'
			aria-describedby='update-finished-date-description'
		>
			<DialogTitle id='update-finished-date-title' disableTypography className={classes.modalHeader}>
				<Button
					justIcon
					className={classes.modalCloseButton}
					key='close'
					aria-label='Close'
					color='transparent'
					onClick={handleCloseUpdateFinishedDateModal}
				>
					<Close className={classes.modalClose} />
				</Button>
				<h4>Modificar Fecha de Fin</h4>
			</DialogTitle>

			<DialogContent id='update-finished-date-description' className={classes.modalBody}>
				<form onSubmit={handleUpdateFinishedDate} id='update-project-date'>
					<GridContainer>
						<GridItem xs={12}>
							<Typography>
								Debe ingresar un motivo por el cual se está cambiando la fecha de fin del proyecto.
							</Typography>
						</GridItem>
						<GridItem xs={12} className={classes.input}>
							<CustomInput
								labelText='Motivo *'
								id='reason'
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									value: reason,
									onChange: (e) => setReason(e.target.value),
									type: 'text',
									required: true,
								}}
							/>
						</GridItem>
						<GridItem xs={12}>
							<LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
								<CalendarPicker
									className={classes.calendaryContainer}
									date={date}
									onChange={(newDate) => setDate(newDate)}
									disablePast
								/>
							</LocalizationProvider>
						</GridItem>
					</GridContainer>
					{errorProjectsUpdateFinishedDate && (
						<GridContainer>
							<GridItem xs={12}>
								<SnackbarContent message={errorProjectsUpdateFinishedDate} color='danger' />
							</GridItem>
						</GridContainer>
					)}
				</form>
			</DialogContent>
			<DialogActions className={classes.modalFooter}>
				<GridContainer>
					<GridItem xs={6}>
						<Button onClick={handleCloseUpdateFinishedDateModal} block>
							Cancelar
						</Button>
					</GridItem>
					<GridItem xs={6}>
						<Button
							type='onSubmit'
							form='update-project-date'
							color={successProjectsUpdateFinishedDate ? 'success' : 'primary'}
							disabled={!reason || format(new Date(), 'dd-MM-yyyy') === format(new Date(date), 'dd-MM-yyyy')}
							block
						>
							{loadingProjectsUpdateFinishedDate
								? 'Guardando'
								: successProjectsUpdateFinishedDate
								? 'Guardado'
								: 'Guardar'}
						</Button>
					</GridItem>
				</GridContainer>
			</DialogActions>
		</Dialog>
	)
}

export default UpdateFinishedDateModal
