import { cardTitle } from 'assets/jss/material-dashboard-pro-react'
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
	},
	rootItem: {
		padding: '0 !important',
	},
	loader: {
		display: 'flex',
		justifyContent: 'center',
		marginTop: '10px',
	},
	selectLabel: {
		fontSize: '14px',
		top: '8px',
	},
	option: { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },

	...modalStyle(theme),
})

export default styles
