import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'

const styles = (theme) => ({
  card: {
    padding: '20px',
    margin: 'auto',
  },
  gridBody: {
    boxSizing: 'border-box',
    margin: 'auto',
    width: '100%',
    marginBottom: '20px',
  },
  imageRound: {
    borderRadius: '50%',
    height: '80px',
    width: '80px',
  },
  image: {
    borderRadius: '7px',
    height: '80px',
    width: '80px',
  },
  header: {
    display: 'flex',
    flexWrap: 'nowrap',
    marginBottom: '10px',
  },
  text2: {
    fontWeight: 'bold',
  },
  ...modalStyle(theme),
})

export default styles
