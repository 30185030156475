import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput'

const InputsUser = ({ userInfo, setUserInfo }) => {
	return (
		<GridContainer style={{ marginBottom: '26px' }}>
			<GridItem xs={12} sm={6}>
				<CustomInput
					labelText={'Nombre *'}
					id='name'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.name,
						onChange: (e) => setUserInfo({ ...userInfo, name: e.target.value, representativeName: e.target.value }),
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={6}>
				<CustomInput
					labelText={'Apellidos *'}
					id='lastName'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.lastName,
						onChange: (e) =>
							setUserInfo({ ...userInfo, lastName: e.target.value, representativeLastName: e.target.value }),
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={6}>
				<CustomInput
					labelText={'DNI/NIE *'}
					id='dni'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.dni,
						onChange: (e) =>
							setUserInfo({
								...userInfo,
								dni: e.target.value,
								representativeNif: e.target.value,
								nif: e.target.value,
								cif: userInfo.personType === 'legal' ? e.target.value : '',
							}),
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={6}>
				<CustomInput
					labelText={'Email *'}
					id='email'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.email,
						onChange: (e) => setUserInfo({ ...userInfo, email: e.target.value, businessEmail: e.target.value }),
						type: 'email',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={6}>
				<CustomInput
					labelText='Teléfono *'
					id='phone'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.phone,
						onChange: (e) => setUserInfo({ ...userInfo, phone: e.target.value, businessPhone: e.target.value }),
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={6}>
				<CustomInput
					labelText='Teléfono 2 (No principal)'
					id='phone2'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.phone2,
						onChange: (e) => setUserInfo({ ...userInfo, phone2: e.target.value }),
						type: 'text',
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={6}>
				<CustomInput
					labelText='Dirección *'
					id='address'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.address,
						onChange: (e) =>
							setUserInfo({
								...userInfo,
								address: e.target.value,
								businessAddress: e.target.value,
								representativeAddress: e.target.value,
							}),
						type: 'text',
						required: true,
					}}
				/>
			</GridItem>
			<GridItem xs={12} sm={6}>
				<CustomInput
					labelText='Sponsor '
					id='sponsor'
					formControlProps={{
						fullWidth: true,
					}}
					inputProps={{
						value: userInfo.sponsor,
						onChange: (e) => setUserInfo({ ...userInfo, sponsor: e.target.value }),
						type: 'text',
					}}
				/>
			</GridItem>
		</GridContainer>
	)
}

export default InputsUser
