import { Tooltip } from '@material-ui/core'
import { Visibility } from '@mui/icons-material'
import Button from 'components/CustomButtons/Button'
import OptionsMenuTable from 'components/OptionsMenuTable/OptionsMenuTable'
import { formatCurrency } from 'shared/helpers/currency-helpers'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'

const LIQUIDATEDINVESTMENT_COLUMNS = ({
	userInfo,
	openModal,
	setShowDetailInvestmentAdminInfo,
	setInvestmentId,
	setDetailInvestmentAdminModal,
	setOpenInvestmentCommmentModal,
	setShowGenerateRCModal,
	setIdForRetentionCertificate,
	setOpenReinvestmentModal,
	setOpenReinvestmentPDLModal,
	setOpenUpdateInvestmentStatus,
	setShowReinvestAdminInfo,
	handlePayStatus,
}) => {
	return [
		{
			header: 'Perfil Inversor',
			accessor: 'investorProfile',
			size: 'md',
		},
		{
			header: 'Email',
			accessor: 'email',
			size: 'md',
		},
		{
			header: 'Comentarios añadidos',
			accessor: 'hasComments',
			size: 'sm',
		},
		{
			header: 'Dirección de Proyecto',
			accessor: 'projectAddress',
			size: 'md',
		},
		{
			header: 'Importe',
			accessor: 'amount',
			size: 'xs',
		},
		{
			header: 'Retención',
			accessor: 'retention',
			size: 'xs',
			nullValue: 'Sin retención',
		},
		{
			header: 'Beneficio',
			accessor: 'benefit',
			size: 'xs',
		},
		{
			header: 'Importe de retención',
			size: 'xs',
			cell: ({ row }) =>
				formatCurrency(
					(parseFloat(row.benefit.replace('€', '').replace('.', '').replace(',', '.').replace('−', '-').trim()) *
						parseFloat(row.retention.replace('%', '').trim())) /
						100,
				),
		},
		{
			header: 'A Pagar',
			accessor: 'toPay',
			size: 'xs',
		},

		{
			header: 'Confirmación Ingreso',
			accessor: 'incomeRecivedDate',
			type: 'date',
			dateFormat: 'dd-MM-yyyy',
			size: 'md',
		},
		{
			header: 'Estado de Contrato',
			accessor: 'singedContract',
			size: 'md',
			cell: ({ row, cell }) => (
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						width: '100%',
						justifyContent: 'space-between',
						padding: '0 10px',
						alignItems: 'center',
					}}
				>
					{cell}
					{cell === 'Firmado' ? (
						<Tooltip id='tooltip-top' title='Ver contrato' placement='bottom'>
							<Button
								justIcon
								round
								simple
								onClick={() => window.open(getUrlToOpenFile(row.contractUrl, userInfo))}
								color='success'
								className='view'
							>
								<Visibility />
							</Button>
						</Tooltip>
					) : (
						' - '
					)}
				</div>
			),
		},
		{
			header: 'Fecha Inicio Proyecto',
			accessor: 'startDateUpdated',
			type: 'date',
			dateFormat: 'dd-MM-yyyy',
			size: 'md',
		},
		{
			header: 'Fecha Liquidación',
			accessor: 'dateLiquidation',
			type: 'date',
			dateFormat: 'dd-MM-yyyy',
			nullValue: 'Fecha no Registrada',
			size: 'md',
		},
		{
			header: 'TIR Inversor',
			accessor: 'investorTIR',
			size: 'xs',
		},
		{
			header: 'Fondos',
			accessor: 'liquidation',
			size: 'md',
		},
		{
			header: 'Acciones',
			accessor: 'actions',
			size: 'sm',
			cellAlign: 'right',
			headerAlign: 'right',
			cell: ({ row }) => (
				<div className='actions-right'>
					<OptionsMenuTable
						options={[
							<div onClick={() => openModal(row.item._id, setInvestmentId, setOpenInvestmentCommmentModal)}>
								Comentarios
							</div>,
							<div onClick={() => openModal(row.item, setShowDetailInvestmentAdminInfo, setDetailInvestmentAdminModal)}>
								Mas Información
							</div>,
							<div
								onClick={() => {
									setShowGenerateRCModal(true)
									setIdForRetentionCertificate(row.item._id)
								}}
							>
								Descargar Certificado de Retenciones
							</div>,
						]
							.concat(
								(row.item.liquidation === 'reinvest' || row.item.liquidation === 'benefits-only') &&
									!row.item.wasInvestment ? (
									<div onClick={() => openModal(row.item, setShowReinvestAdminInfo, setOpenReinvestmentModal)}>
										Reinvertir en Co-Inversión
									</div>
								) : (
									[]
								),
							)
							.concat(
								row.item.liquidation === 'reinvest' && !row.item.wasInvestment ? (
									<div onClick={() => openModal(row.item, setShowReinvestAdminInfo, setOpenUpdateInvestmentStatus)}>
										Cambiar Estado
									</div>
								) : (
									[]
								),
							)
							.concat(
								(row.item.liquidation === 'benefits-only' || row.item.liquidation === 'reinvest') &&
									!row.item.wasInvestment ? (
									<div onClick={() => handlePayStatus(row.item)}>Finalmente Pagados</div>
								) : (
									[]
								),
							)
							.concat(
								(row.item.liquidation === 'reinvest' || row.item.liquidation === 'benefits-only') &&
									!row.item.wasInvestment ? (
									<div onClick={() => openModal(row.item, setShowReinvestAdminInfo, setOpenReinvestmentPDLModal)}>
										Reinvertir en PDL
									</div>
								) : (
									[]
								),
							)}
					/>
				</div>
			),
		},
	]
}

export default LIQUIDATEDINVESTMENT_COLUMNS
