import { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import { Attachment } from '@material-ui/icons'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import Loader from 'components/Loader/Loader'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import { FilterReactVirtualizedTableContext } from 'contexts/filterReactVirtualizedTableContext'
import { replaceStringToNum } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import { parseNumberToEURCurrencyString } from 'shared/dealWithTypesOfNumbers/dealWithTypesOfNumbers'
import styles from '../styles/investmentsListResumeStyles'

const useStyles = makeStyles(styles)

const InvestmentsListResume = ({ tableId, activeInvestments, liquidatedInvesments }) => {
	const classes = useStyles()

	const { getFilteredDataRows, filteredRows } = useContext(FilterReactVirtualizedTableContext)

	const [activeInvests, setActiveInvests] = useState(0)
	const [liquidatedAmount, setLiquidedAmount] = useState(0)
	const [liquidatedQuantity, setLiquidedQuantity] = useState(0)
	const [TIR, setTIR] = useState(false)
	const [profitsTotals, setProfitsTotals] = useState(0)

	const { liquidatedInvestmentsListData, loadingLiquidatedInvestmentsList: loading } = useSelector(
		(state) => state.liquidatedInvestmentsList,
	)

	useEffect(() => {
		const active = getFilteredDataRows({ tableId })
			.filter((d) => d.status !== 'Liquidado')
			.map((d) => ({ ...d, amount: replaceStringToNum(d.amount) }))
		let activeAmount = 0

		active.forEach((element) => {
			activeAmount += element.amount
		})
		setActiveInvests(activeAmount)
	}, [tableId, filteredRows])
	useEffect(() => {
		let liquidatedAmount = 0
		let liquidatedQuantity = getFilteredDataRows({ tableId })?.length
		let tirSum = 0
		let profits = 0

		getFilteredDataRows({ tableId })?.forEach((inv) => {
			const element = liquidatedInvestmentsListData?.find((d) => d._id === inv.id)
			if (element) {
				liquidatedAmount += replaceStringToNum(element.amount)
				if (typeof element.investorTIR === 'number') tirSum += element.investorTIR
				// hotfix validation element.toPay
				if (element.toPay) {
					profits += Math.abs(
						replaceStringToNum(element.toPay) -
							(element.liquidation === 'benefits-only' ? 0 : replaceStringToNum(element.amount)),
					)
				}
			}
		})

		setLiquidedAmount(liquidatedAmount)
		setLiquidedQuantity(liquidatedQuantity)
		setTIR(parseFloat(tirSum / getFilteredDataRows({ tableId }).length).toFixed(2))
		setProfitsTotals(profits)
	}, [liquidatedInvestmentsListData, filteredRows])

	return (
		<GridContainer>
			<GridItem xs={12} sm={8} md={4}>
				<Card className={classes.cardStyle}>
					<CardHeader color='primary' icon>
						<CardIcon color='primary'>
							<Attachment />
						</CardIcon>
					</CardHeader>
					{loading ? (
						<GridItem xs={12} className={classes.loader}>
							<Loader />
						</GridItem>
					) : (
						<>
							{activeInvestments && (
								<CardBody>
									<h4 className={classes.cardTitle}>Inversiones Activas</h4>
									<p>
										Importe invertido:{' '}
										<strong>
											{getFilteredDataRows({ tableId }).length > 0
												? parseNumberToEURCurrencyString(activeInvests)
												: '-'}
										</strong>
									</p>
								</CardBody>
							)}
							{liquidatedInvesments && (
								<CardBody>
									<h4 className={classes.cardTitle}>Inversiones Liquidadas</h4>
									<p>
										Inversiones Liquidadas:{' '}
										<strong>{getFilteredDataRows({ tableId }).length > 0 ? liquidatedQuantity : '-'}</strong>
									</p>
									<p>
										Importe Liquidado:{' '}
										<strong>
											{getFilteredDataRows({ tableId }).length > 0
												? parseNumberToEURCurrencyString(liquidatedAmount + profitsTotals)
												: '-'}
										</strong>
									</p>
									<p>
										TIR media: <strong>{getFilteredDataRows({ tableId }).length > 0 ? TIR : '-'} %</strong>
									</p>
									<p>
										Beneficio:{' '}
										<strong>
											{getFilteredDataRows({ tableId }).length > 0
												? parseNumberToEURCurrencyString(profitsTotals)
												: '-'}
										</strong>
									</p>
								</CardBody>
							)}
						</>
					)}
				</Card>
			</GridItem>
		</GridContainer>
	)
}

export default InvestmentsListResume
