import { createRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SweetAlert from 'react-bootstrap-sweetalert'
import { makeStyles, FormControlLabel, Switch, Badge } from '@material-ui/core'
import { Close, PersonAdd } from '@mui/icons-material'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import Card from 'components/Card/Card'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CardHeader from 'components/Card/CardHeader'
import CardIcon from 'components/Card/CardIcon'
import CardBody from 'components/Card/CardBody'
import SelectInputGender from 'components/SelectInputGender/SelectInputGender'
import InputsUser from './components/InputsUser'
import InputsPhysicalProfile from './components/InputsPhysicalProfile'
import InputsLegalProfile from './components/InputsLegalProfile'
import InvestorRegisterTitles from './components/InvestorRegisterTitles'
import { matchPersonType, matchGender } from 'shared/matchData/matchData'
import { complexPasswordValidation } from 'shared/validators/inputValidators'
import { decidePerson } from 'shared/helpers/decide-person'
import { investorUserInputsInitialState } from 'shared/initial-states/investor-user-initial-state'
import { INVESTOR_PROFILE_WITH_USER_REGISTER_RESET } from 'local_redux/constants/investorProfileConstants'
import { registerProfileInvestorWithUser } from 'local_redux/actions/investorProfileActions'
import { getPrescriberList } from 'local_redux/actions/prescriberActions'
import styles from './styles/investorUserRegisterScreenStyles'

const useStyles = makeStyles(styles)

const InvestorUserRegisterScreen = () => {
	const dispatch = useDispatch()
	const fileInput = createRef()
	const classes = useStyles()

	const [data, setData] = useState(investorUserInputsInitialState)
	const [isPhysical, setIsPhysical] = useState(false)
	const [isLegal, setIsLegal] = useState(false)
	const [errorGender, setErrorGender] = useState('')
	const [errorAddress, setErrorAddress] = useState('')
	const [errorPasswordValidation, setErrorPasswordValidation] = useState('')
	const [alert, setAlert] = useState(null)
	const [errorTaxResidenceFile, setErrorTaxResidenceFile] = useState('')
	const [prescribers, setPrescribers] = useState([])

	const {
		loadingInvestorProfileWithUserRegister,
		errorInvestorProfileWithUserRegister,
		successInvestorProfileWithUserRegister,
	} = useSelector((state) => state.investorProfileWithUserRegister)
	const { successPrescriberList, prescriberListData } = useSelector((state) => state.prescriberList)

	useEffect(() => {
		if (successInvestorProfileWithUserRegister) {
			setAlert(
				<SweetAlert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title='¡Hecho!'
					// eslint-disable-next-line no-use-before-define
					onConfirm={() => confirmSuccess()}
					// eslint-disable-next-line no-use-before-define
					onCancel={() => hideAlert()}
					confirmBtnCssClass={classes.confirmBtnCssClass}
				>
					Inversor Registrado Correctamente.
				</SweetAlert>,
			)
		}
	}, [successInvestorProfileWithUserRegister])
	useEffect(() => {
		if (successPrescriberList) {
			setPrescribers(prescriberListData)
		}
	}, [successPrescriberList])
	useEffect(() => {
		return () => dispatch({ type: INVESTOR_PROFILE_WITH_USER_REGISTER_RESET })
	}, [dispatch])
	useEffect(() => {
		dispatch(getPrescriberList())
	}, [dispatch])

	const hideAlert = () => {
		setAlert(null)
	}
	const confirmSuccess = () => {
		setData(investorUserInputsInitialState)
		setErrorPasswordValidation('')
		setErrorGender('')
		setErrorTaxResidenceFile('')
		setIsPhysical(false)
		setIsLegal(false)
		dispatch({ type: INVESTOR_PROFILE_WITH_USER_REGISTER_RESET })
		setAlert(null)
	}
	const handlePhysicalPerson = (e) => {
		setIsPhysical(e.target.checked)
		e.target.checked ? setData({ ...data, personType: 'physical' }) : setData({ ...data, personType: '' })
		if (isLegal) {
			setIsLegal(false)
		}
	}
	const handleLegalPerson = (e) => {
		setIsLegal(e.target.checked)
		e.target.checked ? setData({ ...data, personType: 'legal' }) : setData({ ...data, personType: '' })
		if (isPhysical) {
			setIsPhysical(false)
		}
	}
	const handleSelectFile = () => {
		fileInput.current.click()
	}
	const handleChangeFile = (e) => {
		e.preventDefault()

		let pdfFile = e.target.files[0]

		setData({ ...data, residenceCertificate: pdfFile })
	}
	const handleRemoveFile = () => {
		setData({ ...data, residenceCertificate: '' })
	}
	const registerInvestorHandler = (e) => {
		e.preventDefault()

		if (!data.gender) {
			return setErrorGender('Por favor ingresa el genero.')
		}

		if (data.address.includes(';')) {
			return setErrorAddress('La dirección no puede tener un ";" (Punto y coma)')
		}

		let isLegalOrPhysical = data.personType.length > 0

		if (isLegalOrPhysical && data.country !== 'España' && !data.residenceCertificate) {
			return setErrorTaxResidenceFile('Certificado de residencia fiscal obligatorio, salvo España')
		}

		const createInvestorProfile = {
			...data,
			gender: matchGender(data.gender),
			personType: decidePerson(isLegal, isPhysical),
		}

		dispatch(registerProfileInvestorWithUser(createInvestorProfile))
	}

	return (
		<GridContainer>
			<GridItem xs={12}>
				<Card>
					<CardHeader color='primary' icon>
						<CardIcon color='primary'>
							<PersonAdd />
						</CardIcon>
						<h4 style={{ color: '#999' }}>
							Registrar {matchPersonType(decidePerson(isLegal, isPhysical))} para ShareHolders
						</h4>
					</CardHeader>
					<CardBody>
						<InvestorRegisterTitles personType={decidePerson(isLegal, isPhysical)} />
						<form autoComplete='off' onSubmit={registerInvestorHandler}>
							<GridContainer className={classes.formContainer}>
								<GridItem xs={12} md={4} className={classes.switchPersonType}>
									<FormControlLabel
										control={<Switch checked={isPhysical} onChange={handlePhysicalPerson} value='physical' />}
										label='Persona Física'
									/>
								</GridItem>
								<GridItem xs={12} md={4} className={classes.switchPersonType}>
									<FormControlLabel
										control={<Switch checked={isLegal} onChange={handleLegalPerson} value='legal' />}
										label='Persona Jurídica'
									/>
								</GridItem>
								{!isLegal && !isPhysical && (
									<GridItem xs={12}>
										<div className={classes.formSectionContainer}>
											<InputsUser userInfo={data} setUserInfo={setData} />
											<SelectInputGender userInfo={data} setUserInfo={setData} update={true} />
										</div>
									</GridItem>
								)}
								{isPhysical && (
									<GridItem xs={12}>
										<div className={classes.formSectionContainer}>
											<InputsPhysicalProfile
												userInfo={data}
												setUserInfo={setData}
												investorsProfileListData={prescribers}
												handleChangeFile={handleChangeFile}
												fileInput={fileInput}
												handleSelectFile={handleSelectFile}
												setErrorTaxResidenceFile={setErrorTaxResidenceFile}
												classes={classes}
											/>
											{data.residenceCertificate && (
												<GridItem xs={12}>
													<Badge
														badgeContent={
															<Button size='sm' color='danger' round justIcon onClick={() => handleRemoveFile()}>
																<Close />
															</Button>
														}
														className={classes.fileBadge}
													>
														<div className={classes.fileDiv}>
															{typeof data.residenceCertificate === 'object'
																? data.residenceCertificate.name.length > 20
																	? data.residenceCertificate.name.slice(1, 10) +
																	  '...' +
																	  data.residenceCertificate.name.slice(
																			data.residenceCertificate.name.length - 6,
																			data.residenceCertificate.name.length,
																	  )
																	: data.residenceCertificate.name
																: data.residenceCertificate.length > 20
																? data.residenceCertificate.slice(1, 10) +
																  '...' +
																  data.residenceCertificate.slice(
																		data.residenceCertificate.length - 6,
																		data.residenceCertificate.length,
																  )
																: data.residenceCertificate}
														</div>
													</Badge>
												</GridItem>
											)}
										</div>
									</GridItem>
								)}
								{isLegal && (
									<GridItem xs={12}>
										<div className={classes.formSectionContainer}>
											<InputsUser userInfo={data} setUserInfo={setData} />
											<SelectInputGender userInfo={data} setUserInfo={setData} update={true} />
										</div>
										<h4 className={classes.companyInformation}>Introducir información de la empresa</h4>
										<div className={classes.formSectionContainer}>
											<InputsLegalProfile
												userInfo={data}
												setUserInfo={setData}
												investorsProfileListData={prescribers}
												handleChangeFile={handleChangeFile}
												fileInput={fileInput}
												handleSelectFile={handleSelectFile}
												setErrorTaxResidenceFile={setErrorTaxResidenceFile}
												classes={classes}
											/>
											{data.residenceCertificate && (
												<GridItem xs={12}>
													<Badge
														badgeContent={
															<Button size='sm' color='danger' round justIcon onClick={() => handleRemoveFile()}>
																<Close />
															</Button>
														}
														className={classes.fileBadge}
													>
														<div className={classes.fileDiv}>
															{typeof data.residenceCertificate === 'object'
																? data.residenceCertificate.name.length > 20
																	? data.residenceCertificate.name.slice(1, 10) +
																	  '...' +
																	  data.residenceCertificate.name.slice(
																			data.residenceCertificate.name.length - 6,
																			data.residenceCertificate.name.length,
																	  )
																	: data.residenceCertificate.name
																: data.residenceCertificate.length > 20
																? data.residenceCertificate.slice(1, 10) +
																  '...' +
																  data.residenceCertificate.slice(
																		data.residenceCertificate.length - 6,
																		data.residenceCertificate.length,
																  )
																: data.residenceCertificate}
														</div>
													</Badge>
												</GridItem>
											)}
										</div>
									</GridItem>
								)}
							</GridContainer>
							{errorTaxResidenceFile && !data.residenceCertificate && (
								<GridItem xs={12} sm={12}>
									<SnackbarContent message={errorTaxResidenceFile} color='warning' />
								</GridItem>
							)}
							{errorInvestorProfileWithUserRegister && (
								<GridContainer>
									<GridItem xs={12}>
										<SnackbarContent message={errorInvestorProfileWithUserRegister} color='danger' />
									</GridItem>
								</GridContainer>
							)}
							{errorGender && !data.gender && (
								<GridContainer>
									<GridItem xs={12}>
										<SnackbarContent message={errorGender} color='danger' />
									</GridItem>
								</GridContainer>
							)}
							{errorAddress && (
								<GridContainer>
									<GridItem xs={12}>
										<SnackbarContent message={errorAddress} color='danger' />
									</GridItem>
								</GridContainer>
							)}
							{errorPasswordValidation && !complexPasswordValidation(data.password) && (
								<GridContainer>
									<GridItem xs={12}>
										<SnackbarContent message={errorPasswordValidation} color='danger' />
									</GridItem>
								</GridContainer>
							)}
							<GridContainer>
								<GridItem xs={12}>
									<Button
										disabled={loadingInvestorProfileWithUserRegister}
										type='submit'
										color={successInvestorProfileWithUserRegister ? `success` : 'primary'}
										block
									>
										{!successInvestorProfileWithUserRegister
											? loadingInvestorProfileWithUserRegister
												? 'Registrando...'
												: `Registrar ${matchPersonType(decidePerson(isLegal, isPhysical))}`
											: 'Registrado Correctamente'}
									</Button>
								</GridItem>
							</GridContainer>
						</form>
					</CardBody>
				</Card>
			</GridItem>
			{alert}
		</GridContainer>
	)
}

export default InvestorUserRegisterScreen
