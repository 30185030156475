import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle'
const styles = (theme) => ({
	...modalStyle(theme),
	dialogContent: {
		overflow: 'auto',
		maxWidth: '100vw',
	},
	spanTitle: {
		margin: 10,
	},
	spanError: {
		color: 'red',
	},
	hoverCell: {
		'&:hover': {
			color: 'black',
			fontWeight: '400',
		},
	},
	...customSelectStyle,
	pagPosition: {
		position: 'sticky',
		top: '0',
		left: '0',
	},
	inputFormat: {
		background: 'transparent',
		border: 'none',
		borderBottom: '2px solid #c0bbac',
	},
	gridContainer: {
		justifyContent: 'center',
	},
	trWidthBig: {
		minWidth: '100% !important',
	},
	widthBig: {
		minWidth: '170px !important',
	},
	confirmBtnCssClass: {
		backgroundColor: '#ebe6e2',
		color: '#333333',
		padding: '10px',
		width: '5rem',
		borderRadius: '3px',
		'&:hover': {
			color: '#333333',
		},
	},
	//COMMENTS MODAL
	commentWindow: {
		width: '100%',
		maxHeight: '50vh',
		minHeight: '350px',
	},
	loadingContainer: {
		width: '100%',
		minWidth: '360px',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	noItemContainer: {
		backgroundColor: '#d3d3d324 !important',
		width: '100% !important',
		height: '100% !important',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	emptyText: {
		fontSize: '18px',
		textAlign: 'center',
		width: '80%',
	},
	list: {
		width: '100% !important',
		backgroundColor: '#d3d3d324 !important',
		position: 'relative !important',
		overflow: 'auto !important',
		maxHeight: '50vh !important',
		minHeight: '350px !important',

		'&::-webkit-scrollbar': {
			width: '8px',
		},
		'&::-webkit-scrollbar-track': {
			background: '#d3d3d3',
			borderRadius: '8px',
		},
		'&::-webkit-scrollbar-thumb': {
			background: '#999',
			borderRadius: '8px',
		},
	},
	shippingInputContainer: { display: 'flex', alignItems: 'center', padding: '0 16px' },
	commentContainer: {
		width: '100%',
		minHeight: '200px',
		maxHeight: '250px',
		overflow: 'auto',
	},
	commentTitle: { fontSize: '12px', fontWeight: 'bold', padding: '1px 8px' },
	commentBody: {
		margin: '0 20px',
		wordWrap: 'break-word',
		whiteSpace: 'pre-wrap',
		padding: '5px 0px',
	},
	commentDateContainer: { display: 'flex', justifyContent: 'end' },
	commentDate: { alignSelf: 'end', padding: '0px 8px', fontSize: '10px', color: '#c0bbac' },
	commentSticker: { paddingRight: '10px' },
	loader: {
		width: '100%',
		minHeight: '300px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	gridSearch: {
		marginTop: '3rem !important',
		textAlign: 'center',
	},
	iconSearch: {
		'& svg': {
			fontSize: '130px',
			color: '#999',
		},
	},
	inputContainer: {
		padding: '0 !important',
		marginTop: '10px',
		background: '#f1f1f1',
		display: 'flex',
		borderRadius: '10px !important',
		flexDirection: 'row',
	},
	inputGrid: {
		display: 'flex',
		paddingTop: '7px !important',
		paddingBottom: '7px !important',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
	},
	buttonContainer: {
		padding: '10px',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'flex-end',
		height: '100%',
	},
})

export default styles
