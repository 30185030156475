import { useState } from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'
import { es } from 'date-fns/locale'
import { Accordion, AccordionDetails, Avatar, makeStyles } from '@material-ui/core'
import { Typography } from '@mui/material'
import GridItem from 'components/Grid/GridItem'
import { getUrlToOpenFile } from 'shared/helpers/get-url-to-open'
import styles from '../styles/commentItemStyles'

const useStyles = makeStyles(styles)

const CommentItem = ({ userName, comment, key, dateComment }) => {
	const classes = useStyles()

	const [showMore, setShowMore] = useState(false)

	const { userInfo } = useSelector((state) => state.userLogin)

	return (
		<GridItem container className={classes.commentRoot}>
			<GridItem className={classes.commentAvatar}>
				<Avatar src={getUrlToOpenFile(userInfo?.avatar, userInfo)} alt='...' style={{ width: 18, height: 18 }} />
			</GridItem>
			<GridItem className={classes.commentGridContainer}>
				<div className={classes.commentContainer} key={key}>
					<Typography className={classes.commentHeader}>
						{userName.substring(0, userName.indexOf(' ') < 0 ? userName.length : userName.indexOf(' '))}
					</Typography>
					<Accordion expanded={showMore} onChange={() => {}} className={classes.accordion}>
						<AccordionDetails className={classes.accordionNoPadding}>
							<Typography className={classes.commentBody}>
								{comment?.length > 200 ? (
									<>
										{showMore ? comment : comment.substring(0, 200)}
										{showMore ? '' : '...'}
										<b className={classes.showMoreButton} onClick={() => setShowMore(!showMore)}>
											{showMore ? 'Ver menos' : 'Ver mas'}
										</b>
									</>
								) : (
									comment
								)}
							</Typography>
						</AccordionDetails>
					</Accordion>
					<div
						className={classes.commentDateContainer}
						title={`Comentario generado el ${format(new Date(dateComment || null), "d 'de' MMMM 'de' yyyy", {
							locale: es,
						})} a las ${format(new Date(dateComment), 'hh:mmaaa', { locale: es })}`}
					>
						<span className={classes.commentDate}>
							{format(new Date(dateComment), 'dd/MM/yyyy - hh:mm aaaa', { locale: es })}
						</span>
					</div>
				</div>
			</GridItem>
		</GridItem>
	)
}

export default CommentItem
