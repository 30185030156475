import { cardTitle } from 'assets/jss/material-dashboard-pro-react'
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle'
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch'

const styles = (theme) => ({
  ...customCheckboxRadioSwitch,
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
  rootItem: {
    padding: '0 !important',
  },
  addButtonContainer: {
    textAlign: 'end',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  ...modalStyle(theme),
})

export default styles
