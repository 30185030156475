import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import CustomInput from 'components/CustomInput/CustomInput'
import { signContract } from 'local_redux/actions/investmentActions'
import { INVESTMENT_SIGN_CONTRACT_RESET } from 'local_redux/constants/investmentConstants'
import styles from '../styles/investmentAdminDeleteModalStyles'

const useStyles = makeStyles(styles)

const SignedModal = ({ handleCloseSignedModal, signedModal, showSignedInfo }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [contractId, setContractId] = useState('')

  const { successInvestmenSignContract, loadingInvestmenSignContract, errorInvestmenSignContract } = useSelector(
    (state) => state.investmenSignContract
  )

  useEffect(() => {
    let timeOut = ''
    if (successInvestmenSignContract) {
      timeOut = setTimeout(() => {
        handleCloseSignedModal()
      }, 1500)
    }
    return () => clearTimeout(timeOut)
  }, [successInvestmenSignContract])
  useEffect(() => {
    return () => {
      dispatch({ type: INVESTMENT_SIGN_CONTRACT_RESET })
    }
  }, [dispatch])

  const handleSigned = (e) => {
    e.preventDefault()

    const data = {
      _id: showSignedInfo._id,
      contractId,
      sign: true,
    }
    dispatch(signContract(data))
  }

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={signedModal}
      keepMounted
      onClose={handleCloseSignedModal}
      aria-labelledby='user-modal-delete-title'
      aria-describedby='user-modal-delete-description'
    >
      <DialogTitle id='user-modal-delete-title' disableTypography className={classes.modalHeader}>
        <Button
          justIcon
          className={classes.modalCloseButton}
          key='close'
          aria-label='Close'
          color='transparent'
          onClick={handleCloseSignedModal}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4>Firmar Contrato</h4>
      </DialogTitle>
      <DialogContent id='user-modal-delete-description' className={classes.modalBody}>
        <form onSubmit={handleSigned} id='signed-form'>
          <GridContainer>
            <GridItem xs={12}>
              <Typography>
                ¿Estás seguro que queres firmar el contrato de <b>{showSignedInfo.investorProfile.businessName}</b> ?
              </Typography>
            </GridItem>
            <GridItem xs={12}>
              <CustomInput
                labelText='ID de contrato *'
                id='contractId'
                helperText='Formato 559e187b-e88f-4e0b-a61b-e959f3eb2620'
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: contractId,
                  onChange: (e) => {
                    setContractId(e.target.value)
                  },
                  type: 'text',
                  required: true,
                }}
              />
            </GridItem>
          </GridContainer>
          {errorInvestmenSignContract && (
            <GridContainer>
              <GridItem xs={12}>
                <SnackbarContent message={errorInvestmenSignContract} color='danger' />
              </GridItem>
            </GridContainer>
          )}
        </form>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <GridContainer>
          <GridItem xs={6}>
            <Button onClick={handleCloseSignedModal} block>
              Cancelar
            </Button>
          </GridItem>
          <GridItem xs={6}>
            <Button type='submit' color={successInvestmenSignContract ? 'success' : 'primary'} block form='signed-form'>
              {loadingInvestmenSignContract ? 'Firmando' : successInvestmenSignContract ? 'Firmado' : 'Firmar'}
            </Button>
          </GridItem>
        </GridContainer>
      </DialogActions>
    </Dialog>
  )
}

export default SignedModal
