const fixedRateLoansMapper = (fixedRateLoansListData) => {
	return fixedRateLoansListData?.map((item) => {
		return {
			id: item._id,
			typesOfInterestRates: `${item.typesOfInterestRates} %`,
			investorProfile: item?.businessName,
			registerDate: item.registerDate,
			startDate: item.startDate,
			finishDate: item.finishDate,
			amount: item.amount,
			status: item.status,
			user: item.user ? item?.user?.name : 'Unknown User',
			createdAt: item?.createdAt,
		}
	})
}

export default fixedRateLoansMapper
