import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format } from 'date-fns'
import es from 'date-fns/locale/es'
import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles } from '@material-ui/core'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { CalendarPicker } from '@mui/x-date-pickers/CalendarPicker'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import SnackbarContent from 'components/Snackbar/SnackbarContent'
import { updateIncome } from 'local_redux/actions/incomeAction'
import { INCOME_UPDATE_RESET } from 'local_redux/constants/incomeConstants'
import styles from '../styles/incomeConfirmationModalStyles'

const useStyles = makeStyles(styles)

const IncomeConfirmationModal = ({ handleCloseIncomeUpdateModal, incomeUpdateModal, showIncomeUpdateInfo }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  const [date, setDate] = useState(new Date())

  const { loadingincomeUpdate, successIncomeUpdate, errorIncomeUpdate } = useSelector((state) => state.incomeUpdate)

  useEffect(() => {
    let timeOut
    if (successIncomeUpdate) {
      timeOut = setTimeout(() => {
        dispatch({ type: INCOME_UPDATE_RESET })
        handleCloseIncomeUpdateModal()
      }, 1500)
    }
    return () => clearTimeout(timeOut)
  }, [successIncomeUpdate])
  useEffect(() => {
    return () => dispatch({ type: INCOME_UPDATE_RESET })
  }, [dispatch])

  const handleIncomeConfirmation = (e) => {
    e.preventDefault()

    dispatch(
      updateIncome({
        investmentId: showIncomeUpdateInfo._id,
        incomeDate: format(date, 'yyyy-MM-dd'),
      })
    )
  }

  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal,
      }}
      open={incomeUpdateModal}
      keepMounted
      onClose={handleCloseIncomeUpdateModal}
      aria-labelledby='user-modal-delete-title'
      aria-describedby='user-modal-delete-description'
    >
      <DialogTitle id='user-modal-delete-title' disableTypography className={classes.modalHeader}>
        <Button
          justIcon
          className={classes.modalCloseButton}
          key='close'
          aria-label='Close'
          color='transparent'
          onClick={handleCloseIncomeUpdateModal}
        >
          <Close className={classes.modalClose} />
        </Button>
        <h4>Confirmar ingreso de inversión</h4>
      </DialogTitle>
      <DialogContent id='user-modal-delete-description' className={classes.modalBody}>
        <form onSubmit={handleIncomeConfirmation} id='income-confirmation-form'>
          <GridContainer>
            <GridItem xs={12} style={{ width: '5px' }}>
              <p>
                Recuerde que una vez confirmado el ingreso, esta operación no puede ser modificada, por favor corrobore
                bien los datos antes de confirmar.
              </p>
              <ol className={classes.ulStyles}>
                <li>
                  Proyecto <b>{`${showIncomeUpdateInfo.projectAddress}`}</b>
                </li>
                <li>
                  Monto <b>{`${showIncomeUpdateInfo.amount}`} </b>
                </li>
                <li>
                  Perfil <b>{`${showIncomeUpdateInfo.businessName}`}</b>
                </li>
                <li>
                  Fecha de inversión <b>{`${format(new Date(showIncomeUpdateInfo.investmentDate), 'dd/MM/yyyy')}`}</b>
                </li>
              </ol>
            </GridItem>
            <GridItem xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
                <CalendarPicker
                  className={classes.calendaryContainer}
                  date={date}
                  onChange={(newDate) => setDate(newDate)}
                  disableFuture
                />
              </LocalizationProvider>
            </GridItem>
          </GridContainer>
          {errorIncomeUpdate && (
            <GridContainer>
              <GridItem xs={12}>
                <SnackbarContent message={errorIncomeUpdate} color='danger' />
              </GridItem>
            </GridContainer>
          )}
        </form>
      </DialogContent>
      <DialogActions className={classes.modalFooter}>
        <GridContainer>
          <GridItem xs={12}>
            <Button
              type='submit'
              color={successIncomeUpdate ? 'success' : 'primary'}
              block
              form='income-confirmation-form'
            >
              {loadingincomeUpdate ? 'Confirmando...' : successIncomeUpdate ? 'Confirmado' : 'Confirmar'}
            </Button>
          </GridItem>
        </GridContainer>
      </DialogActions>
    </Dialog>
  )
}

export default IncomeConfirmationModal
