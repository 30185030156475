import { differenceInCalendarDays } from 'date-fns'
const delayedProjectsMapper = (delayedProjects) =>
	delayedProjects?.map((item) => {
		return {
			id: item._id,
			projectAddress: item.projectAddress,
			finishDateDossier: item.finishDateDossier,
			delayedDays: differenceInCalendarDays(new Date(), new Date(item.finishDateDossier)),
			updateFinishedDateReasonsCount: item.updateFinishedDateReasonsCount,
			finishedDate: item.finishDateUpdated,
		}
	})
export default delayedProjectsMapper
